<div class="cybake-page-background h-full overflow-y-auto">
  <div class="">
    <cybake-factory-user-details
      [hasSites]="hasSites"
      [refreshSites]="refreshSites"
      [loadingSites]="loadingSites"
    ></cybake-factory-user-details>
  </div>

  <div class="inline-flex w-full flex-wrap">
    @if (tiles && tiles.length) {
      @for (tile of tiles; track tile) {
        <div class="col-6 md:col-4">
          <cybake-factory-tile
            [pageSourceName]="pageSourceName"
            [identifier]="'tile-' + tile?.Id"
            [title]="tile?.Name || ''"
            [iconKey]="tile?.DisplayContent || ''"
            [link]="tile?.Title || ''"
            [disabled]="!tile.Enabled"
          >
          </cybake-factory-tile>
        </div>
      }
    }
  </div>
</div>

import { Component, Input } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'cybake-factory-stock-location',
  standalone: true,
  imports: [RouterOutlet, TranslateModule, RouterModule, BaseCardComponent],
  templateUrl: './stock-location.component.html',
  styleUrl: './stock-location.component.scss',
})
export class StockLocationComponent extends BaseCardComponent {
  @Input() selectedtransferLocation!: number | undefined;
  @Input() showIcon: boolean = true;
}

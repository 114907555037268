import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VibrationService {
  vibrate(vibratePattern: number[] = [200]): boolean {
    const supportsVibration: boolean = this.hasVibrationSupport();
    if (supportsVibration) {
      window.navigator.vibrate(vibratePattern);
    }

    return !!supportsVibration;
  }

  cancelVibration(): boolean {
    const supportsVibration: boolean = this.hasVibrationSupport();
    if (supportsVibration) {
      window.navigator.vibrate(0);
    }

    return !!supportsVibration;
  }

  hasVibrationSupport(): boolean {
    return !!window.navigator.vibrate;
  }
}

<div (click)="visible = true"
     class="px-3 active:bg-gray-100"
     id="{{ pageSourceName }}_selectToggle">
  <div class="flex flex-row cursor-pointer justify-content-between border-1 border-slate-300 rounded-lg p-2 align-items-center">
    <p class="text-black" cybake-text-style="body-small">
      {{ getDisplay() }}
    </p>
    <i class="fa-solid fa-chevron-down text-black font-semibold"></i>
  </div>
</div>
<div class="select-dialog-wrapper">
  <cybake-factory-dialog [title]="'StockTransfer.SelectContainer'"
                         [type]="'question'"
                         [(visible)]="visible"
                         [identifier]="'productionLocationsSelection'"
                         [closable]="true"
                         [width]="25"
                         [dialogContentTemplate]="dialogContentTemplate2">
    <ng-template #dialogContentTemplate2>
      <div class="grid m-0">
        <div class="col-12 p-0 pb-2">
          <cybake-factory-listbox [options]="options"
                                  [(value)]="selectedOption"
                                  [optionLabel]="optionLabel"
                                  [radioButtons]="false"
                                  [height]="200">
          </cybake-factory-listbox><!--[optionValue]="optionValue"-->
        </div>
        <div class="col-12 p-0 pt-2">
          <cybake-factory-button [pageSourceName]="confirmButton.PageSourceName"
                                 [identifier]="confirmButton.Identifier"
                                 [translationKey]="confirmButton.TranslationKey"
                                 [class]="confirmButton.Class"
                                 [iconKey]="confirmButton.IconKey"
                                 [iconClass]="confirmButton.IconClass"
                                 [fullWidth]="true"
                                 [disabled]="!selectedOption"
                                 (clickEvent)="selectValueChange()">
          </cybake-factory-button>
        </div>
      </div>
    </ng-template>
  </cybake-factory-dialog>

</div>

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonModule } from 'primeng/skeleton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'cybake-factory-toggle',
  standalone: true,
  imports: [
    FormsModule,
    ToggleButtonModule,
    SkeletonModule,
    TooltipModule,
    TranslateModule,
  ],
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
})
export class CyBakeToggleComponent {
  // Inputs/Outputs

  @Input() pageSourceName!: string;
  @Input() identifier!: string;
  @Input() class?: string;
  @Input() buttonOnIcon: string[] = [];
  @Input() buttonOffIcon: string[] = [];
  @Input() buttonOnLabel: string = '';
  @Input() buttonOffLabel: string = '';

  @Input() tooltip: string = '';
  @Input() tooltipPosition: string = 'top';

  @Input() bigIcon: boolean = false;
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;

  @Input() value!: boolean;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { BarcodeScanComponent } from '../_shared/components/barcode-scan/barcode-scan.component';
import { DialogComponent } from '../_shared/components/dialog/dialog.component';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';
import { ButtonFloatEnum } from '../_shared/models/cybake/button/button-float.enum';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { DataService } from '../_shared/services/data.service';
import { NavbarTitle } from '../_shared/models/navbar/navbar-title.model';
import { VibrationService } from '../_shared/services/vibration.service';
import { environment } from '../../environments/environment';
import { ProductionService } from '../_shared/services/production.service';
import { ProductionServiceMock } from '../_shared/services/mock-services/production.service.mock';
import { ProductionLocation } from '../_shared/models/production/locations/production-location.model';
import { ProductionShiftSelectComponent } from './production-shift-select/production-shift-select.component';
import { MessageSeverityEnum } from '../_shared/models/cybake/message/message-severity.enum';
import { Message } from '../_shared/models/cybake/message/message.model';
import { CyBakeMessageComponent } from '../_shared/components/message/message.component';
import { ProductionPlan } from '../_shared/models/production/plans/production-plan.model';
import { ProductionShift } from '../_shared/models/production/shifts/production-shift.model';
import moment from 'moment';
//import { faker } from '@faker-js/faker';
import { CyBakeCardComponent } from '../_shared/components/card/card.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeProgressPieComponent } from '../_shared/components/progress-pie/progress-pie.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from '../_shared/pipes/filter-by.pipe';
import { CyBakeDialComponent } from '../_shared/components/dial/dial.component';
import { ProductionPlanProduct } from '../_shared/models/production/plans/production-plan-product.model';
import { ProductionPlanItemRecipeLine } from '../_shared/models/production/plans/production-plan-item-recipe-line.model';
import _ from 'underscore';
import { ProductionPlanProductMix } from '../_shared/models/production/plans/production-plan-recipe-line-mix.model';
import { CyBakeInputNumberComponent } from '../_shared/components/input-number/input-number.component';
import { StockLotSelectionComponent } from '../_shared/dialogs/stock-lot-selection/stock-lot-selection.component';
import { StockLot } from '../_shared/models/production/stocklots/stock-lot.model';
import {
  LayoutModule,
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { UserDetails } from '../_shared/models/user/user-details.model';
import { CompleteProductionItemComponent } from './complete-production-item/complete-production-item.component';
import { NotesComponent } from '../_shared/dialogs/notes/notes.component';
import { CyBakeSwitchComponent } from '../_shared/components/switch/switch.component';
import { FilterByBooleanPipe } from '../_shared/pipes/filter-by-boolean.pipe';
import { DialogOptionsModel } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';
import { CyBakeConfirmationPrompTypeEnum } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';
import { Subscription } from 'rxjs';
import { OutputFunction } from '../_shared/models/common/output-function.model';
import { ProductionPlanProductRecipeMethod } from '../_shared/models/production/plans/production-plan-recipe-methods.model';
import { ImageComponent } from '../_shared/components/image/image.component';
import { CyBakeToggleComponent } from '../_shared/components/toggle/toggle.component';
import { ProductInfoComponent } from '../_shared/dialogs/product-info/product-info.component';
import { ProductionUsedLine } from '../_shared/models/production/plans/production-used-line.model';
import { PersistProductionUsedLineRequest } from '../_shared/models/production/plans/persist-production-used-line-request.model';
import { ToastMessage } from '../_shared/models/common/toast-message.model';
import { ToastSeverity } from '../_shared/models/cybake/toast/toast-severity.enum';
import { GetProductionPlanProductRecipeMethodLineResponse } from '../_shared/models/production/plans/get-production-plan-product-recipe-method-line-response.model';
import { DomSanitizer } from '@angular/platform-browser';
import { PrintingService } from '../_shared/services/printing.service';
import { CompleteProductionPlanLineResponse } from '../_shared/models/production/plans/complete-production-plan-line-response.model';

@Component({
  selector: 'cybake-factory-production',
  standalone: true,
  imports: [
    DialogComponent,
    BarcodeScanComponent,
    CyBakeButtonComponent,
    ProductionShiftSelectComponent,
    CyBakeMessageComponent,
    CyBakeCardComponent,
    TranslateModule,
    CommonModule,
    CyBakeProgressPieComponent,
    FilterPipe,
    FilterByBooleanPipe,
    CyBakeDialComponent,
    CyBakeInputNumberComponent,
    StockLotSelectionComponent,
    LayoutModule,
    CompleteProductionItemComponent,
    NotesComponent,
    CyBakeSwitchComponent,
    ImageComponent,
    CyBakeToggleComponent,
    ProductInfoComponent
  ],
  templateUrl: './production.component.html',
  styleUrl: './production.component.scss',
})
export class ProductionComponent implements OnInit, AfterViewInit {
  // Children
  @ViewChild('productionHeaderDiv') productionHeader!: HTMLDivElement;
  @ViewChild('recipleLineDial') recipeLineDial!: CyBakeDialComponent;
  @ViewChild('dialCenterTemplate') recipeLineDialHtml!: ElementRef;

  // Services
  dataService: DataService = inject(DataService);
  vibrationService: VibrationService = inject(VibrationService);
  router: Router = inject(Router);
  breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  productionService: ProductionService | ProductionServiceMock = environment.mock ? inject(ProductionServiceMock) : inject(ProductionService);
  domSanitizer: DomSanitizer = inject(DomSanitizer);
  printingService: PrintingService = inject(PrintingService);

  // Variables
  pageSourceName: string = 'production';
  userDetails!: UserDetails;
  loadingCardHeight: number = 111;
  defaultButtonType: ButtonTypeEnum = ButtonTypeEnum.default;

  // Variables - Production Locations
  productionLocations: ProductionLocation[] = [];
  closableProductionLocationSelection: boolean = false;
  deviceProductionLocation?: ProductionLocation;

  // Variables - Production Shifts
  productionShift!: ProductionShift;
  lockedProductionShift: boolean = false;
  showShiftTimingMessage: boolean = false;

  // Variables - Production Plans
  loadingProductionPlans: boolean = false;
  productionPlans: ProductionPlan[] = [];
  selectedProductionPlan?: ProductionPlan | null;
  showCompleteProductionPlansSwitchValue: boolean = false;

  // Variables - Production Items
  loadingProductionProducts: boolean = false;
  productionPlanProducts?: ProductionPlanProduct[];
  selectedProductionPlanProduct?: ProductionPlanProduct | null;
  showCompleteProductionPlanProductsSwitchValue: boolean = false;

  // Variables - Mixes
  loadingMixes: boolean = false;
  productionPlanProductMixes?: ProductionPlanProductMix[] = [];
  selectedProductionPlanProductMix?: ProductionPlanProductMix | null;
  showCompleteMixSwitchValue: boolean = false;

  // Variables - Recipe Lines
  loadingRecipeLines: boolean = false;
  productionPlanProductRecipeLines?: ProductionPlanItemRecipeLine[] = [];
  selectedProductionPlanProductRecipeLine?: ProductionPlanItemRecipeLine | null;
  showCompleteRecipeLineSwitchValue: boolean = false;
  completeRecipeLineConfirmationOptions?: DialogOptionsModel;
  recipeLineDialColour!: string;

  // Variables - Methods
  loadingMethods: boolean = false;
  productionPlanProductMethods?: ProductionPlanProductRecipeMethod[];
  selectedProductionPlanProductMethod?: ProductionPlanProductRecipeMethod | null;

  // Variables - Recipe Measures
  loadingUsedLines: boolean = false;
  selectedProductionPlanProductRecipeLineUsedLines: ProductionUsedLine[] = [];
  usedLine?: PersistProductionUsedLineRequest | null;
  productWithinTolerance?: boolean = false;

  // Variables - Plan Notes
  productionPlanForNoteDialog?: ProductionPlan;

  // Variables - Product Info
  productionPlanProductInfoDialog?: ProductionPlanProduct;

  // Variables - Stock Lots
  stockLots: StockLot[] = [];

  // Dialogs
  displayBarcodeScanner: boolean = false;
  displayProductionLocations: boolean = false;
  displayStockLots: boolean = false;
  displayCompleteItem: boolean = false;
  displayPlanNote: boolean = false;
  displayProductInfo: boolean = false;

  // Complete Item Dialog
  completeItemDialog: DialogOptionsModel = new DialogOptionsModel({
    Title: 'Production.CompleteItem.HeaderPrefix',
    Type: CyBakeConfirmationPrompTypeEnum.question,
    PageSourceName: this.pageSourceName,
    Identifier: 'completeItem',
  });

  // Barcode Button
  barcodeScannerButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'barcodeScanner',
    Class: ButtonClassEnum.default,
    Float: ButtonFloatEnum.bottomLeft,
    IconKey: ['fa-duotone', 'fa-scanner-gun'],
  });

  // Navigate back button
  navigateBackButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'productionNavigateBack',
    Class: ButtonClassEnum.transparent,
    IconKey: ['fa-regular', 'fa-chevron-left'],
    TranslationKey: 'Production.NavigateBackButton',
    IconClass: 'text-2xl',
  });

  // Complete button
  completeButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'complete',
    Class: ButtonClassEnum.success,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-check'],
    IconClass: 'text-3xl',
  });

  // Print Labels button
  printLabelsButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.output,
    PageSourceName: this.pageSourceName,
    Identifier: 'printLabels',
    Class: ButtonClassEnum.default,
    Rounded: true,
    IconClass: 'text-3xl',
  });

  // Production Locations Button
  productionLocationButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'productionLocation',
    Class: ButtonClassEnum.default,
    TranslationKey: 'Production.ProductionLocationsBtn',
    Prefix: 'Production.ProductionLocationsBtnPrefex',
    IconKey: ['fa-duotone', 'fa-location-dot'],
    Loading: true,
    TranslationFromData: false,
  });

  // Stock Lots Button
  stockLotsButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'stockLots',
    Class: ButtonClassEnum.default,
    TranslationKey:
      'Production.ProductionPlanRecipeLineCard.StockLotBtnPlaceholder',
    IconKey: ['fa-sharp', 'fa-barcode-scan'],
    Loading: false,
    TranslationFromData: false,
  });

  // Confirm Adding Measure Button
  confirmAddingMeasureButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.add,
    PageSourceName: this.pageSourceName,
    Identifier: 'confirmAddingEditingMeasure',
    Class: ButtonClassEnum.success,
    TranslationKey:
      'Production.ProductionPlanRecipeLineCard.ConfirmAddingMeasureBtn',
  });

  // Cancel Adding Measure Button
  cancelAddingMeasureButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'cancelAddingEditingMeasure',
    Class: ButtonClassEnum.warning,
    TranslationKey: 'Production.ProductionPlanRecipeLineCard.CancelAddingMeasureBtn',
    IconKey: ['fa-solid', 'fa-xmark'],
  });

  // Remove  Adding Measure Button
  removeAddingMeasureButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.delete,
    PageSourceName: this.pageSourceName,
    Identifier: 'removeMeasure',
    Class: ButtonClassEnum.danger,
    TranslationKey: 'Production.ProductionPlanRecipeLineCard.RemoveMeasureBtn',
    IconKey: ['fa-sharp-duotone fa-solid', 'fa-trash'],
  });

  //Plan Closing Message
  planClosingMessage: Message = new Message({
    PageSourceName: this.pageSourceName,
    Identifier: 'productionPlanClosing',
    Severity: MessageSeverityEnum.error,
    TranslationKey: 'Production.PlanClosed',
    Closable: false,
  });

  navbarTitle: NavbarTitle = new NavbarTitle({
    Title: 'Production.PlanHeader',
    PrefixSubTitle: 'Production.SubPlanHeaderPrefix',
    SubTitleFromData: true,
  });

  // productionPlanDetailsPrompOptions

  productionPlanDetailsPrompOptions: DialogOptionsModel = new DialogOptionsModel({
    Type: CyBakeConfirmationPrompTypeEnum.info,
    PageSourceName: this.pageSourceName,
    Identifier: 'planDetails',
    Title: 'Production.ProductionPlanNote.Header',
    ConfirmButton: new CyBakeButton({
      PageSourceName: this.pageSourceName,
      TranslationKey: 'Production.ProductionPlanNote.CloseButton',
      Identifier: 'closePlanDetails',
      Type: ButtonTypeEnum.default,
      Class: ButtonClassEnum.default,
    }),
    ConfirmButtonMethod: new OutputFunction({
      MethodName: 'closePlanNote'
    }),
  });

  // Screen Breakpoints
  isMobilePortrait: boolean = false;
  isMobileLandscape: boolean = false;
  isTabletLandscape: boolean = false;
  isTabletPortrait: boolean = false;

  // Subscriptions
  subscriptionConfirmationPromptOutputMethod!: Subscription;

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('user')!);
    this.dataService.updateNavbarTitle(this.navbarTitle);

    this.getCurrentProductionShift();

    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
      ])
      .subscribe((state: BreakpointState) => {
        this.isMobilePortrait = state.breakpoints[Breakpoints.HandsetPortrait];
        this.isMobileLandscape =
          state.breakpoints[Breakpoints.HandsetLandscape];
        this.isTabletLandscape = state.breakpoints[Breakpoints.TabletLandscape];
        this.isTabletPortrait = state.breakpoints[Breakpoints.TabletPortrait];
      });

    // Confirmation Prompt Output Method
    this.subscriptionConfirmationPromptOutputMethod =
      this.dataService.confirmationPromptOutputFunctionState.subscribe(
        (outputFunction: OutputFunction) => {
          // eslint-disable-next-line
          (this as any)[outputFunction.MethodName](outputFunction.Param);
        },
      );
  }

  ngAfterViewInit() {
    if (!environment.mock) {
      this.printingService.setup();
    }
  }

  // ---------------------- Production Locations ----------------------

  getProductionLocations() {
    this.productionLocationButton.Loading = true;
    this.productionLocationButton.Failed = false;

    this.productionService.getProductionLocations().subscribe({
      next: (getProductionLocationsResponse: ProductionLocation[]) => {
        this.productionLocations = getProductionLocationsResponse;

        // Check if any location are returned and if not prompt user to go back to dashboard. No other option.
        if (!this.productionLocations.length) {
          this.productionLocationButton.Loading = false;
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.MissingDataMessage.NoProductionLocationsMsg',
            Message: `Production.MissingDataMessage.NoProductionLocationsSubMsg`,
            MessageHtml: false,
            PageSourceName: this.pageSourceName,
            Identifier: 'noProductionLocations',
            Closable: false,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToDashboard',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToDashboardBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
          });

          this.dataService.openConfirmationPrompt(options);
          return;
        }

        // Find if production location has been set previously for user and set it
        this.deviceProductionLocation = JSON.parse(
          localStorage.getItem('deviceProductionLocation')!,
        ) as ProductionLocation;

        if (this.deviceProductionLocation) {
          const setProductionLocation: ProductionLocation = _.find(
            this.productionLocations,
            (location: ProductionLocation) => {
              return location.Id === this.deviceProductionLocation!.Id;
            },
          ) as ProductionLocation;

          // LS - Added as error with local storage production location not in list
          if (setProductionLocation) {
            this.productionLocationButton.TranslationKey = `${setProductionLocation.Name}`;
            this.productionLocationButton.TranslationFromData = true;

            this.getCurrentProductionPlans(setProductionLocation.Id);
          } else {
            // If no stored production location, force a selection
            this.openProductionLocations(true);
          }

          this.productionLocationButton.TranslationKey = `${setProductionLocation.Name}`;
          this.productionLocationButton.TranslationFromData = true;

          this.getCurrentProductionPlans(setProductionLocation.Id);
        } else {
          // If no stored production location, force a selection
          this.openProductionLocations(true);
        }

        this.productionLocationButton.Loading = false;
      },
      error: () => {
        this.productionLocationButton.Loading = false;
        this.productionLocationButton.Failed = true;
      },
    });
  }

  // ---------------------- Current Production Shift ----------------------

  getCurrentProductionShift() {
    this.productionService.getCurrentProductionShift().subscribe({
      next: (getCurrentProductionShiftResponse: ProductionShift) => {

        if (!getCurrentProductionShiftResponse) {
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.MissingDataMessage.NoProductionShiftMsg',
            PageSourceName: this.pageSourceName,
            Identifier: 'noProductShift',
            Closable: false,
            Width: 25,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToDashboard',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToDashboardBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            })
          });

          this.dataService.openConfirmationPrompt(options);
          this.productionLocationButton.Loading = false;
          return;
        }

        this.productionShift = getCurrentProductionShiftResponse;
        this.getProductionLocations();

        // Update Navbar with shift info
        // LS - Added as start time is a date because start time might not be the same today, could be yesterday
        // LS - Same for end time
        const formattedStartDateTime = moment(this.productionShift.StartTime).format(
          `${this.userDetails.Settings.TimeFormat.Short}`,
        );

        const formattedEndDateTime = moment(this.productionShift.EndTime).format(
          `${this.userDetails.Settings.TimeFormat.Short}`,
        );

        this.navbarTitle.SubTitle = `${this.productionShift.Name} (${formattedStartDateTime} - ${formattedEndDateTime})`;
        this.dataService.updateNavbarTitle(this.navbarTitle);

        // Check shift timing
        // LS - Removed and using this.productionShift.EndTime instead as it's a date not just time
        // let newDate = moment().format('DD-MM-YYYY');
        // let dateWithEndTime = new Date(newDate + ' ' + this.productionShift.EndTime);

        this.planClosingMessage.TranslationParams = {
          planName: this.productionShift.Name,
          planEndTime: this.productionShift.EndTime,
        };
        const nowUtc = moment().utc();
        const thresholdTimeUtc = moment().utc().add(30, 'minutes');

        // LS - Changed to use this.productionShift.EndTime
        if (nowUtc.isAfter(moment(this.productionShift.EndTime))) {
          this.showShiftTimingMessage = true;
          this.lockedProductionShift = true;
        } else if (
          // LS - Changed to use this.productionShift.EndTime
          thresholdTimeUtc.isAfter(moment(this.productionShift.EndTime))
        ) {
          this.planClosingMessage.Severity = MessageSeverityEnum.info;
          this.planClosingMessage.TranslationKey = 'Production.PlanClosingSoon';
          this.planClosingMessage.Closable = true;
          this.showShiftTimingMessage = true;
          this.lockedProductionShift = false;
        } else {
          this.showShiftTimingMessage = false;
          this.lockedProductionShift = false;
        }
      },
      error: () => { },
    });
  }

  // ---------------------- Get Production Plans ----------------------

  getCurrentProductionPlans(productionShiftId: number) {

    this.loadingProductionPlans = true;

    this.productionService.getProductionPlans(productionShiftId).subscribe({
      next: (getProductionPlansResponse: ProductionPlan[]) => {

        if (!getProductionPlansResponse.length) {
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.MissingDataMessage.NoProductionPlansMsg',
            PageSourceName: this.pageSourceName,
            Identifier: 'NoProductionPlans',
            Closable: false,
            Width: 40,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToDashboard',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToDashboardBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
            // LS - Added Decline button to take you back to production locations pop up
            // LS - Did this because if prod location is saved and you go to production
            // LS - if there's no plans it loops you back to dashboard without being able to change locations
            // LS - Probably needs changing and couldn't get translation to work
            DeclineButtonMethod: new OutputFunction({
              MethodName: 'backToProductionLocations',
            }),
            DeclineButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToProductionPlansBtn',
              Identifier: 'backToProductionLocations',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
          });

          this.dataService.openConfirmationPrompt(options);
          this.loadingProductionPlans = false;
          return;
        }

        const nonCompletePlans: ProductionPlan[] = _.filter(getProductionPlansResponse, (plan: ProductionPlan) => {
          return plan.NumberOfLinesCompleted !== plan.TotalNumberOfLines;
        },
        );

        if (!nonCompletePlans.length) {
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.success,
            Title: 'Production.AllProductionPlansCompleteMsg',
            PageSourceName: this.pageSourceName,
            Identifier: 'completeProductionPlans',
            Closable: false,
            Width: 40,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToDashboard',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToDashboardBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
            // LS - Added Decline button to take you back to production locations pop up
            // LS - Did this because if prod location is saved and you go to production
            // LS - if there's no plans it loops you back to dashboard without being able to change locations
            // LS - Probably needs changing and couldn't get translation to work
            DeclineButtonMethod: new OutputFunction({
              MethodName: 'backToProductionLocations',
            }),
            DeclineButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey:
                'Production.MissingDataMessage.BackToProductionPlansBtn',
              Identifier: 'backToProductionLocations',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
          });

          this.dataService.openConfirmationPrompt(options);
          this.loadingProductionPlans = false;
          return;
        }

        _.forEach(getProductionPlansResponse, (plan: ProductionPlan) => {
          plan.Completed = plan.TotalNumberOfLines === plan.NumberOfLinesCompleted ? true : false;
          plan.StartTime = moment('1970-01-01 ' + plan.StartTime).format(this.userDetails.Settings.TimeFormat.Short);
        });

        getProductionPlansResponse = _.sortBy(getProductionPlansResponse, 'StartTime' );
        this.productionPlans = getProductionPlansResponse;
        this.loadingProductionPlans = false;
      },
      error: () => {

        this.loadingProductionPlans = false;
      },
    });
  }

  // ---------------------- Get Production Plan Products ----------------------

  getProductionPlanProducts() {
    this.loadingCardHeight = 130;
    this.loadingProductionProducts = true;

    this.productionService.getProductionPlanProducts(this.selectedProductionPlan!.Id).subscribe({
      next: (getProductionPlanProductsResponse: ProductionPlanProduct[]) => {

        this.productionPlanProducts = [];

        if (!getProductionPlanProductsResponse.length) {
          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.warning,
            Title: 'Production.MissingDataMessage.NoProductionPlanProductsMsg',
            TitleTranslationParams: { planName: this.selectedProductionPlan!.Name },
            Message: 'Production.MissingDataMessage.NoProductionPlanProductsSubMsg',
            MessageHtml: false,
            PageSourceName: this.pageSourceName,
            Identifier: 'NoProductionPlans',
            Closable: false,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'backToProductionPlans',
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey: 'Production.MissingDataMessage.BackToProductionPlansBtn',
              Identifier: 'backToDashboard',
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            }),
          });

          this.dataService.openConfirmationPrompt(options);
          return;
        }

        this.loadingProductionProducts = false;
        this.productionPlanProducts = getProductionPlanProductsResponse;

        _.forEach(this.productionPlanProducts, (product: ProductionPlanProduct) => {
          if (product.Image) {
            product.ImageLoading = true;
            this.getProductionPlanProductImage(Number(product.Image), product);
          }
        });

      },
      error: () => {
        this.loadingProductionProducts = false;
      },
    });
  }

  getProductionPlanProductImage(imageGuid: number, product: ProductionPlanProduct) {
    this.productionService.getProductionPlanProductImage(imageGuid).subscribe({
      next: (getProductionPlanProductImageResponse: string) => {

        // LS - This needs changing to handle blob, not sure what needs changing though.
        product.Image = getProductionPlanProductImageResponse;

        //if (environment.mock) {
        //  if (product.ProductId !== 2) {
        //    product.ImageLoading = false;
        //  } else {
        //    product.Image = undefined;
        //    product.ImageLoading = false;
        //  }
        //}

      },
      error: () => {
        // LS - Added because the above call fails with real data and images just show loading
        product.Image = undefined;
        product.ImageLoading = false;
      },
    });
  }

  // ---------------------- Get Production Product Mixes ----------------------

  getProductionPlanProductMixes() {
    this.loadingCardHeight = 51;
    this.loadingMixes = true;

    this.productionPlanProductMixes = [];

    this.productionService.getProductionPlanProductMixes(this.selectedProductionPlan!.Id, this.selectedProductionPlanProduct!.ProductId).subscribe({
      next: (getProductionPlanProductMixesResponse: ProductionPlanProductMix[]) => {

        _.forEach(getProductionPlanProductMixesResponse, (mix: ProductionPlanProductMix) => {
          mix.Completed = mix.TotalNumberOfLines === mix.NumberOfLinesCompleted ? true : false;
        });

        const incompleteMixes: ProductionPlanProductMix[] = _.filter((getProductionPlanProductMixesResponse), (mix: ProductionPlanProductMix) => {
          return !mix.Completed
        })

        if (!incompleteMixes.length) {
          this.showCompleteMixSwitchValue = true;
        }

        this.productionPlanProductMixes = getProductionPlanProductMixesResponse;
        this.loadingMixes = false;
      },
      error: () => {
        this.loadingMixes = false;
      },
    });
  }

  // ---------------------- Get Production Product Recipe Lines ----------------------


  getProductionPlanMethodLines() {
    this.loadingCardHeight = 51;
    this.loadingMethods = true;

    this.productionPlanProductMethods = [];

    this.productionService.getProductionPlanMethodLines(this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id)).subscribe({
      next: (methods: ProductionPlanProductRecipeMethod[]) => {

        if (!methods.length) {
          this.loadingMethods = false;
          this.getProductionPlanProductRecipeLines();
        } else {
          this.productionPlanProductMethods = methods;

          _.forEach(this.productionPlanProductMethods, (method: ProductionPlanProductRecipeMethod) => {
            if (method.FileIdentifier) {
              method.LoadingImage = true;
              this.getProductionPlanMethodImage(method.FileIdentifier, method);
            }
          });

          this.loadingMethods = false;
        }

      },
      error: () => {
        this.loadingMethods = false;
      },
    });
  }

  getProductionPlanMethodImage(image: string, method: ProductionPlanProductRecipeMethod) {

    this.productionService.getProductionPlanMethodImage(image).subscribe({
      next: (getProductionPlanProductImageResponse: Blob) => {
        const urlCreator = window.URL;
        const imageUrl = urlCreator.createObjectURL(getProductionPlanProductImageResponse);
        method.Image = this.domSanitizer.bypassSecurityTrustResourceUrl(imageUrl);
        method.LoadingImage = false;
      },
      error: () => {
        method.LoadingImage = false;
      },
    });
  }

  getProductionPlanProductRecipeLines() {
    this.loadingCardHeight = 130;
    this.loadingRecipeLines = true;

    this.productionPlanProductRecipeLines = [];

    this.productionService.getProductionPlanRecipeLines(this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id)).subscribe({
      next: (recipeLines: ProductionPlanItemRecipeLine[]) => {

        const incompleteRecipeLines: ProductionPlanItemRecipeLine[] = _.filter(recipeLines, (recipeLine: ProductionPlanItemRecipeLine) => {
          return !recipeLine.Completed
        })

        if (!incompleteRecipeLines.length) {
          this.showCompleteRecipeLineSwitchValue = true;
        }

        this.productionPlanProductRecipeLines = recipeLines;
        this.loadingRecipeLines = false;

        _.forEach(this.productionPlanProductRecipeLines, (recipeLine: ProductionPlanItemRecipeLine) => {

          if (recipeLine.TotalQuantity) {
            //let stepAmount = Number(this.dataService.countDecimals(recipeLine.TotalQuantity));

            // RM - Trying to make step amount based on number of decimal places in TotalQuantiy but covid killed my brain and i can't figure it
            //recipeLine.StepAmount = stepAmount ? (1 / stepAmount) : 1;
            recipeLine.StepAmount = 1;
          }

          console.log(recipeLine.StepAmount);

          if (recipeLine.Image) {
            recipeLine.ImageLoading = true;
            this.getProductionPlanRecipeLineProductImage(Number(recipeLine.Image), recipeLine);
          }

        },
        );
      },
      error: () => {
        this.loadingRecipeLines = false;
      },
    });
  }

  getProductionPlanRecipeLineProductImage(image: number, recipeLine: ProductionPlanItemRecipeLine) {
    this.productionService.getProductionPlanProductImage(image).subscribe({
      next: (getProductionPlanProductImageResponse: string) => {
        recipeLine.Image = getProductionPlanProductImageResponse;
      },
      error: () => { },
    });
  }

  // ---------------------- Local Functions ----------------------

  viewPlan(productionPlan: ProductionPlan) {
    this.selectedProductionPlan = productionPlan;
    this.getProductionPlanProducts();

    this.navbarTitle.Title = 'Production.PlanProductHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
  }

  backToDashboard() {
    this.dataService.closeConfirmationPrompt();
    this.router.navigateByUrl('/dashboard');
  }

  backToProductionLocations() {
    this.dataService.closeConfirmationPrompt();
    this.selectedProductionPlan = null;
    this.navbarTitle.Title = 'Production.PlanHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
    this.openProductionLocations(true);
  }

  backToProductionPlans() {
    this.dataService.closeConfirmationPrompt();
    this.selectedProductionPlan = null;
    this.navbarTitle.Title = 'Production.PlanHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
    return;
  }

  viewPlanNote(productionPlan: ProductionPlan) {
    this.productionPlanForNoteDialog = productionPlan;
    this.displayPlanNote = true;
  }

  viewProductInfo(product: ProductionPlanProduct) {
    this.productionPlanProductInfoDialog = product;
    this.displayProductInfo = true;
  }

  closePlanNote() {
    this.displayPlanNote = false;
  }

  confirmProductionLocation(location: ProductionLocation) {
    this.displayProductionLocations = false;
    this.productionLocationButton.TranslationKey = `${location.Name}`;
    this.productionLocationButton.TranslationFromData = true;
    this.selectedProductionPlan = null;
    this.deviceProductionLocation = location;
    this.getCurrentProductionPlans(location.Id);
  }

  viewRecipeMethod(method: ProductionPlanProductRecipeMethod) {

    this.loadingCardHeight = 130;
    this.loadingRecipeLines = true;

    this.productionService.getProductionPlanMethodLine(
      this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id), method.Id).subscribe({
        next: (methodResponse: GetProductionPlanProductRecipeMethodLineResponse) => {

          this.selectedProductionPlanProductMethod = methodResponse.Method;
          this.productionPlanProductRecipeLines = methodResponse.RecipeLines;

          const incompleteRecipeLines: ProductionPlanItemRecipeLine[] = _.filter(this.productionPlanProductRecipeLines!, (recipeLine: ProductionPlanItemRecipeLine) => {
            return !recipeLine.Completed
          });

          if (!incompleteRecipeLines.length) {
            this.showCompleteRecipeLineSwitchValue = true;
          }

          this.loadingRecipeLines = false;

          this.navbarTitle.Title = 'Production.PlanProductRecipeLineHeader';
          this.dataService.updateNavbarTitle(this.navbarTitle);
        },
        error: () => {
          this.loadingRecipeLines = false;
        },
      });

    this.selectedProductionPlanProductMethod = method;

    _.forEach(this.selectedProductionPlanProductMethod.RecipeLines, (methodRecipeLine: ProductionPlanItemRecipeLine) => {
      if (methodRecipeLine.Image) {
        this.getProductionPlanRecipeLineProductImage(Number(methodRecipeLine.Image), methodRecipeLine);
      }
    });

    this.navbarTitle.Title = 'Production.PlanProductRecipeLineHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
  }

  backToProductionPlan() {
    if (this.selectedProductionPlanProductRecipeLine) {
      this.usedLine = undefined;
      this.selectedProductionPlanProductRecipeLine = null;
      this.navbarTitle.Title = 'Production.PlanProductRecipeLineHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);

      const incompleteRecipeLines: ProductionPlanItemRecipeLine[] = _.filter((this.selectedProductionPlanProductMethod ? this.selectedProductionPlanProductMethod.RecipeLines : this.productionPlanProductRecipeLines!), (recipeLine: ProductionPlanItemRecipeLine) => {
        return !recipeLine.Completed
      })

      if (!incompleteRecipeLines.length) {
        this.showCompleteRecipeLineSwitchValue = true;
      }

      return;
    }

    if (this.selectedProductionPlanProductMethod) {
      this.selectedProductionPlanProductMethod = null;
      this.productionPlanProductRecipeLines = [];
      this.navbarTitle.Title = 'Production.SelectRecipeMethodHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
      return;
    }

    if (this.productionPlanProductMethods?.length) {
      this.productionPlanProductMethods = [];
      this.showCompleteMixSwitchValue = false;
      this.showCompleteRecipeLineSwitchValue = false;

      if (this.selectedProductionPlanProduct?.HasSubLines) {
        this.selectedProductionPlanProductMix = null;
        this.navbarTitle.Title = 'Production.PlanProductMixHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);
      } else {               

        const incompleteProducts: ProductionPlanProduct[] = _.filter((this.productionPlanProducts!), (product: ProductionPlanProduct) => {
          return !product.Completed && product !== this.selectedProductionPlanProduct
        });

        if (!incompleteProducts.length) {
          this.showCompleteProductionPlanProductsSwitchValue = true;
        }

        this.selectedProductionPlanProduct = null;
        this.navbarTitle.Title = 'Production.PlanProductHeader';

        this.dataService.updateNavbarTitle(this.navbarTitle);
      }

      return;
    }

    if (this.productionPlanProductRecipeLines?.length) {
      this.productionPlanProductRecipeLines = [];
      this.showCompleteMixSwitchValue = false;
      this.showCompleteRecipeLineSwitchValue = false;

      if (this.selectedProductionPlanProduct?.HasSubLines) {
        this.selectedProductionPlanProductMix = null;
        this.navbarTitle.Title = 'Production.PlanProductMixHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);
      } else {
        this.selectedProductionPlanProduct = null;
        this.navbarTitle.Title = 'Production.PlanProductHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);
      }

      return;
    }

    if (this.selectedProductionPlanProduct) {
      this.selectedProductionPlanProduct = null;
      this.showCompleteMixSwitchValue = false;
      this.showCompleteRecipeLineSwitchValue = false;
      this.navbarTitle.Title = 'Production.PlanProductHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
      return;
    }

    if (this.selectedProductionPlan) {
      this.selectedProductionPlan = null;
      this.navbarTitle.Title = 'Production.PlanHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
      return;
    }
  }

  // ---------------------- Dialogs ----------------------

  openBarcodeScanner() {
    this.displayBarcodeScanner = true;
  }

  openProductionLocations(forceSelection?: boolean) {
    this.closableProductionLocationSelection = !forceSelection;
    this.displayProductionLocations = true;
  }

  // ---------------------- Recipe Line Functions ----------------------

  viewProductionPlanProduct(product: ProductionPlanProduct) {
    this.selectedProductionPlanProduct = product;
    this.loadingCardHeight = 51;

    if (product.HasSubLines) {
      this.loadingMixes = true;
      this.getProductionPlanProductMixes();
      this.navbarTitle.Title = 'Production.PlanProductMixHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
    } else {
      this.loadingMethods = true;
      this.getProductionPlanMethodLines();
      this.navbarTitle.Title = 'Production.PlanProductRecipeLineHeader';
      this.dataService.updateNavbarTitle(this.navbarTitle);
    }
  }

  viewProductionPlanProductRecipeMix(mix: ProductionPlanProductMix) {
    this.selectedProductionPlanProductMix = mix;
    this.getProductionPlanMethodLines();
    this.navbarTitle.Title = 'Production.PlanProductRecipeLineHeader';
    this.dataService.updateNavbarTitle(this.navbarTitle);
  }

  viewProductionPlanProductRecipe(recipeLine: ProductionPlanItemRecipeLine) {
    console.log('viewProductionPlanProductRecipe', recipeLine);

    this.loadingUsedLines = true;

    this.usedLine = new PersistProductionUsedLineRequest({
      Id: null,
      ProductionLineId: 0,
      RecipeLineId: 0,
      ProductId: 0,
      Quantity: 0,
      OriginalQuantity: 0,
      Measure: '',
      MeasureId: 0,
      StockLocationId: 1,
      StockLotId: null
    });

    this.selectedProductionPlanProductRecipeLine = recipeLine;

    this.productionService.getProductionPlanRecipeLine(this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id), recipeLine.Id).subscribe({
      next: (getProductionPlanRecipeLineResponse: ProductionPlanItemRecipeLine) => {
        this.selectedProductionPlanProductRecipeLine = getProductionPlanRecipeLineResponse;
        this.selectedProductionPlanProductRecipeLine.StepAmount = recipeLine.StepAmount;

        this.getRecipeLineUsed();
        let stillRequiredAmount = this.selectedProductionPlanProductRecipeLine.TotalQuantity - this.selectedProductionPlanProductRecipeLine.QuantityUsed;

        if (stillRequiredAmount < 0) {
          stillRequiredAmount = 0;
        }

        this.usedLine = new PersistProductionUsedLineRequest({
          Id: null,
          ProductionLineId: this.selectedProductionPlanProductRecipeLine!.ProductionLineId,
          RecipeLineId: this.selectedProductionPlanProductRecipeLine!.Id,
          ProductId: this.selectedProductionPlanProductRecipeLine!.ProductId,
          Quantity: stillRequiredAmount,
          OriginalQuantity: stillRequiredAmount,
          Measure: this.selectedProductionPlanProductRecipeLine ? this.selectedProductionPlanProductRecipeLine.Measure : 'kg',
          MeasureId: this.selectedProductionPlanProductRecipeLine!.MeasureId,
          StockLocationId: 1,
          StockLotId: null
        });

        console.log('recipeLine', this.selectedProductionPlanProductRecipeLine);

        this.selectedProductionPlanProductRecipeLine.StillRequired = stillRequiredAmount;
        this.selectedProductionPlanProductRecipeLine.OriginalQuantityUsed = this.dataService.deepClone(this.selectedProductionPlanProductRecipeLine.QuantityUsed);

        this.navbarTitle.Title = 'Production.AddToRecipeLineHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);
        this.updateDial();

        this.navbarTitle.Title = 'Production.AddToRecipeLineHeader';
        this.dataService.updateNavbarTitle(this.navbarTitle);
      },
      error: () => {
        this.loadingUsedLines = false;
      },
    });
  }

  getRecipeLineDialColour(recipeLine: ProductionPlanItemRecipeLine) {
    const minErrorValueThreshold: number = recipeLine!.TotalQuantity - recipeLine!.Tolerance * 2;
    const maxErrorValueThreshold: number = recipeLine!.TotalQuantity + recipeLine!.Tolerance * 2;

    const minSuccessValueThreshold: number = recipeLine!.TotalQuantity - recipeLine!.Tolerance;
    const maxSuccessValueThreshold: number = recipeLine!.TotalQuantity + recipeLine!.Tolerance;

    const usedValue = this.selectedProductionPlanProductRecipeLine!.QuantityUsed + this.usedLine!.Quantity;
    //const usedValue = recipeLine!.QuantityUsed + this.usedLine!.Quantity;

    if (usedValue <= minErrorValueThreshold || usedValue >= maxErrorValueThreshold) {
      this.recipeLineDialColour = 'var(--danger)';
    }

    if (
      usedValue >= minSuccessValueThreshold &&
      usedValue <= maxSuccessValueThreshold
    ) {
      this.recipeLineDialColour = 'var(--success)';
    }

    if (
      (usedValue > minErrorValueThreshold &&
        usedValue < minSuccessValueThreshold) ||
      (usedValue > maxSuccessValueThreshold &&
        usedValue < maxErrorValueThreshold)
    ) {
      this.recipeLineDialColour = 'var(--warning)';
    }
  }

  recipeLineWithinTolerance(recipeLine: ProductionPlanItemRecipeLine): boolean {
    const minErrorValueThreshold: number = recipeLine!.TotalQuantity - recipeLine!.Tolerance * 2;
    const maxErrorValueThreshold: number = recipeLine!.TotalQuantity + recipeLine!.Tolerance * 2;

    const minSuccessValueThreshold: number = recipeLine!.TotalQuantity - recipeLine!.Tolerance;
    const maxSuccessValueThreshold: number = recipeLine!.TotalQuantity + recipeLine!.Tolerance;

    const usedValue = recipeLine!.QuantityUsed;
    let productWithinTolerance: boolean = true;

    if (usedValue <= minErrorValueThreshold || usedValue >= maxErrorValueThreshold) {
      productWithinTolerance = false;
    }

    if (usedValue >= minSuccessValueThreshold && usedValue <= maxSuccessValueThreshold) {
      productWithinTolerance = true;
    }

    if ((usedValue > minErrorValueThreshold && usedValue < minSuccessValueThreshold) || (usedValue > maxSuccessValueThreshold && usedValue < maxErrorValueThreshold)) {
      productWithinTolerance = false;
    }

    return productWithinTolerance;
  }

  // Adding Measure

  addRemainingRequiredAmount() {
    this.usedLine!.Quantity = this.selectedProductionPlanProductRecipeLine!.StillRequired ?? 0;
    this.updateDial();
  }

  updateDial() {
    setTimeout(() => {
      this.recipeLineDial.dialCenterHtml = this.recipeLineDialHtml.nativeElement.innerHTML;
      this.getRecipeLineDialColour(this.selectedProductionPlanProductRecipeLine!);
    }, 1);
  }

  updateDialTouchEvent(value: number) {
    if ((value - this.selectedProductionPlanProductRecipeLine!.QuantityUsed) < 0) {

      const minValue = this.selectedProductionPlanProductRecipeLine!.QuantityUsed;

      this.recipeLineDial.value = minValue
      this.recipeLineDial.dialRef.value = minValue;
      return;
    }

    this.usedLine!.Quantity = value - this.selectedProductionPlanProductRecipeLine!.QuantityUsed;

    this.updateDial();
  }

  getRecipeLineUsed() {
    this.loadingUsedLines = true;

    this.productionService.getProductionPlanRecipeLineUsedLines(this.selectedProductionPlanProduct!.Id, this.selectedProductionPlanProductRecipeLine!.Id).subscribe({
      next: (usedLines: ProductionUsedLine[]) => {
        this.selectedProductionPlanProductRecipeLineUsedLines = usedLines;
        this.loadingUsedLines = false;
      },
      error: () => {
        this.loadingUsedLines = false;
      },
    });
  }

  addRecipeLineUsed() {

    let stillRequiredAmount: number = 0;
    if (this.selectedProductionPlanProductRecipeLine) {
      stillRequiredAmount = this.selectedProductionPlanProductRecipeLine?.TotalQuantity - this.selectedProductionPlanProductRecipeLine.QuantityUsed;
    }

    if (stillRequiredAmount < 0) {
      stillRequiredAmount = 0;
    }

    this.usedLine = new PersistProductionUsedLineRequest({
      Id: null,
      ProductionLineId: this.selectedProductionPlanProductRecipeLine!.ProductionLineId,
      RecipeLineId: this.selectedProductionPlanProductRecipeLine!.Id,
      ProductId: this.selectedProductionPlanProductRecipeLine!.ProductId,
      Quantity: stillRequiredAmount,
      OriginalQuantity: stillRequiredAmount,
      Measure: this.selectedProductionPlanProductRecipeLine ? this.selectedProductionPlanProductRecipeLine.Measure : 'kg',
      MeasureId: this.selectedProductionPlanProductRecipeLine!.MeasureId,
      StockLocationId: 1,
      StockLotId: null
    });
  }

  editRecipeLineUsed(usedLine: ProductionUsedLine) {

    console.log('editRecipeLineUsed', usedLine);
    console.log('editRecipeLine', this.selectedProductionPlanProductRecipeLine);

    if (this.selectedProductionPlanProductRecipeLine!.Completed || usedLine.Completed) {
      return;
    }

    this.usedLine = new PersistProductionUsedLineRequest({
      Id: usedLine.Id,
      ProductionLineId: this.selectedProductionPlanProductRecipeLine!.ProductionLineId,
      RecipeLineId: this.selectedProductionPlanProductRecipeLine!.Id,
      ProductId: this.selectedProductionPlanProductRecipeLine!.ProductId,
      Quantity: usedLine.Quantity,
      OriginalQuantity: usedLine.Quantity,
      Measure: this.selectedProductionPlanProductRecipeLine ? this.selectedProductionPlanProductRecipeLine.Measure : 'kg',
      MeasureId: this.selectedProductionPlanProductRecipeLine!.MeasureId,
      StockLocationId: 1,
      StockLotId: usedLine.StockLotId
    });

    const stillRequiredAmount = this.selectedProductionPlanProductRecipeLine!.TotalQuantity - (this.selectedProductionPlanProductRecipeLine!.OriginalQuantityUsed - this.usedLine.Quantity);
    this.selectedProductionPlanProductRecipeLine!.StillRequired = stillRequiredAmount;
    this.selectedProductionPlanProductRecipeLine!.QuantityUsed = this.selectedProductionPlanProductRecipeLine!.OriginalQuantityUsed - this.usedLine.Quantity;

    this.stockLotsButton.TranslationKey = `${usedLine.SystemLotNumber}`;
    this.stockLotsButton.TranslationFromData = true;

    this.updateDial();
  }

  confirmAddEditRecipeLineUsed() {

    this.loadingUsedLines = true;

    if (this.usedLine?.Id) {
      // Update existing measure record  
      this.updateRecipeLineUsed();
    } else {
      // Create a new measure entry
      this.createRecipeLineUsed();
    }

    this.stockLotsButton.TranslationKey = 'Production.ProductionPlanRecipeLineCard.StockLotBtnPlaceholder';
    this.stockLotsButton.TranslationFromData = false;
  }

  createRecipeLineUsed() {
    this.loadingUsedLines = true;

    this.productionService.createProductionPlanRecipeLineUsedLine(this.usedLine).subscribe({
      next: () => {
        this.selectedProductionPlanProductRecipeLine!.QuantityUsed += this.usedLine!.Quantity;
        this.viewProductionPlanProductRecipe(this.selectedProductionPlanProductRecipeLine!);
        this.loadingUsedLines = false;

        this.usedLine = null;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.success,
          Detail: 'Successfully added measure'
        }));
      },
      error: () => {
        this.loadingUsedLines = false;
      },
    });
  }

  updateRecipeLineUsed() {
    this.loadingUsedLines = true;

    this.productionService.updateProductionPlanRecipeLineUsedLine(this.usedLine).subscribe({
      next: () => {

        this.viewProductionPlanProductRecipe(this.selectedProductionPlanProductRecipeLine!);
        this.usedLine = null;
        this.loadingUsedLines = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.success,
          Detail: 'Successfully updated measure'
        }));

      },
      error: () => {
        this.loadingUsedLines = false;
      },
    });
  }

  removeMeasure() {

    this.loadingUsedLines = true;

    this.productionService.deleteProductionPlanRecipeLineUsedLine(this.selectedProductionPlanProductRecipeLine!.ProductionLineId, this.usedLine!.Id!).subscribe({
      next: () => {

        this.viewProductionPlanProductRecipe(this.selectedProductionPlanProductRecipeLine!);
        this.usedLine = null;
        this.loadingUsedLines = false;

        this.dataService.openToast(new ToastMessage({
          Severity: ToastSeverity.success,
          Detail: 'Successfully deleted measure'
        }));

      },
      error: () => {
        this.loadingUsedLines = false;
      },
    });


    //RM - TODO, Waiting for call
  }

  cancelAddingEditingMeasure() {
    this.viewProductionPlanProductRecipe(this.selectedProductionPlanProductRecipeLine!);
  }

  // Show Complete Switch Functions

  checkCompleteProductionPlans(): boolean {
    if (this.productionPlans.length) {
      const completePlans: ProductionPlan[] = _.filter(this.productionPlans, (plan: ProductionPlan) => {
        return plan.NumberOfLinesCompleted === plan.TotalNumberOfLines;
      },
      );

      return completePlans.length ? true : false;
    } else {
      return false;
    }
  }

  checkCompleteProductionPlanProducts(): boolean {
    if (this.productionPlanProducts?.length) {
      const completeProducts: ProductionPlanProduct[] = _.filter(
        this.productionPlanProducts, () => {
          return (
            this.selectedProductionPlanProduct!.TotalNumberOfLines -
            this.selectedProductionPlanProduct!.NumberOfLinesCompleted ===
            0
          );
        },
      );

      return completeProducts.length ? true : false;
    } else {
      return false;
    }
  }

  checkCompleteProductionPlanProductMixes(): boolean {
    if (this.productionPlanProductMixes?.length) {
      const completedMixes: ProductionPlanProductMix[] = _.filter(
        this.productionPlanProductMixes,
        (mix: ProductionPlanProductMix) => {
          return mix.NumberOfLinesCompleted === mix.TotalNumberOfLines;
        },
      );

      return completedMixes.length ? true : false;
    } else {
      return false;
    }
  }

  checkCompleteProductionPlanRecipeLines(): boolean {
    if (this.productionPlanProductRecipeLines?.length) {
      const completeRecipeLines: ProductionPlanItemRecipeLine[] = _.filter(
        this.productionPlanProductRecipeLines,
        (recipeLine: ProductionPlanItemRecipeLine) => {
          return recipeLine.Completed;
        },
      );

      return completeRecipeLines.length ? true : false;
    } else {
      return false;
    }
  }

  // Print Labels
  printLabels() {

    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.question,
      Title: 'Are you sure you want to print labels from this product',
      Message: `
      <div class="text-xl">
      <div class="w-full"><strong>Name:</strong> ${this.selectedProductionPlanProduct!.ProductName}</div>
      <div class="w-full"><strong>Code:</strong> ${this.selectedProductionPlanProduct!.ProductCode}</div>
      <div class="w-full"><strong>Quantity:</strong> ${this.selectedProductionPlanProduct!.TotalNumberOfLines} Items</div>
      <div class="w-full"><strong>Cybake lot:</strong> 486587598</div>
      <div class="w-full"><strong>Expiry:</strong> ${moment().add(2, 'days').format(this.userDetails.Settings.DateFormat.MomentShort)}</div>
      </div>
      `,
      PageSourceName: this.pageSourceName,
      Identifier: 'printLabels',
      ConfirmButtonMethod: new OutputFunction({
        MethodName: 'confirmPrintLabels',
      }),
      ConfirmButton: new CyBakeButton({
        PageSourceName: this.pageSourceName,
        TranslationKey: 'Print labels',
        Identifier: 'location',
        Type: ButtonTypeEnum.output,
        Class: ButtonClassEnum.default,
      }),
    });

    this.dataService.openConfirmationPrompt(options);
  }

  confirmPrintLabels() {
    this.dataService.closeConfirmationPrompt();
    this.printingService.writeToSelectedPrinter('^XA^CF0,20^FO300,30^FD CYBAKE TRACEABILITY ^FS^FO15,50^GB775,3,3^FS^CFB,50^FO30,75^FD100043675^FS^CFB,25^FO550,95^FDExp. 10-08-24^FS^CFB,30^FO210,165^FDWhite Dough Mix^FS^CFB,20^FO350,210^FDM10094^FS^FO15,250^GB775,3,3^FS^BY3,2,100^FO75,265^BC^FDCYB-LOT-100043675^FS^XZ');
  }

  // Complete Actions

  completeAction() {
    this.displayCompleteItem = true;
  }

  confirmCompleteAction(quantityProduced?: number) {

    this.productionService.completeProductionPlanLineAsync(
      this.selectedProductionPlan!.Id, (this.selectedProductionPlanProductMix ? this.selectedProductionPlanProductMix!.Id : this.selectedProductionPlanProduct!.Id), quantityProduced ?? 0).subscribe({
        next: (completeProductionPlanProductRecipeLineResponse: CompleteProductionPlanLineResponse) => {

          // TODO Need to go back to plan products;

          if (this.selectedProductionPlanProduct!.IsTraceable) {
            const options: DialogOptionsModel = new DialogOptionsModel({
              Type: CyBakeConfirmationPrompTypeEnum.success,
              Title: 'Successfully completed production',
              Message: `
              <div class="text-xl">
              <div class="w-full"><strong>Name:</strong> ${this.selectedProductionPlanProduct!.ProductName}</div>
              <div class="w-full"><strong>Code:</strong> ${this.selectedProductionPlanProduct!.ProductCode}</div>
              <div class="w-full"><strong>Quantity:</strong> ${this.selectedProductionPlanProduct!.TotalNumberOfLines} Items</div>
              <div class="w-full"><strong>Cybake lot:</strong> ${completeProductionPlanProductRecipeLineResponse.SystemLotNumber}</div>
              <div class="w-full"><strong>Expiry:</strong> ${moment(completeProductionPlanProductRecipeLineResponse.ExpiryDate).format(this.userDetails.Settings.DateFormat.MomentShort)}</div>
              </div>
              `,
              PageSourceName: this.pageSourceName,
              Identifier: 'completedPlanProduct',
              ConfirmButtonMethod: new OutputFunction({
                MethodName: 'confirmPrintLabels',
              }),
              ConfirmButton: new CyBakeButton({
                PageSourceName: this.pageSourceName,
                TranslationKey: 'Print labels',
                Identifier: 'location',
                Type: ButtonTypeEnum.output,
                Class: ButtonClassEnum.default,
              }),
            });

            this.displayCompleteItem = false;
            this.dataService.openConfirmationPrompt(options);

            this.showCompleteRecipeLineSwitchValue = true;

            if (this.selectedProductionPlanProductMix) {
              this.selectedProductionPlanProductMix!.Completed = true;
            } else {
              this.selectedProductionPlanProduct!.Completed = true;
            }

            if (this.productionPlanProductMethods?.length) {
              if (this.selectedProductionPlanProductMethod) {
                this.selectedProductionPlanProductMethod.CompletedLines++;
              }
            }

          } else {
            const options: DialogOptionsModel = new DialogOptionsModel({
              Type: CyBakeConfirmationPrompTypeEnum.success,
              Title: 'Successfully completed item',
              PageSourceName: this.pageSourceName,
              Identifier: 'confrimCompletedPlanProduct',
              AutoClose: true,
            });

            this.displayCompleteItem = false;
            this.dataService.openConfirmationPrompt(options);
            this.backToProductionPlan();
          }

          // Update complete number on production plan screen
          if (this.selectedProductionPlan) {
            const currentPlan: ProductionPlan = _.find(this.productionPlans!, (plan: ProductionPlan) => {
              return plan.Id === this.selectedProductionPlan!.Id;
            },) as ProductionPlan;

            if (currentPlan) {
              currentPlan.NumberOfLinesCompleted++;
            }
          }

          this.getProductionPlanProducts();

        },
        error: () => {

        },
      });

  }

  productCanBeCompleted(): boolean {

    if (this.productionPlanProductMethods?.length) {

      const uncompletedItems: ProductionPlanProductRecipeMethod[] = _.filter(this.productionPlanProductMethods!, (method: ProductionPlanProductRecipeMethod) => {
        return method.TotalLines !== method.CompletedLines;
      });

      return uncompletedItems.length ? false : true;

    } else {

      const uncompletedItems: ProductionPlanItemRecipeLine[] = _.filter(
        this.productionPlanProductRecipeLines!,
        (recipeLine: ProductionPlanItemRecipeLine) => {
          return !recipeLine.Completed;
        },
      );

      return uncompletedItems.length ? false : true;
    }
  }

  incompleteProduct() {
    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.warning,
      Title: 'Production.CompleteItem.IncompleteMessage',
      PageSourceName: this.pageSourceName,
      Identifier: 'incompleteRecipeLine',
      AutoClose: true,
    });

    this.dataService.openConfirmationPrompt(options);
  }

  incompleteRecipeLine() {
    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.warning,
      Title: 'Production.CompleteRecipeLine.IncompleteMessage',
      PageSourceName: this.pageSourceName,
      Identifier: 'incompleteRecipeLine',
      AutoClose: true,
    });

    this.dataService.openConfirmationPrompt(options);
  }

  completeRecipeLine() {
    this.completeRecipeLineConfirmationOptions = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.question,
      Title: 'Production.CompleteRecipeLine.Message',
      Message: 'Production.CompleteRecipeLine.Message2',
      MessageTranslationParams: {
        itemName: this.selectedProductionPlanProductRecipeLine!.ProductName,
      },
      MessageHtml: false,
      PageSourceName: this.pageSourceName,
      Identifier: 'completeIngrediant',
      ConfirmButton: new CyBakeButton({
        PageSourceName: this.pageSourceName,
        TranslationKey: 'Production.CompleteItem.CompleteBtn',
        Identifier: 'completeConfirmation',
        Type: ButtonTypeEnum.default,
        Class: ButtonClassEnum.success,
      }),
      ConfirmButtonMethod: new OutputFunction({
        MethodName: 'confirmCompleteRecipeLine',
      }),
    });

    this.dataService.openConfirmationPrompt(this.completeRecipeLineConfirmationOptions);
  }

  confirmCompleteRecipeLine() {
    this.completeRecipeLineConfirmationOptions!.Loading = true;
    this.dataService.openConfirmationPrompt(this.completeRecipeLineConfirmationOptions!);

    this.productionService.completeProductionPlanProductRecipeLine(this.selectedProductionPlanProduct!.Id, this.selectedProductionPlanProductRecipeLine!.Id).subscribe({
      next: () => {

        this.getProductionPlanProductRecipeLines();

        if (this.selectedProductionPlanProductMix) {
          this.selectedProductionPlanProductMix!.NumberOfLinesCompleted++;
        }

        if (this.selectedProductionPlanProductMethod) {

          const currentRecipeMeethod: ProductionPlanProductRecipeMethod = _.find(this.productionPlanProductMethods!, (method: ProductionPlanProductRecipeMethod) => {
            return method.Id === this.selectedProductionPlanProductMethod!.Id;
            }, ) as ProductionPlanProductRecipeMethod;          

          if (currentRecipeMeethod) {
            currentRecipeMeethod.CompletedLines++;
          }
          
          this.selectedProductionPlanProductMethod!.CompletedLines++;
        }

        this.dataService.closeConfirmationPrompt();

        const options: DialogOptionsModel = new DialogOptionsModel({
          Type: CyBakeConfirmationPrompTypeEnum.success,
          Title: 'Successfully completed item',
          PageSourceName: this.pageSourceName,
          Identifier: 'confrimCompletedPlanProduct',
          AutoClose: true,
        });

        this.dataService.openConfirmationPrompt(options);
        this.selectedProductionPlanProductRecipeLine = null;

      },
      error: () => {

      },
    });
  }

  //Stock Lot Selection

  openStockLots() {
    //this.loadingRecipeLines = true;

    this.productionService.getStockLots(
      this.selectedProductionPlan!.Id, this.selectedProductionPlanProductRecipeLine!.ProductId).subscribe({
        next: (stockLots: StockLot[]) => {
          this.stockLots = stockLots;
          this.displayStockLots = true;
        },
        error: () => {
          //this.loadingRecipeLines = false;
        },
      });
  }

  confirmStockLot(lot: StockLot) {
    this.displayStockLots = false;

    if (this.usedLine) {
      this.usedLine.StockLotId = lot.Id;
      this.usedLine.SystemLotNumber = lot.SystemLotNumber;
      this.usedLine.ExpiryDate = lot.ExpiryDate;
    }

    this.stockLotsButton.TranslationKey = `${lot.SystemLotNumber}`;
    this.stockLotsButton.TranslationFromData = true;
  }
}

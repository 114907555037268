<div class="w-full">

  @if (contentHeader) {
  <div class="pt-2 pb-4">
    <ng-container *ngTemplateOutlet="contentHeader ? contentHeader : null"></ng-container>
  </div>
  }

  <div class="overflow-y-auto" style="max-height: 300px">
    {{note}}
  </div>

  <div class="col-12 p-0 pt-4">
    <cybake-factory-button [pageSourceName]="pageSourceName"
                           [identifier]="'closeNotes'"
                           [translationKey]="'Production.ProductionPlanNote.CloseButton'"
                           [fullWidth]="true"
                           (clickEvent)="this.closeEvent.emit(true)">
    </cybake-factory-button>
  </div>

</div>

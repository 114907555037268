import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StockLocationType } from '../models/stock-transfers/stocks/stock-location-types.model';
import { Site } from '../models/sites/site.model';
import { GetStockLocationTypeResponse } from '../models/stock-transfers/stocks/stock-location-type-response.model';
import { StockTransferType } from '../models/stock-transfers/stocks/stock-transfer.model';

@Injectable({
  providedIn: 'root',
})
export class StockTransfersService {
  // Services
  http: HttpClient = inject(HttpClient);

  // Service Calls
  getStockLocations(): Observable<StockLocationType[]> {
    let url = 'api/stocktransfer/locations';
    const site: Site = JSON.parse(localStorage.getItem('deviceSite')!);
    if (site) {
      url += `?siteId=${site.Id}`;
    }

    return this.http.get<GetStockLocationTypeResponse>(environment.api + url).pipe(
      map((getStockLocationTypeResponse: GetStockLocationTypeResponse) => {
        return getStockLocationTypeResponse.StockLocationTypes;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  createStockTransferRequest(request?: StockTransferType | null): Observable<null> {
    const url = `api/stocktransfer/create`;

    return this.http.post<null>(environment.api + url, request).pipe(
      map((response: null) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  constructor() {}
}

<div [ngClass]="{'p-float-label mt-4' : label}">
  <p-calendar #cybakeCalendar
              [(ngModel)]="value"
              [touchUI]="true"
              [readonlyInput]="readOnly"
              [autofocus]="false"
              inputId="{{pageSourceName}}_{{identifier}}_calendar_input"
              styleClass="cybake-calendar-container w-full h-full"
              inputStyleClass="cybake-calendar-input w-full"
              [firstDayOfWeek]="0"
              [showClear]="showClear"
              [selectOtherMonths]="true"
              appendTo="body"
              placeholder="{{label ? '' : (placeholder ? (placeholder | translate) : '')}}"
              dateFormat="{{userDetails!.Settings.DateFormat.PrimeShort}}"
              [timeOnly]="timeOnly"
              (onSelect)="optionSelected($event);this.valueChange.emit(value)"
              [minDate]="minDate ? minDate : null"
              [maxDate]="maxDate ? maxDate : null"
              [showTime]="showTime ? true : false"
              [hourFormat]="(userDetails!.Settings.TimeFormat.Name === '24 Hour' ? '24' : '12')"
              [ngClass]="{'model-required': required && !value}"
              [hideOnDateTimeSelect]="showTime ? false : true"
              [disabled]="disabled">

    <ng-template pTemplate="header">
      <div class="float-end cybake-calendar-header-div" *ngIf="!timeOnly">
        <div class="vertically-center-right-align">
          <cybake-factory-button [pageSourceName]="pageSourceName"
                                 [identifier]="'calendarSetToday'"
                                 [translationKey]="'CyBakeTemplates.Calendar.TodayButton'"
                                 (clickEvent)="setTodaysDate()">
          </cybake-factory-button>
        </div>        
      </div>
    </ng-template>

  </p-calendar>

  @if (label) {
  <label id="{{pageSourceName}}_{{identifier}}_calendar_label"
         for="{{pageSourceName}}_{{identifier}}_calendar_input"
         [translate]="label"
         style="width: 100%; left: 0; text-align: center; font-size: 1em; ">
  </label>
  }

</div>

<div
  id="{{ pageSourceName + '_' + identifier }}_stockTransfer"
  class="cybake-stock-location bottom-border-radial flex flex-row cursor-pointer h-20 bg-white pl-3 items-center relative border border-slate-300 justify-content-between"
>
  <div class="flex flex-row gap-3 items-center">
    <p
      id="{{ pageSourceName + '_' + identifier }}_stockTransferName"
      class="text-base font-semibold"
    >
      {{ name | translate }}
    </p>
  </div>
  <span class="arrow-span mr-2">
    <i
      id="{{ pageSourceName }}{{
        identifier ? '_' + identifier : ''
      }}_stockTransfer_rightIicon"
      class="fa-icon fa-thin fa-angle-right text-4xl text-gray-300"
    ></i>
  </span>
</div>

import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { faker } from '@faker-js/faker';
import { ProductionLocation } from '../../models/production/locations/production-location.model';
import { ProductionPlan } from '../../models/production/plans/production-plan.model';
import { ProductionShift } from '../../models/production/shifts/production-shift.model';
import moment from 'moment';
import { StockLot } from '../../models/production/stocklots/stock-lot.model';
import { ProductionPlanProduct } from '../../models/production/plans/production-plan-product.model';
import { ProductionPlanProductMix } from '../../models/production/plans/production-plan-recipe-line-mix.model';
import { ProductionPlanItemRecipeLine } from '../../models/production/plans/production-plan-item-recipe-line.model';
import { ProductionPlanProductRecipeMethod } from '../../models/production/plans/production-plan-recipe-methods.model';
import { ProductionUsedLine } from '../../models/production/plans/production-used-line.model';
import { GetProductionPlanProductRecipeLinesResponse } from '../../models/production/plans/get-production-plan-product-recipe-lines-response.model';
import { CompleteProductionPlanLineRecipeLineResponse } from '../../models/production/plans/complete-recipe-line-response.model';
import { GetProductionPlanProductRecipeMethodLineResponse } from '../../models/production/plans/get-production-plan-product-recipe-method-line-response.model';
import { CompleteProductionPlanLineResponse } from '../../models/production/plans/complete-production-plan-line-response.model';

@Injectable({
  providedIn: 'root',
})
export class ProductionServiceMock {
  // Variables
  weightUnits: string[] = ['g', 'kgs', 'litres', 'lbs', 'ml', 'tonnes', 'oz'];

  containers: string[] = ['units', 'boxes', 'pallets'];

  ingrediants: string[] = [
    'Flour',
    'Water',
    'Yeast',
    'Butter',
    'Milk',
    'Sugar',
    'Salt',
  ];

  productionLocations: string[] = [
    'Bread Line (LC)',
    'Confectionary Line (HC)'
  ];

  // Production Plans
  productionPlans: string[] = [
    'Thursday Breads',
    'Thursday Savouries',
    'Thursday Morning Goods',
    'Thursday R&D Test Plan',
  ];

  productionPlanNotes: string[] = [
    'Please ensure these are completed first so can be loaded for early deliveries',
    'Staffing issues mean that this line is intentionally less than normal',
    'High humidity expected which may require additional proving time',
    'This is a planned run of test lines and is not for storage in warehouse stock',
  ];

  productionPlanItems: number[] = [
    4,
    1,
    7,
    1,
  ];

  productionPlanStartTimes: string[] = [
    '14:15',
    '16:30',
    '17:15',
    '21:30',
  ];

  methodSteps: string[] = [
    '1 - Mixing',
    '2 - Bulk Fermentation',
    '3 - Scaling',
    '4 - Intermediate Proof',
    '5 - Make Up & Final Fermentation',
    '6 - Decoration (before baking)',
    '7 - Baking',
  ];

  methodDescriptions: string[] = [
    '<p>Mixing Spiral</p><p>Add all ingredients to mixing bowl</p><p>4 minutes on slow</p><p>Scrape down</p><p>6 minutes on fast</p><p>Dough temperature: 24C</p>',
    '<p>Bulk Fermentation: 2 x 20 minutes at ambient tempertature</p>',
    '<p>Scale: 320g</p><p>Make up half long, not too tight</p>',
    '<p>Intermediate Proof: 15 minutes at ambient temperature</p>',
    '<p>Make up: Long, 50cm.</p><p>Stretch the tips of the baguette</p><p>Final Fermentation: 40 minutes at 28C and 85% R.H.</p>',
    '<p>Must be done before baking</p><p>Dust with flour</p><p>Score 5 times on a slight diagonal, using a lame</p>',
    '<p>Oven temperature: 230C in deck oven</p><p>Baking time: 25 minutes with steam</p>',
  ];

  getCurrentProductionShift(): Observable<ProductionShift> {
    const shift: ProductionShift = new ProductionShift({
      Id: faker.number.int(),
      Name: 'Thursday Night',
      StartTime: new Date((moment().format('YYYY-MM-DD 14:00'))),
      EndTime: new Date((moment().format('YYYY-MM-DD 04:00')))
    });

    return of(shift).pipe(delay(1000));
  }

  getStockLots(): Observable<StockLot[]> {
    const stockLots: StockLot[] = [];

    for (let i = 0; i < 20; i++) {
      const stockLot: StockLot = new StockLot({
        Id: faker.number.int(),
        SystemLotNumber: faker.number.int({ min: 100000000, max: 999999999 }),
        ExpiryDate: faker.date.soon(),
      });

      stockLots.push(stockLot);
    }

    return of(stockLots).pipe(delay(1000));
  }

  getProductionPlans(): Observable<ProductionPlan[]> {
    const plans: ProductionPlan[] = [];

    for (let i = 0; i < 4; i++) {
      const plan: ProductionPlan = new ProductionPlan({
        Id: i + 1,
        Name: this.productionPlans[i],
        Note: this.productionPlanNotes[i],
        TotalNumberOfLines: this.productionPlanItems[i],
        NumberOfLinesCompleted: 0,
        StartTime: this.productionPlanStartTimes[i],
        Sequence: i
      });

      plans.push(plan);
    }

    return of(plans);
  }

  getProductionLocations(): Observable<ProductionLocation[]> {
    const locations: ProductionLocation[] = [];

    for (let i = 0; i < 2; i++) {
      const location: ProductionLocation = {
        Id: i,
        Name: this.productionLocations[i],
        Sequence: i,
      };

      locations.push(location);
    }

    return of(locations);
  }

  getProductionPlanProducts(): Observable<ProductionPlanProduct[]> {
    return of([]);
  }

  getProductionPlanProductMixes(): Observable<ProductionPlanProductMix[]> {
    return of([]);
  }

  getProductionPlanMethodLines(): Observable<ProductionPlanProductRecipeMethod[]> {
    return of([]);
  }

  getMockedRecipeLines(): GetProductionPlanProductRecipeLinesResponse {

    const response: GetProductionPlanProductRecipeLinesResponse = {
      RecipeLines: [],
    };

    return response;
  }

  getProductionPlanRecipeLines(): Observable<ProductionPlanItemRecipeLine[]> {
    const response = this.getMockedRecipeLines();
    return of(response.RecipeLines);
  }

  getProductionPlanMethodLine(): Observable<GetProductionPlanProductRecipeMethodLineResponse> {
    return of();
  }

  getProductionPlanRecipeLine(productionPlanId: number, productionPlanLineId: number, recipeLineId: number): Observable<ProductionPlanItemRecipeLine> {
    const response = this.getMockedRecipeLines();
    const recipeLine = response.RecipeLines.find((x: ProductionPlanItemRecipeLine) => x.Id == recipeLineId) as ProductionPlanItemRecipeLine;
    return of(recipeLine);
  }

  getProductionPlanRecipeLineUsedLines(): Observable<ProductionUsedLine[]> {

    const lines: ProductionUsedLine[] = [];

    for (let i = 0; i < 3!; i++) {
      const measure = faker.number.int({ min: 0, max: 6 });

      const line: ProductionUsedLine = {
        Id: faker.number.int(),
        StockLotId: faker.number.int(),
        SystemLotNumber: faker.number.int().toString(),
        ExpiryDate: moment().add(i * 2, 'days').toDate(),
        StockLocationId: faker.number.int(),
        Quantity: faker.number.int({ min: 1, max: 30 }),
        OriginalQuantity: faker.number.int({ min: 1, max: 30 }),
        MeasureId: measure,
        Measure: this.weightUnits[measure],
        Completed: false
      };

      lines.push(line);
    }

    return of(lines);
  }

  getProductionPlanMethodImage(): Observable<Blob> {
    return of();
  }

  createProductionPlanRecipeLineUsedLine(): Observable<null> {
    return of(null);
  }

  updateProductionPlanRecipeLineUsedLine(): Observable<null> {
    return of(null);
  }

  deleteProductionPlanRecipeLineUsedLine(): Observable<null> {
    return of(null);
  }

  getProductionPlanProductImage(productId: number): Observable<string> {
    return of(`assets/images/mock/${productId}.jpeg`).pipe(delay(1500));
  }

  completeProductionPlanProductRecipeLine(): Observable<CompleteProductionPlanLineRecipeLineResponse> {
    return of(true).pipe(delay(2000));
  }

  completeProductionPlanLineAsync(): Observable<CompleteProductionPlanLineResponse> {
    return of(new CompleteProductionPlanLineResponse()).pipe(delay(2000));
  }

}

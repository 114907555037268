import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MessageService, PrimeNGConfig, Translation } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Title } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { MenuService } from './_shared/services/menu.service';
import { MenuComponent } from './menu/menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { DataService } from './_shared/services/data.service';
import { ToastMessage } from './_shared/models/common/toast-message.model';
import { CyBakeToastComponent } from './_shared/components/toast/toast.component';
import { DialogOptionsModel } from './_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';
import { DialogComponent } from './_shared/components/dialog/dialog.component';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { OutputFunction } from './_shared/models/common/output-function.model';
import { ButtonTypeEnum } from './_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from './_shared/models/cybake/button/button-class.enum';
import { CyBakeButton } from './_shared/models/cybake/button/button.model';
import { CyBakeConfirmationPrompTypeEnum } from './_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';
import { PwaService } from './_shared/services/pwa.service';
import { AddAppToHomeScreenComponent } from './_shared/dialogs/add-app-to-home-screen/add-app-to-home-screen.component';
import { Platform } from '@angular/cdk/platform';
import { SettingsComponent } from './settings/settings.component';
import { AppInfoComponent } from './_shared/dialogs/app-info/app-info.component';

@Component({
  selector: 'cybake-factory-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    TranslateModule,
    MenuModule,
    SidebarModule,
    MenuComponent,
    NavbarComponent,
    CyBakeToastComponent,
    DialogComponent,
    AddAppToHomeScreenComponent,
    SettingsComponent,
    AppInfoComponent
  ],
  providers: [MenuService, MessageService, TranslateService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  // Children
  @ViewChild(Sidebar) menuSidebar!: Sidebar;

  // Services
  router: Router = inject(Router);
  dataService: DataService = inject(DataService);
  translateService: TranslateService = inject(TranslateService);
  primeNgConfig: PrimeNGConfig = inject(PrimeNGConfig);
  messageService: MessageService = inject(MessageService);
  titleService: Title = inject(Title);
  swUpdate: SwUpdate = inject(SwUpdate);
  location: Location = inject(Location);
  pwaService: PwaService = inject(PwaService);
  platform: Platform = inject(Platform);

  // Variables
  title: string = 'Cybake Factory';
  pageSourceName: string = 'appComponent';
  versionNumber!: string;
  device?: number;
  declinedAddToHomeScreenSetting: boolean = false;

  // Menus
  menuVisible: boolean = false;
  menuPosition: string = 'left';

  // Toast Message
  toast: ToastMessage | undefined;

  // Add to Home Screen Dialog
  displayAddToHomeScreen: boolean = false;

  // eslint-disable-next-line
  promptEvent!: any;

  // Confirmation Prompt
  confirmationPrompOptions!: DialogOptionsModel;
  displayConformationPrompt: boolean = false;
  displaySettingsDialog: boolean = false;
  displayAppInfoDialog: boolean = false;

  // Subscriptions
  subscriptionOpenToast!: Subscription;
  subscriptionOpenConfirmationPrompt!: Subscription;
  subscriptionCloseConfirmationPrompt!: Subscription;
  subscriptionConfirmationPromptOutputMethod!: Subscription;
  subscriptionOpenUpdateDialog!: Subscription;
  subscriptionOpenAddToHomeScreenDialog!: Subscription;
  subscriptionOpenSettingsDialog!: Subscription;
  subscriptionOpenAppInfoDialog!: Subscription;

  ngOnInit() {

    if (localStorage.getItem('declinedAddToHomeScreen')) {
      this.declinedAddToHomeScreenSetting = JSON.parse(localStorage.getItem('declinedAddToHomeScreen')!);
    }

    console.log('swUpdate', this.swUpdate);
    if (this.swUpdate.isEnabled) {

      console.log('swUpdate isEnabled', this.swUpdate.isEnabled);

      this.pwaService.activate();

      setTimeout(() => {
        if (!this.declinedAddToHomeScreenSetting) {
          this.initPwaPrompt();
        }
      }, 1000);

      this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
        console.log('swUpdate, event', event);

        if (event.type === "VERSION_READY") {

          console.log(location.pathname)
          const loggedIn = (location.pathname === '/' || location.pathname === '/login-pin' || location.pathname === '/login') ? false : true;

          if (loggedIn) {
            this.dataService.showUpdateIconNavbar(true);
          }

          const options: DialogOptionsModel = new DialogOptionsModel({
            Type: CyBakeConfirmationPrompTypeEnum.info,
            Title: 'UpdateApplicationPopup.Title',
            Message: `UpdateApplicationPopup.Message`,
            MessageHtml: false,
            PageSourceName: this.pageSourceName,
            Identifier: 'updateApplicationToLatest',
            Closable: loggedIn,
            ConfirmButtonMethod: new OutputFunction({
              MethodName: 'updateApplication'
            }),
            ConfirmButton: new CyBakeButton({
              PageSourceName: this.pageSourceName,
              TranslationKey: 'UpdateApplicationPopup.UpdateBtn',
              Identifier: 'updateApplication',
              IconKey: ['fa-duotone fa-solid', 'fa-download'],
              Type: ButtonTypeEnum.default,
              Class: ButtonClassEnum.default,
            })
          });

          this.confirmationPrompOptions = options;
          this.displayConformationPrompt = true;
        }
      });
    } else {
      console.log('Service worker updates are not enabled.');
    }

    this.translateService.setDefaultLang('en');
    this.titleService.setTitle('Cybake Factory');
    this.versionNumber = environment.version;

    // Open Update Dialog
    this.subscriptionOpenUpdateDialog = this.dataService.openUpdateDialogState.subscribe((showDialog: boolean) => {
      if (showDialog) {
        this.openUpdateDialog();
      } else {
        this.displayConformationPrompt = false;
      }
    });

    // Open Settings Dialog
    this.subscriptionOpenSettingsDialog = this.dataService.openSettingsDialogState.subscribe((showDialog: boolean) => {
      this.displaySettingsDialog = showDialog;
    });

    // Open App Info Dialog
    this.subscriptionOpenAppInfoDialog = this.dataService.openAppInfoDialogState.subscribe((showDialog: boolean) => {
      this.displayAppInfoDialog = showDialog;
    });

    // Open Add to Home Screen Dialog
    this.subscriptionOpenAddToHomeScreenDialog = this.dataService.showAddToHomeScreenState.subscribe((device: number | null) => {
      if (device) {
        this.device = device;
        this.displayAddToHomeScreen = true;
      } else {
        this.device = undefined;
        this.displayAddToHomeScreen = false;
      }
    });

    // Open Toast Message
    this.subscriptionOpenToast = this.dataService.openToastState.subscribe((toast: ToastMessage) => {
      console.log('subscriptionOpenToast', toast)
    });

    // Confirmation Prompt - Open
    this.subscriptionOpenConfirmationPrompt = this.dataService.openConfirmationPromptState.subscribe((promptOptions: DialogOptionsModel) => {
      this.confirmationPrompOptions = promptOptions;
      this.displayConformationPrompt = true;

      if (promptOptions.AutoClose) {
        setTimeout(() => {
          this.displayConformationPrompt = false;
        }, promptOptions.AutoCloseDuration);
      }
    });

    // Confirmation Prompt - Close
    this.subscriptionCloseConfirmationPrompt = this.dataService.closeConfirmationPromptState.subscribe(() => {
      this.displayConformationPrompt = false;
    });

    // Confirmation Prompt - Output Function
    this.subscriptionConfirmationPromptOutputMethod = this.dataService.confirmationPromptOutputFunctionState.subscribe((outputFunction: OutputFunction) => {
      console.log('subscriptionConfirmationPromptOutputMethod', outputFunction);

      // eslint-disable-next-line
      if ((this as any)[outputFunction.MethodName]) {
        // eslint-disable-next-line
        (this as any)[outputFunction.MethodName](outputFunction.Param);
      }
    });
  }

  initPwaPrompt() {
    console.log('initPwaPrompt', this.platform);

    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: Event) => {
        event.preventDefault();
        console.log('event', event);
        this.promptEvent = event;
        this.dataService.showAddToHomeScreen(1);
      });

      window.addEventListener('appinstalled', () => {
        // Hide the app-provided install promotion
        this.displayAddToHomeScreen = false;
        // Clear the deferredPrompt so it can be garbage collected
        this.promptEvent = null;
        // Optionally, send analytics event to indicate successful install
        console.log('PWA was installed');
      });
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this.dataService.showAddToHomeScreen(2);
      }
    }
  }

  updateApplication() {
    window.location.reload();
  }

  confirmAndroidAddToHomeScreen() {
    this.promptEvent.prompt();
  }

  openAddToHomeScreenDialog() {

  }

  declinedAddToHomeScreen() {
    localStorage.setItem('declinedAddToHomeScreen', JSON.stringify(true));
  }

  openUpdateDialog() {
    const loggedIn = (location.pathname === '/' || location.pathname === '/login-pin' || location.pathname === '/login') ? false : true;

    if (loggedIn) {
      this.dataService.showUpdateIconNavbar(true);
    }

    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.info,
      Title: 'UpdateApplicationPopup.Title',
      Message: `UpdateApplicationPopup.Message`,
      MessageHtml: false,
      PageSourceName: this.pageSourceName,
      Identifier: 'updateApplicationToLatest',
      Closable: loggedIn,
      ConfirmButtonMethod: new OutputFunction({
        MethodName: 'updateApplication'
      }),
      ConfirmButton: new CyBakeButton({
        PageSourceName: this.pageSourceName,
        TranslationKey: 'UpdateApplicationPopup.UpdateBtn',
        Identifier: 'updateApplication',
        IconKey: ['fa-duotone fa-solid', 'fa-download'],
        Type: ButtonTypeEnum.default,
        Class: ButtonClassEnum.default,
      })
    });

    this.confirmationPrompOptions = options;
    this.displayConformationPrompt = true;
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .subscribe((res: Translation) => this.primeNgConfig.setTranslation(res));
  }

}

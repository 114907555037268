export class StockLot {
  Id!: number;
  SystemLotNumber!: number;
  ExpiryDate!: Date;

  FormattedExpiryDate?: string;

  constructor(values: StockLot) {
    Object.assign(this, values);
  }
}

import { Component, OnInit, inject } from '@angular/core';
import { CybakeInputEmailComponent } from '../_shared/components/input-email/input-email.component';
import { CybakeInputPasswordComponent } from '../_shared/components/input-password/input-password.component';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '../_shared/services/authentication.service';
import { AuthenticationServiceMock } from '../_shared/services/mock-services/authentication.service.mock';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { CompanyService } from '../_shared/services/company.service';
import { CompanyServiceMock } from '../_shared/services/mock-services/company.service.mock';
import { UserService } from '../_shared/services/user.service';
import { UserServiceMock } from '../_shared/services/mock-services/user.service.mock';
import { VibrationService } from '../_shared/services/vibration.service';
import { Message } from '../_shared/models/cybake/message/message.model';
import { MessageSeverityEnum } from '../_shared/models/cybake/message/message-severity.enum';
import { CyBakeMessageComponent } from '../_shared/components/message/message.component';
import { UserDetails } from '../_shared/models/user/user-details.model';
import { CommonModule } from '@angular/common';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';

@Component({
  selector: 'cybake-factory-login',
  standalone: true,
  imports: [
    CybakeInputEmailComponent,
    CyBakeButtonComponent,
    CybakeInputPasswordComponent,
    CyBakeMessageComponent,
    TranslateModule,
    CommonModule,
  ],
  providers: [MessageService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  // Services
  translate = inject(TranslateService);
  messageService = inject(MessageService);
  router = inject(Router);
  vibrationService = inject(VibrationService);
  authenticationService: AuthenticationService | AuthenticationServiceMock =
    environment.mock
      ? inject(AuthenticationServiceMock)
      : inject(AuthenticationService);
  companyService: CompanyService | CompanyServiceMock = environment.mock
    ? inject(CompanyServiceMock)
    : inject(CompanyService);
  userService: UserServiceMock | UserService = environment.mock
    ? inject(UserServiceMock)
    : inject(UserService);

  // Variables
  pageSourceName = 'login';
  username: string = '';
  password: string = '';
  failedLoginCount: number = 0;
  loggingIn: boolean = false;
  captcha: boolean = false;
  versionNumber!: string;
  existingUsers: UserDetails[] = [];
  failedGetUser: boolean = false;

  // Existing Users Button
  existingUserButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'goToPinLogin',
    Class: ButtonClassEnum.warning,
    IconKey: ['fa-duotone', 'fa-users'],
  });

  // Failed Login Message
  failedLoginMessage: Message = new Message({
    PageSourceName: this.pageSourceName,
    Identifier: 'failedLoginMessage',
    Severity: MessageSeverityEnum.error,
    TranslationKey: 'Login.FailedLoginAttempt',
  });

  ngOnInit() {
    this.getExistingUsers();
    this.versionNumber = environment.version;

    if (localStorage.getItem('users')) {
      this.existingUsers = JSON.parse(localStorage.getItem('users')!);
    }
  }

  getExistingUsers() {}

  login() {
    if (!this.username && !this.password) {
      return;
    }

    this.loggingIn = true;
    this.failedGetUser = false;

    this.authenticationService.login(this.username, this.password).subscribe({
      next: () => {
        this.userService.getUserDetails().subscribe({
          next: () => {
            this.router.navigateByUrl('/dashboard');
            this.loggingIn = false;
            this.failedLoginCount = 0;
          },
          error: () => {
            this.failedLoginMessage.TranslationKey = 'Login.FailedGetUser';
            this.failedGetUser = true;
            this.loggingIn = false;
            this.vibrationService.vibrate();
          },
        });
      },
      error: () => {
        this.failedLoginMessage.TranslationKey = 'Login.FailedLoginAttempt';
        this.failedLoginCount++;
        this.loggingIn = false;
        this.vibrationService.vibrate();

        if (this.failedLoginCount > 3) {
          this.showCaptcha();
        }
      },
    });
  }

  showCaptcha() {
    this.captcha = true;
  }

  goToPinLogin() {
    this.router.navigateByUrl('/login-pin');
  }
}

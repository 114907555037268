<div class="grid m-0">
  <div class="col-12 p-0 pb-2">
    <cybake-factory-listbox [options]="locationsListBox.Options"
                            [(value)]="locationsListBox.Value"
                            [loading]="locationsListBox.Loading"
                            [optionValue]="locationsListBox.OptionValue"
                            [optionLabel]="locationsListBox.OptionLabel"
                            [radioButtons]="true"
                            [height]="200">
    </cybake-factory-listbox>
  </div>

  <div class="col-12 p-0 pt-2">
    <cybake-factory-button [pageSourceName]="confirmButton.PageSourceName"
                           [identifier]="confirmButton.Identifier"
                           [translationKey]="confirmButton.TranslationKey"
                           [disabled]="locationsListBox.Value === null"
                           [class]="confirmButton.Class"
                           [iconKey]="confirmButton.IconKey"
                           [iconClass]="confirmButton.IconClass"
                           [fullWidth]="true"
                           (clickEvent)="confirmLocation()">
    </cybake-factory-button>
  </div>
</div>

@if (!(!subtitle && !count)) {
  <div
    id="{{ pageSourceName + '_' + identifier }}_productCard"
    class="cybake-stock-location bottom-border-radial flex flex-row cursor-pointer h-36 bg-white px-3 items-center relative border border-slate-300 justify-content-between"
  >
    <div class="flex flex-column md:align-items-center w-11">
      <p
        id="{{ pageSourceName + '_' + identifier }}_productCardTitle"
        cybake-text-style="title-text"
      >
        {{ name | translate }}
      </p>
      <div class="flex flex-row md:align-items-center gap-2">
        @if (!index) {
          <p
            id="{{ pageSourceName + '_' + identifier }}_productCardSubtitle"
            cybake-text-style="body-small"
          >
            {{ subtitle }}
          </p>
        }
        @if (index) {
          <p
            id="{{ pageSourceName + '_' + identifier }}_productCardSubtitle"
            class="text-xs font-normal"
          >
            (There are currently <b>{{ count }} item(s)</b> in your tranfer)
          </p>
        }
      </div>
    </div>
    <div class="flex flex-row gap-3 items-center">
      @if (showArrowIcon) {
        <span class="arrow-span">
          <i
            id="{{ pageSourceName }}{{
              identifier ? '_' + identifier : ''
            }}_productCard_rightIicon"
            class="fa-icon fa-thin fa-angle-right text-4xl text-gray-300"
          ></i>
        </span>
      }
    </div>
  </div>
}

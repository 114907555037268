<div
  id="outerContainer"
  [ngClass]="{ 'disable-slider': loading || disabled }"
  style="position: relative; padding-bottom: 60px"
>
  <div #track class="track_drag animate">
    <div #dragItem id="item">
      @if (!loading) {
        <i
          class="fa-icon fa-solid fa-chevron-right vertically-center"
          style="pointer-events: none"
        ></i>
      }

      @if (loading) {
        <i
          class="fa-icon fa-regular fa-circle-notch fa-spin completing-spinning-icon vertically-center"
          style="pointer-events: none"
        ></i>
      }
    </div>
    <p #end class="track_text track_text--end animate">Confirmed</p>
    <p #after class="track_text track_text--after animate"></p>
    <p #before class="track_text track_text--before animate">
      Slide to confirm
    </p>
  </div>
</div>

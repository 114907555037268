import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
//import { Observable, catchError, map, throwError } from 'rxjs';
//import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  // Services
  http: HttpClient = inject(HttpClient);

  // Service Calls

  //getCompanyDetails(): Observable<any> {
  //    //if (localStorage.getItem("modules") !== null) {
  //    //  let data = JSON.parse(localStorage.getItem("modules"));
  //    //  return of(data)
  //    //}

  //    const url = 'api/authentication/company/modules';

  //    return this.http.get(environment.api + url).pipe(
  //        map((getCompanyModulesResponse: any) => {
  //            return getCompanyModulesResponse;
  //        }),
  //        catchError((error: HttpErrorResponse) => {
  //            return throwError(() => error);
  //        })
  //    );
  //}
}

import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import * as _ from 'underscore';
import { MenuItem, MessageService, SharedModule } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MenuService } from '../_shared/services/menu.service';
import { MenuModule } from 'primeng/menu';

import { Router, RouterOutlet } from '@angular/router';
import { MenuServiceMock } from '../_shared/services/mock-services/menu.service.mock';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs';
import { GroupedMenu } from '../_shared/models/menus/grouped-menu.model';
import { Menu } from '../_shared/models/menus/menu.model';
import { SkeletonModule } from 'primeng/skeleton';
import { DataService } from '../_shared/services/data.service';

@Component({
  selector: 'cybake-factory-menu',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    MenuModule,
    SharedModule,
    SkeletonModule
  ],
  providers: [MenuService, MessageService],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent implements OnInit {
  // Services
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  dataService: DataService = inject(DataService);
  messageService: MessageService = inject(MessageService);
  menuService: MenuService | MenuServiceMock = environment.mock ? inject(MenuServiceMock) : inject(MenuService);

  // Inputs/Outputs
  @Output() closeMenuEvent: EventEmitter<boolean> = new EventEmitter();

  // Variables
  pageSourceName: string = 'menu';

  // Menu
  loadingMenus: boolean = false;
  failedGettingMenus: boolean = false;
  menus: MenuItem[] = [];

  ngOnInit() {
    const defaultMenus: GroupedMenu[] = this.menuService.mapMenusToCyBakeMenu([]);
    this.menus = this.mapMenusToPrimeNgMenuItems(defaultMenus);
    this.getMenus();
  }

  getMenus() {
    this.loadingMenus = true;
    this.failedGettingMenus = false;
    this.menuService.getMenus().subscribe({
      next: (getMenusResponse: Menu[]) => {
        getMenusResponse = _.sortBy(getMenusResponse, 'Sequence');
        const mappedMenus: GroupedMenu[] = this.menuService.mapMenusToCyBakeMenu(getMenusResponse);

        this.menus = this.mapMenusToPrimeNgMenuItems(mappedMenus);

        this.loadingMenus = false;
      },
      error: () => {
        forkJoin([
          this.translateService.get('Login.FailedLoginMsg'),
          this.translateService.get('Login.FailedLoginMsg'),
        ]).subscribe((translations: string[]) => {
          this.messageService.add({
            severity: 'error',
            detail: translations[0],
            summary: translations[1],
          });
        });

        this.failedGettingMenus = true;
        this.loadingMenus = false;
      },
    });
  }

  mapMenusToPrimeNgMenuItems(mappedMenus: GroupedMenu[]): MenuItem[] {

    const menuItems: MenuItem[] = [];

    _.forEach(mappedMenus, (groupedMenu: GroupedMenu) => {
      //if (groupedMenu[0].GroupId) {

      const menuItem: MenuItem = {
        label: groupedMenu.label,
        items: [],
      };

      _.forEach(groupedMenu.items, (menu: Menu) => {
        const mappedMenu: MenuItem = {
          id: menu.Id ? menu.Id.toString() : undefined,
          label: menu.Name,
          icon: menu.DisplayContent,
          routerLink: menu.Title ? menu.Title : null,
          routerLinkActiveOptions: { exact: true },
          disabled: !menu.Enabled
        };

        if (menu.Command) {

          switch (menu.Command) {
            case 'logout':
              mappedMenu.command = () => {
                this.logout();
              };
              break;
            case 'openAbout':
              mappedMenu.command = () => {
                this.openAbout();
              };
              break;
            case 'openSettings':
              mappedMenu.command = () => {
                this.openSettings();
              };
              break;
          }
          
        }

        if (!menuItem.items?.length) {
          menuItem.items = [];
        }

        menuItem.items.push(mappedMenu);
      });

      menuItems.push(menuItem);
    });

    // Translate headers - RM: Hopefully fixed in future Prime NG with custom menu header template

    _.forEach(menuItems, (menuItem: MenuItem) => {
      if (menuItem.label) {
        this.translateService
          .get(menuItem.label)
          .subscribe((translation: string) => {
            menuItem.label = translation;
          });
      }
    });

    return menuItems;
  }

  logout() {
    localStorage.removeItem('authData');
    localStorage.removeItem('menus');
    localStorage.removeItem('user');
    localStorage.removeItem('sites');
    this.router.navigateByUrl('');
    this.closeMenuEvent.emit(false);
  }

  openSettings() {
    this.dataService.openSettingsDialog(true);
    this.closeMenuEvent.emit(false);
  }

  openAbout() {
    this.dataService.openAppInfoDialog(true);
    this.closeMenuEvent.emit(false);
  }

  closeMenu() {
    this.closeMenuEvent.emit(false);
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { UnCamelCasePipe } from '../../pipes/un-camel-case.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'cybake-factory-search-input',
  standalone: true,
  imports: [
    InputTextModule,
    SkeletonModule,
    CommonModule,
    FormsModule,
    UnCamelCasePipe,
    TranslateModule,
    TooltipModule,
  ],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent {
  @Input() pageSourceName: string = '';
  @Input() identifier: string = '';
  @Input() value: string | null = null;
  @Output() valueChange: EventEmitter<string | null> = new EventEmitter();

  @Input() label: string = '';

  @Input() class: string = '';
  @Input() inputClass: string = '';

  @Input() translationKey: string = '';
  @Input() translationParams: object = {};


  @Input() placeholder: boolean = false;
  @Input() loading: boolean = false;
  @Input() showClear: boolean = true;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() hidden: boolean = false;
  @Input() clearable: boolean = false;
  @Input() required: boolean = false;
  @Input() failed: boolean = false;


  @Input() iconKey: string[] = [];
  @Input() iconClass: string = '';

  @Output() enterEvent: EventEmitter<null> = new EventEmitter();
  @Output() iconClickEvent: EventEmitter<null> = new EventEmitter();
  @Output() clearClickEvent: EventEmitter<null> = new EventEmitter();

}

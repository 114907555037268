<div class="cybake-page-background w-full h-full p-2">

  <!--Navigation Card-->
  <div #productionHeaderDiv
       class="pb-2"
       [ngClass]="{ 'inline-flex w-full': selectedProductionPlan }">

    @if (selectedProductionPlan) {
    <div class="w-full inline-flex">
      <div class="w-full">
        <cybake-factory-card [pageSourceName]="pageSourceName"
                             [hideCardHeader]="true"
                             [customClass]="'production-path'"
                             [cardContentTemplate]="breadcrumbCardContentTemplate"
                             [fullHeight]="true"
                             [identifier]="'productionPlanBreadcrumb'">
        </cybake-factory-card>

        <ng-template #breadcrumbCardContentTemplate>
          <div class="w-full inline-flex">
            <div>
              <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                     [pageSourceName]="pageSourceName"
                                     [identifier]="navigateBackButton.Identifier"
                                     [class]="navigateBackButton.Class"
                                     [iconClass]="navigateBackButton.IconClass"
                                     [raisedButton]="false"
                                     (clickEvent)="backToProductionPlan()">
              </cybake-factory-button>
            </div>

            <div class="w-full">
              <div class="w-full"
                   [ngClass]="{
                    'vertically-center-left-align':
                      !selectedProductionPlanProduct
                  }">
                {{ selectedProductionPlan.Name }}
              </div>

              @if (selectedProductionPlanProduct) {
              <div class="w-full inline-flex" style="padding-left: 10px">
                <i class="fa-solid fa-arrow-turn-down-right mr-2"
                   style="padding-top: 4px"></i>
                <div class="w-full">
                  {{ selectedProductionPlanProduct.ProductName }}{{selectedProductionPlanProduct.ProductContainerDisplayName ? ' - ' + selectedProductionPlanProduct.ProductContainerDisplayName : ''}} ({{selectedProductionPlanProduct.TotalNumberOfLines}} {{ selectedProductionPlanProduct.HasSubLines ? (selectedProductionPlanProduct.TotalNumberOfLines === 1 ? ('Production.ProductionPlanCard.MixLabel' | translate) : ('Production.ProductionPlanCard.MixesLabel' | translate)) : (selectedProductionPlanProduct.TotalNumberOfLines === 1 ? ('Production.ProductionPlanCard.ItemLabel' | translate) : ('Production.ProductionPlanCard.ItemsLabel' | translate)) }})

                </div>
              </div>
              }

              @if (selectedProductionPlanProductMix) {
              <div class="w-full inline-flex ml-2"
                   style="padding-left: 20px">
                <i class="fa-solid fa-arrow-turn-down-right mr-2"
                   style="padding-top: 4px"></i>
                <!-- LS - Changed this, we don't have a mix name but we have a mix number, needs to come from translation -->
                <div class="w-full">
                  Mix {{ selectedProductionPlanProductMix.MixNumber }}
                </div>
              </div>
              }

              @if (selectedProductionPlanProductMethod) {
              <div class="w-full inline-flex ml-2"
                   style="padding-left: 40px">
                <i class="fa-solid fa-arrow-turn-down-right mr-2"
                   style="padding-top: 4px"></i>
                <div class="w-full">
                  {{ selectedProductionPlanProductMethod.Title }}
                </div>
              </div>
              }
            </div>
          </div>
        </ng-template>
      </div>

      <!--Show Complete Items Switch - Production Plan Products-->
      @if (selectedProductionPlan && !selectedProductionPlanProduct) {
      <div class="pl-2"
           [ngClass]="{ 'show-complete-switch-width': !isMobilePortrait }">
        <cybake-factory-toggle [(value)]="showCompleteProductionPlanProductsSwitchValue"
                               [bigIcon]="true"
                               [buttonOnIcon]="['fa-sharp', 'fa-regular fa-eye']"
                               [buttonOffIcon]="['fa-sharp', 'fa-regular fa-eye-slash']"
                               [buttonOnLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''"
                               [buttonOffLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''">
        </cybake-factory-toggle>
      </div>
      }

      <!--Show Complete Items Switch - Production Plan Item Mix-->
      @if (
      selectedProductionPlan &&
      selectedProductionPlanProduct &&
      selectedProductionPlanProduct.HasSubLines &&
      !selectedProductionPlanProductMix
      ) {
      <div class="pl-2"
           [ngClass]="{ 'show-complete-switch-width': !isMobilePortrait }">
        <cybake-factory-toggle [(value)]="showCompleteMixSwitchValue"
                               [bigIcon]="true"
                               [buttonOnIcon]="['fa-sharp', 'fa-regular fa-eye']"
                               [buttonOffIcon]="['fa-sharp', 'fa-regular fa-eye-slash']"
                               [buttonOnLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''"
                               [buttonOffLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''">
        </cybake-factory-toggle>
      </div>
      }

      <!--Show Complete Items Switch - Production Plan Recipe Line-->
      @if (selectedProductionPlan && selectedProductionPlanProduct && (selectedProductionPlanProduct.HasSubLines ? selectedProductionPlanProductMix : true) && !selectedProductionPlanProductRecipeLine) {
      <div class="pl-2"
           [ngClass]="{ 'show-complete-switch-width': !isMobilePortrait }">
        <cybake-factory-toggle [(value)]="showCompleteRecipeLineSwitchValue"
                               [bigIcon]="true"
                               [buttonOnIcon]="['fa-sharp', 'fa-regular fa-eye']"
                               [buttonOffIcon]="['fa-sharp', 'fa-regular fa-eye-slash']"
                               [buttonOnLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''"
                               [buttonOffLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''">
        </cybake-factory-toggle>
      </div>
      }

      <!--Complete button - Non Mix Item Or Method Product-->
      @if (selectedProductionPlanProduct && !selectedProductionPlanProductMix && !selectedProductionPlanProductRecipeLine && !selectedProductionPlanProduct.HasSubLines && !selectedProductionPlanProduct.Completed && !selectedProductionPlanProductMethod) {
      <div class="pl-2">
        <cybake-factory-button [iconKey]="completeButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [translationKey]="!isMobileLandscape && !isMobilePortrait ? 'Production.CompleteBtn' : undefined"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class"
                               [iconClass]="completeButton.IconClass"
                               [bigIcon]="true"
                               [disabled]="!productCanBeCompleted() || (loadingMixes || loadingMethods || loadingRecipeLines)"
                               (clickEvent)="completeAction()"
                               (disabledClickEvent)="incompleteProduct()">
        </cybake-factory-button>
      </div>
      }

      <!--Complete Button - Mix-->
      @if (selectedProductionPlanProduct && !selectedProductionPlanProductRecipeLine && selectedProductionPlanProductMix && !selectedProductionPlanProductMix.Completed) {
      <div class="pl-2">
        <cybake-factory-button [iconKey]="completeButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [translationKey]="!isMobileLandscape && !isMobilePortrait ? 'Production.CompleteBtn' : undefined"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class"
                               [iconClass]="completeButton.IconClass"
                               [bigIcon]="true"
                               [disabled]="!productCanBeCompleted() || (loadingMixes || loadingMethods || loadingRecipeLines)"
                               (clickEvent)="completeAction()"
                               (disabledClickEvent)="incompleteProduct()">
        </cybake-factory-button>
      </div>
      }

      <!--Complete button - Recipe Line-->
      @if (selectedProductionPlanProductRecipeLine && !selectedProductionPlanProductRecipeLine.Completed) {
      <div class="pl-2">
        <cybake-factory-button [iconKey]="completeButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [translationKey]="(!isMobileLandscape && !isMobilePortrait) ? 'Production.CompleteBtn' : undefined"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class"
                               [iconClass]="completeButton.IconClass"
                               [bigIcon]="true"
                               [disabled]="!recipeLineWithinTolerance(selectedProductionPlanProductRecipeLine) || (loadingMixes || loadingMethods || loadingRecipeLines)"
                               (clickEvent)="completeRecipeLine()"
                               (disabledClickEvent)="incompleteRecipeLine()">
        </cybake-factory-button>
      </div>
      }

      <!--Print Button-->
      @if (selectedProductionPlanProduct) {
      @if ((selectedProductionPlanProduct!.Completed || selectedProductionPlanProductMix?.Completed) && selectedProductionPlanProduct!.IsTraceable && !selectedProductionPlanProductRecipeLine) {
      <div class="pl-2">
        <cybake-factory-button [iconKey]="printLabelsButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [translationKey]="!isMobileLandscape && !isMobilePortrait ? 'Production.PrintLabelsBtn' : undefined"
                               [identifier]="printLabelsButton.Identifier"
                               [class]="printLabelsButton.Class"
                               [iconClass]="printLabelsButton.IconClass"
                               [type]="printLabelsButton.Type"
                               [loading]="printLabelsButton.Loading"
                               [bigIcon]="true"
                               (clickEvent)="printLabels()">
        </cybake-factory-button>
      </div>
      }

      }
    </div>
    }

    <!--Production Location Button-->
    @if (!selectedProductionPlan) {
    <div class="w-full"
         [ngClass]="{ 'inline-flex': checkCompleteProductionPlans() }">
      <div class="w-full">
        <cybake-factory-button [translationKey]="deviceProductionLocation ? productionLocationButton.TranslationKey : ''"
                               [prefix]="isMobileLandscape || isTabletLandscape ? productionLocationButton.Prefix : undefined"
                               [translationFromData]="true"
                               [iconKey]="productionLocationButton.IconKey"
                               [pageSourceName]="pageSourceName"
                               [identifier]="productionLocationButton.Identifier"
                               [class]="productionLocationButton.Class"
                               [fullWidth]="true"
                               [overrideCenterAlign]="true"
                               [loading]="productionLocationButton.Loading"
                               (clickEvent)="openProductionLocations()">
        </cybake-factory-button>
      </div>

      <!--Show Complete Items Switch - Production Plans-->
      @if (checkCompleteProductionPlans() && !selectedProductionPlan) {
      <div class="pl-2"
           [ngClass]="{ 'show-complete-switch-width': !isMobilePortrait }">
        <cybake-factory-toggle [(value)]="showCompleteProductionPlansSwitchValue"
                               [bigIcon]="true"
                               [buttonOnIcon]="['fa-sharp', 'fa-regular fa-eye']"
                               [buttonOffIcon]="['fa-sharp', 'fa-regular fa-eye-slash']"
                               [buttonOnLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''"
                               [buttonOffLabel]="!isMobilePortrait ? 'Production.ShowCompleteItemsSwitchLabel' : ''">
        </cybake-factory-toggle>
      </div>
      }
    </div>
    }
  </div>

  <div class="overflow-y-auto p-2"
       style="height: calc(100% - 100px); margin-left: -.5rem; margin-right: -.5rem; overflow-x: hidden;">

    <!--List Of Production Plans-->
    @if (!selectedProductionPlan && !loadingProductionPlans) {
    @for ((plan of productionPlans | filterByBoolean:(!showCompleteProductionPlansSwitchValue ? showCompleteProductionPlansSwitchValue : null):'Completed');track plan;let index = $index) {
    <div class="w-full slide-right-animation"
         [ngClass]="{ 'pt-3': index }">

      <cybake-factory-card [title]="plan.Name"
                           [subSubTitle]="plan.Note"
                           [routerLink]="true"
                           [pageSourceName]="pageSourceName"
                           [identifier]="'productionPlan' + plan.Id"
                           [cardContentTemplate]="!selectedProductionPlan ? productionPlanCardContentTemplate : null"
                           (longPressEvent)="viewPlanNote(plan)"
                           (clickEvent)="viewPlan(plan)">

        <ng-template #productionPlanCardContentTemplate>
          <div class="w-full inline-flex text-lg">
            <div class="w-10_12">
              <div class="vertically-center-left-align font-semibold"
                   cybake-text-style="body-xsmall">
                @if (plan.TotalNumberOfLines === plan.NumberOfLinesCompleted) {
                {{'Production.ProductionPlanCard.TotalItemsCompleteLabel' | translate}}
                }

                @if (plan.TotalNumberOfLines !== plan.NumberOfLinesCompleted) {
                {{ plan.TotalNumberOfLines }}

                @if (!isMobilePortrait) {
                {{'Production.ProductionPlanCard.TotalItemsLabel' | translate}}
                }

                @if (isMobilePortrait) {
                {{'Production.ProductionPlanCard.TotalItemsMobileLabel' | translate}}
                }
                }
              </div>
            </div>

            <div class="w-5_12">
              <div class="vertically-center-right-align pr-3 font-semibold"
                   cybake-text-style="body-xsmall">

                @if (!isMobilePortrait) {
                {{'Production.ProductionPlanCard.StartTimeLabel'| translate}}:
                }

                {{plan.StartTime}}
              </div>
            </div>

            <div class="">
              <div class="float-end vertically-center-right-align">
                <cybake-factory-progress-pie [total]="plan.TotalNumberOfLines"
                                             [completed]="plan.NumberOfLinesCompleted"
                                             [showCheckmark]="plan.TotalNumberOfLines === plan.NumberOfLinesCompleted">
                </cybake-factory-progress-pie>
              </div>
            </div>
          </div>
        </ng-template>
      </cybake-factory-card>
    </div>
    }
    }

    <!--List of Products in selected Production Plan-->
    @if (productionPlanProducts?.length && selectedProductionPlan && !selectedProductionPlanProduct && !loadingProductionProducts) {

    <div class="w-full overflow-y-auto slide-right-animation">

      @for ((product of productionPlanProducts | filterByBoolean:(!showCompleteProductionPlanProductsSwitchValue ? showCompleteProductionPlanProductsSwitchValue : null):'Completed');track product;let index = $index) {

      <div class="w-full" [ngClass]="{ 'pt-3': index }">
        <cybake-factory-card [title]="product.ProductName"
                             [subTitle]="product.ProductCode + (product.ProductContainerDisplayName ? ' - ' + product.ProductContainerDisplayName : '')"
                             [pageSourceName]="pageSourceName"
                             [image]="product.Image"
                             [loadingImage]="product.ImageLoading"
                             [pImageClass]="'vertically-center-left-align'"
                             [identifier]="'productionPlanProduct' + product.ProductId"
                             [tagValue]="product.IsTraceable ? ['Production.ProductionPlanProductCard.TraceableLabel'] : undefined"
                             [routerLink]="true"
                             [cardContentTemplate]="productionItemCardContentTemplate"
                             [overrideImageClick]="true"
                             (longPressEvent)="viewProductInfo(product)"
                             (imageClickEvent)="viewProductInfo(product)"
                             (clickEvent)="viewProductionPlanProduct(product)">
        </cybake-factory-card>

        <ng-template #productionItemCardContentTemplate>
          <div class="w-full inline-flex text-lg">
            <div class="w-full">
              <div class="vertically-center-left-align">
                <div class="inline-flex w-full">

                  <span class="pr-1 font-semibold">
                    {{'Production.ProductionPlanCard.PlannedLabel' | translate}}:
                  </span>

                  {{ product.TotalNumberOfLines }}

                  <span class="pl-1">
                    {{ product.HasSubLines ? (product.TotalNumberOfLines === 1 ? ('Production.ProductionPlanCard.MixLabel' | translate) : ('Production.ProductionPlanCard.MixesLabel' | translate)) : (product.TotalNumberOfLines === 1 ? ('Production.ProductionPlanCard.ItemLabel' | translate) : ('Production.ProductionPlanCard.ItemsLabel' | translate))}}
                  </span>

                </div>
              </div>
            </div>

            @if (!product.TotalRemaining) {
            <div class="w-full pr-3">
              <div class="vertically-center-right-align">
                <strong class="pr-1">
                  {{ 'Production.ProductionPlanCard.ProducedLabel' | translate }}:
                </strong>
                <span>{{ product.NumberOfLinesCompleted }}</span>
                <span class="pl-1">
                  {{ product.HasSubLines ? (product.NumberOfLinesCompleted === 1 ? ('Production.ProductionPlanCard.MixLabel' | translate) : ('Production.ProductionPlanCard.MixesLabel' | translate)) : (product.NumberOfLinesCompleted === 1 ? ('Production.ProductionPlanCard.ItemLabel' | translate) : ('Production.ProductionPlanCard.ItemsLabel' | translate)) }}
                </span>
              </div>
            </div>
            }

            <div class="">
              <div class="float-end vertically-center-right-align">
                <cybake-factory-progress-pie [total]="product.TotalNumberOfLines"
                                             [completed]="product.NumberOfLinesCompleted"
                                             [showCheckmark]="product.Completed">
                </cybake-factory-progress-pie>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      }
    </div>
    }

    <!--Mixes-->
    @if (selectedProductionPlanProduct && !selectedProductionPlanProductMix &&  selectedProductionPlanProduct.HasSubLines && !loadingMixes) {
    <div class="w-full overflow-y-auto slide-right-animation"
         [ngClass]="{'mt-3': !selectedProductionPlanProduct && !selectedProductionPlanProductMix}">

      @for ((mix of productionPlanProductMixes | filterByBoolean:(!showCompleteMixSwitchValue ? showCompleteMixSwitchValue : null):'Completed');track mix;let index = $index) {

      <div class="w-full" [ngClass]="{ 'pt-3': index }">
        <!-- LS - Changed this, we don't have a mix name but we have a mix number, needs to come from translation -->
        <cybake-factory-card [title]="'Mix ' + mix.MixNumber + ' - ' + mix.TotalWeight + ' ' + mix.Measure"
                             [pageSourceName]="pageSourceName"
                             [identifier]="'productionPlanItemRecipeLineMix' + mix.Id"
                             [cardTitleContentTemplate]="productionPlAnProductMixCardTitleContentTemplate"
                             (clickEvent)="viewProductionPlanProductRecipeMix(mix)">
        </cybake-factory-card>

        <ng-template #productionPlAnProductMixCardTitleContentTemplate>
          <div class="w-full vertically-center-right-align">
            <div class="float-end">
              <cybake-factory-progress-pie [total]="mix.TotalNumberOfLines"
                                           [completed]="mix.NumberOfLinesCompleted"
                                           [showCheckmark]="mix.TotalNumberOfLines === mix.NumberOfLinesCompleted">
              </cybake-factory-progress-pie>
            </div>
          </div>
        </ng-template>
      </div>
      }
    </div>
    }

    <!--Methods-->
    @if (productionPlanProductMethods?.length && !selectedProductionPlanProductRecipeLine) {
    @for ((method of productionPlanProductMethods | filter:selectedProductionPlanProductMethod?.Id:'Id');track method;let index = $index) {
    <div class="slide-right-animation"
         [ngClass]="{ 'pt-3': index, 'pb-3': loadingRecipeLines }">
      <cybake-factory-card [title]="method.Title"
                           [pageSourceName]="pageSourceName"
                           [identifier]="'methodStep' + method.Id"
                           [cardTitleContentTemplate]="selectedProductionPlanProductMethod ? null : methodTitleContentTemplate"
                           [cardContentTemplate]="selectedProductionPlanProductMethod ? methodContentTemplate : null"
                           [toggleableContent]="selectedProductionPlanProductMethod ? true : false"
                           [openToggleableContent]="selectedProductionPlanProductMethod ? true : false"
                           (clickEvent)="viewRecipeMethod(method)">
      </cybake-factory-card>

      <ng-template #methodTitleContentTemplate>
        <div class="w-full vertically-center-right-align">
          <div class="float-end">
            <cybake-factory-progress-pie [total]="method.TotalLines"
                                         [completed]="method.CompletedLines"
                                         [showCheckmark]="method.TotalLines === method.CompletedLines">
            </cybake-factory-progress-pie>
          </div>

          <div>
            <div class="vertically-center pl-3">
              <i class="fa-regular fa-chevron-right"
                 style="font-size: 1.5rem"></i>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #methodContentTemplate>
        <div class="inline-flex w-full">
          <div class="w-full text-lg">
            <div [innerHtml]="method.Method"></div>
          </div>

          <div class="method-image-div">
            <cybake-factory-image [pageSourceName]="pageSourceName"
                                  [identifier]="'methodImage' + method.Id"
                                  [verticalAlign]="'vertically-center-right-align'"
                                  [width]="150"
                                  [height]="150"
                                  [loading]="method.LoadingImage"
                                  [value]="method.Image">
            </cybake-factory-image>
          </div>
        </div>
      </ng-template>
    </div>
    }
    }

    <!--Recipe Lines-->
    @if (productionPlanProductRecipeLines?.length || selectedProductionPlanProductMethod && !loadingRecipeLines) {
    <div class="w-full overflow-y-auto slide-right-animation">
      @for ((recipeLine of productionPlanProductRecipeLines | filterByBoolean:(!showCompleteRecipeLineSwitchValue ? (!selectedProductionPlanProductRecipeLine ? showCompleteRecipeLineSwitchValue : null) : null):'Completed' | filter:selectedProductionPlanProductRecipeLine?.Id:'Id');track recipeLine;let index = $index) {
      <div class="w-full"
           [ngClass]="{ 'pt-3': (index || selectedProductionPlanProductMethod) && !selectedProductionPlanProductRecipeLine }">

        <cybake-factory-card [title]="recipeLine.ProductName"
                             [subTitle]="recipeLine.ProductCode"
                             [pageSourceName]="pageSourceName"
                             [image]="recipeLine.Image"
                             [loadingImage]="recipeLine.ImageLoading"
                             [identifier]="'productionPlanItemRecipeLine' + recipeLine.Id"
                             [pImageClass]="'vertically-center-left-align'"
                             [tagValue]="[recipeLine.IsTraceable || selectedProductionPlanProductRecipeLine?.IsTraceable ? 'Production.ProductionPlanProductCard.TraceableLabel': '']"
                             [routerLink]="!selectedProductionPlanProductRecipeLine ? true : false"
                             [cardContentTemplate]="recipeLineCardContentTemplate"
                             (clickEvent)="!selectedProductionPlanProductRecipeLine ? viewProductionPlanProductRecipe(recipeLine) : null">
        </cybake-factory-card>

        <ng-template #recipeLineCardContentTemplate>
          @if (!selectedProductionPlanProductRecipeLine) {
          <div class="inline-flex w-full vertically-center-left-align text-lg">
            <div class="inline-flex w-full">
              <strong class="pr-1">
                {{'Production.ProductionPlanRecipeLineCard.RemainingLabel' | translate}}:
              </strong>
              <span class="pr-1">{{ ( (recipeLine.TotalQuantity - recipeLine.QuantityUsed) >= 0 ) ? recipeLine.TotalQuantity - recipeLine.QuantityUsed : 0 }}</span>{{ recipeLine.Measure }}
            </div>

            <div>
              <div class="vertically-center-right-align pr-3">
                <strong>{{ recipeLine.QuantityUsed }}/{{ recipeLine.TotalQuantity }}</strong>
              </div>
            </div>

            <div class="float-end vertically-center-right-align">
              <cybake-factory-progress-pie [total]="recipeLine.TotalQuantity"
                                           [completed]="recipeLine.QuantityUsed"
                                           [showCheckmark]="recipeLine.Completed">
              </cybake-factory-progress-pie>
            </div>
          </div>
          }

          @if (selectedProductionPlanProductRecipeLine) {

          <div class="w-full pt-2">
            <cybake-factory-dial #recipleLineDial
                                 [pageSourceName]="pageSourceName"
                                 [identifier]="selectedProductionPlanProductRecipeLine.ProductName + '_mix'"
                                 [value]="selectedProductionPlanProductRecipeLine!.QuantityUsed + (usedLine ? usedLine!.Quantity : 0)"
                                 [max]="selectedProductionPlanProductRecipeLine.TotalQuantity"
                                 [min]="0"
                                 [size]="300"
                                 [loading]="loadingUsedLines"
                                 [valueColor]="recipeLineDialColour"
                                 [rangeColor]="'SlateGray'"
                                 [labelClass]="'text-3xl font-semibold'"
                                 [readOnly]="false"
                                 [label]="(selectedProductionPlanProductRecipeLine.Completed ? 'Production.ProductionPlanRecipeLineCard.TotalUsedLabel' : 'Production.ProductionPlanRecipeLineCard.StillRequiredLabel')"
                                 [subLabel]="(selectedProductionPlanProductRecipeLine.Tolerance ? ('+/-' + selectedProductionPlanProductRecipeLine.Tolerance + ' ' + selectedProductionPlanProductRecipeLine.Measure) : '')"
                                 [labelTranslationParams]="{requiredTotal: selectedProductionPlanProductRecipeLine.TotalQuantity, used: selectedProductionPlanProductRecipeLine.QuantityUsed, measure: selectedProductionPlanProductRecipeLine.Measure}"
                                 [dialCenterTemplate]="dialCenterTemplate"
                                 (dialTouchChange)="updateDialTouchEvent($event)"
                                 (dialCenterClick)="addRemainingRequiredAmount()">
            </cybake-factory-dial>

            <div class="hidden">
              <div #dialCenterTemplate>

                @if (!selectedProductionPlanProductRecipeLine!.Completed) {
                <div class="mb-1 w-full">
                  <div class="font-semibold text-4xl"
                       [ngClass]="{'c-warning': (usedLine ? usedLine!.Quantity : 0) > selectedProductionPlanProductRecipeLine!.StillRequired}">

                    @if ((usedLine ? usedLine!.Quantity : 0) <= selectedProductionPlanProductRecipeLine!.StillRequired) {
                    {{(selectedProductionPlanProductRecipeLine.StillRequired - (usedLine ? usedLine!.Quantity : 0)) | number: '1.0-3'}} {{selectedProductionPlanProductRecipeLine.Measure}}
                    }


                    @if ((usedLine ? usedLine!.Quantity : 0) > selectedProductionPlanProductRecipeLine!.StillRequired) {
                    {{((usedLine ? usedLine!.Quantity : 0) - selectedProductionPlanProductRecipeLine.StillRequired)  | number: '1.0-3'}} {{selectedProductionPlanProductRecipeLine.Measure}}
                    }

                  </div>
                </div>

                <div class="w-full text-lg">
                  {{ (((usedLine ? usedLine!.Quantity : 0) <= selectedProductionPlanProductRecipeLine!.StillRequired) ? 'Production.ProductionPlanRecipeLineCard.RemainingLabel' : 'Production.ProductionPlanRecipeLineCard.OverLabel') | translate}}
                </div>

                @if ((usedLine ? usedLine!.Quantity : 0) < selectedProductionPlanProductRecipeLine!.StillRequired) {
                <div class="w-full c-success" style="font-size: 1.4rem">
                  <i class="fa-solid fa-circle-down"></i>
                </div>
                }
                }

                @if (selectedProductionPlanProductRecipeLine!.Completed) {
                <div class="mb-1 w-full">
                  <div class="font-semibold text-6xl">
                    <i class="fa-duotone fa-solid fa-check c-success"></i>
                  </div>
                </div>

                <div class="w-full text-2xl">
                  {{'Production.ProductionPlanRecipeLineCard.CompletedLabel' | translate}}
                </div>
                }

              </div>
            </div>

          </div>

          @if (!selectedProductionPlanProductRecipeLine!.Completed) {
          <div class="w-full">
            <cybake-factory-input-number [(value)]="usedLine!.Quantity"
                                         [suffix]="isMobilePortrait || isMobileLandscape ? undefined : usedLine!.Measure"
                                         [min]="0"
                                         [step]="selectedProductionPlanProductRecipeLine!.StepAmount ? selectedProductionPlanProductRecipeLine!.StepAmount : 1"
                                         [showSteppers]="true"
                                         [decimal]="true"
                                         [disabled]="loadingUsedLines"
                                         (valueChange)="updateDial()">
            </cybake-factory-input-number>
          </div>

          @if (selectedProductionPlanProductRecipeLine.IsTraceable) {
          <div class="w-full pt-3"
               style="border-bottom: 1px solid var(--gray-300) !important">
            <cybake-factory-button [translationKey]="stockLotsButton.TranslationKey"
                                   [iconKey]="stockLotsButton.IconKey"
                                   [pageSourceName]="pageSourceName"
                                   [identifier]="stockLotsButton.Identifier"
                                   [class]="stockLotsButton.Class"
                                   [fullWidth]="true"
                                   [translationFromData]="stockLotsButton.TranslationFromData"
                                   [loading]="stockLotsButton.Loading"
                                   (clickEvent)="openStockLots()">
            </cybake-factory-button>
          </div>
          }

          <div class="w-full pt-3">
            <cybake-factory-button [translationKey]="(usedLine?.Id ? 'Production.ProductionPlanRecipeLineCard.ConfirmUpdateMeasureBtn' : confirmAddingMeasureButton.TranslationKey)"
                                   [iconKey]="confirmAddingMeasureButton.IconKey"
                                   [pageSourceName]="pageSourceName"
                                   [identifier]="confirmAddingMeasureButton.Identifier"
                                   [class]="confirmAddingMeasureButton.Class"
                                   [type]="usedLine?.Id ? defaultButtonType : confirmAddingMeasureButton.Type"
                                   [fullWidth]="true"
                                   [disabled]="(selectedProductionPlanProductRecipeLine.IsTraceable ? !usedLine!.StockLotId : false) || !usedLine!.Quantity || loadingUsedLines"
                                   [loading]="confirmAddingMeasureButton.Loading"
                                   (clickEvent)="confirmAddEditRecipeLineUsed()">
            </cybake-factory-button>
          </div>

          @if (usedLine?.Id) {
          <div class="w-full inline-flex pt-3">
            <div class="w-full mr-1">
              <cybake-factory-button [translationKey]="cancelAddingMeasureButton.TranslationKey"
                                     [iconKey]="cancelAddingMeasureButton.IconKey"
                                     [pageSourceName]="pageSourceName"
                                     [identifier]="cancelAddingMeasureButton.Identifier"
                                     [class]="cancelAddingMeasureButton.Class"
                                     [fullWidth]="true"
                                     [loading]="cancelAddingMeasureButton.Loading"
                                     (clickEvent)="cancelAddingEditingMeasure()">
              </cybake-factory-button>
            </div>

            <div class="w-full ml-1">
              <cybake-factory-button [translationKey]="removeAddingMeasureButton.TranslationKey"
                                     [iconKey]="removeAddingMeasureButton.IconKey"
                                     [pageSourceName]="pageSourceName"
                                     [identifier]="removeAddingMeasureButton.Identifier"
                                     [class]="removeAddingMeasureButton.Class"
                                     [fullWidth]="true"
                                     [loading]="removeAddingMeasureButton.Loading"
                                     (clickEvent)="removeMeasure()">
              </cybake-factory-button>
            </div>
          </div>
          }
          }

          }
        </ng-template>
      </div>
      }

      <!--Recorded Measures Card-->
      @if (selectedProductionPlanProductRecipeLine && selectedProductionPlanProductRecipeLineUsedLines.length) {

      <div class="pt-2">

        <cybake-factory-card [title]="'Production.ProductionPlanRecipeLineCard.RecordedMeasuesLabel'"
                             [pageSourceName]="pageSourceName"
                             [identifier]="'productionPlanItemRecipeLineRecordedMeasures'"
                             [cardContentTemplate]="recordedMeasuresContentTemplate"
                             [toggleableContent]="true">
        </cybake-factory-card>

        <ng-template #recordedMeasuresContentTemplate>
          <div class="production-recordedMeasures-list">

            @for ((measure of selectedProductionPlanProductRecipeLineUsedLines);track measure;let index = $index) {

            <div class="inline-flex w-full production-recorded-measure pr-2 pl-2 text-lg"
                 style="height: 50px"
                 (click)="editRecipeLineUsed(measure)">

              @if (usedLine!.Id === measure.Id) {
              <div class="pr-2">
                <i class="fa-duotone fa-solid fa-pencil c-warning vertically-center"></i>
              </div>
              }

              @if (measure.Id && measure.SystemLotNumber) {
              <div class="inline-flex w-full">

                <div class="vertically-center-left-align">
                  {{ measure.SystemLotNumber }}
                </div>
              </div>
              }

              <div [ngClass]="{ 'w-full': !measure.Id || !measure.SystemLotNumber  }">
                <div class="vertically-center-left-align">
                  <span class="pr-2">{{ measure.Quantity }}</span>{{ selectedProductionPlanProductRecipeLine.Measure }}
                </div>
              </div>

              @if (!selectedProductionPlanProductRecipeLine.Completed) {
              <div class="pl-3">
                <i class="fa-icon fa-thin fa-angle-right text-gray-300 vertically-center text-4xl"></i>
              </div>
              }

            </div>

            }

            @if (!selectedProductionPlanProductRecipeLineUsedLines.length) {
            <div class="text-center w-full p-3">
              {{'Production.ProductionPlanRecipeLineCard.NoMeasuresRecordedLabel' | translate}}
            </div>
            }

          </div>
        </ng-template>
      </div>
      }

      <!--// Padding for barcode scan button-->
      @if (selectedProductionPlanProductRecipeLine && !usedLine && selectedProductionPlanProductRecipeLineUsedLines.length && selectedProductionPlanProductRecipeLine.IsTraceable ) {
      <div style="height: 70px"></div>
      }
    </div>
    }

    <!--Loading Card - Plans, Product, Mixes, Methods, Normal Recipe Lines-->
    @if (loadingProductionPlans || loadingProductionProducts || loadingMixes  || loadingMethods || loadingRecipeLines) {
    <cybake-factory-card [pageSourceName]="pageSourceName"
                         [identifier]="'productionPlanLoadingCard'"
                         [loadingHeight]="loadingCardHeight"
                         [loading]="true">
    </cybake-factory-card>
    }

  </div>
</div>

<!--Floating Barcode Scan Button-->
@if (
  selectedProductionPlanProductRecipeLine &&
  selectedProductionPlanProductRecipeLine.IsTraceable
) {
<cybake-factory-button [translationKey]="barcodeScannerButton.TranslationKey"
                       [iconKey]="barcodeScannerButton.IconKey"
                       [pageSourceName]="barcodeScannerButton.PageSourceName"
                       [identifier]="barcodeScannerButton.Identifier"
                       [class]="barcodeScannerButton.Class"
                       [float]="barcodeScannerButton.Float"
                       [rounded]="true"
                       (clickEvent)="openBarcodeScanner()">
</cybake-factory-button>
}
<!--Scan Barcode Dialog-->
<cybake-factory-dialog [title]="'BarcodeScanner.Header'"
                       [(visible)]="displayBarcodeScanner"
                       [identifier]="'barcodeScanProduction'"
                       [closable]="true"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate1">
  <ng-template #dialogContentTemplate1>
    <cybake-factory-barcode-scan></cybake-factory-barcode-scan>
  </ng-template>
</cybake-factory-dialog>

<!--Production Locations Options-->
<cybake-factory-dialog [title]="'Production.ProductionShiftSelecttion.Header'"
                       [type]="'question'"
                       [(visible)]="displayProductionLocations"
                       [identifier]="'productionLocationsSelection'"
                       [closable]="closableProductionLocationSelection"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate2">
  <ng-template #dialogContentTemplate2>
    <cybake-factory-production-shift-select [productionLocation]="deviceProductionLocation"
                                            (comfirmLocation)="confirmProductionLocation($event)"></cybake-factory-production-shift-select>
  </ng-template>
</cybake-factory-dialog>

<!--Stock Lots Options-->
<cybake-factory-dialog [title]="'Production.StockLotSelection.Header'"
                       [(visible)]="displayStockLots"
                       [identifier]="'stockLotSelection'"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate3">
  <ng-template #dialogContentTemplate3>
    <cybake-factory-stock-lot-selection [stockLotId]="usedLine?.StockLotId"
                                        [stockLots]="stockLots"
                                        (comfirmLocation)="confirmStockLot($event)">
    </cybake-factory-stock-lot-selection>
  </ng-template>
</cybake-factory-dialog>

<!--Complete Item-->
<cybake-factory-dialog [title]="completeItemDialog.Title"
                       [type]="completeItemDialog.Type"
                       [(visible)]="displayCompleteItem"
                       [identifier]="completeItemDialog.Identifier"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate4">
  <ng-template #dialogContentTemplate4>
    <cybake-factory-complete-production-item [product]="selectedProductionPlanProduct!"
                                             [traceableItem]="selectedProductionPlanProduct!.IsTraceable ? true : false"
                                             [mixItem]="selectedProductionPlanProductMix ? true : false"
                                             [value]="selectedProductionPlanProduct!.TotalNumberOfLines"
                                             (comfirmComplete)="confirmCompleteAction($event)">
    </cybake-factory-complete-production-item>
  </ng-template>
</cybake-factory-dialog>

<!--Production Plan Note-->
<cybake-factory-dialog [titlePrefix]="'Production.ProductionPlanNote.Header'"
                       [type]="'info'"
                       [(visible)]="displayPlanNote"
                       [identifier]="'productionPlanNote'"
                       [width]="35"
                       [dialogContentTemplate]="dialogContentTemplate5">
  <ng-template #dialogContentTemplate5>

    <cybake-factory-notes [note]="productionPlanForNoteDialog!.Note!"
                          [contentHeader]="productionPlanNoteHeader"
                          (closeEvent)="displayPlanNote = false">
    </cybake-factory-notes>

    <ng-template #productionPlanNoteHeader>
      <div class="w-full inline-flex">
        <div class="pr-1">
          <strong>
            {{'Production.ProductionPlanNote.PlanNameLabel' | translate}}:
          </strong>
        </div>
        <div>{{ productionPlanForNoteDialog!.Name }}</div>
      </div>

      <div class="w-full inline-flex">
        <div class="pr-1">
          <strong>
            {{'Production.ProductionPlanNote.ShiftLabel' | translate}}:
          </strong>
        </div>
        <div>
          {{productionShift!.Name}}
        </div>
      </div>

      <div class="w-full inline-flex">
        <div class="pr-1">
          <strong>
            {{'Production.ProductionPlanNote.PlannedStartLabel' | translate}}:
          </strong>
        </div>
        <div>
          {{productionPlanForNoteDialog!.StartTime}}
        </div>
      </div>

    </ng-template>
  </ng-template>
</cybake-factory-dialog>

<!--Production Plan Product Info-->
<cybake-factory-dialog [(visible)]="displayProductInfo"
                       [identifier]="'productionPlanProductInfo'"
                       [width]="35"
                       [dialogContentTemplate]="productionPlanProductInfo">
  <ng-template #productionPlanProductInfo>

    <cybake-factory-product-info [name]="productionPlanProductInfoDialog!.ProductName!"
                                 [code]="productionPlanProductInfoDialog!.ProductCode!"
                                 [isTraceable]="productionPlanProductInfoDialog!.IsTraceable!"
                                 [image]="productionPlanProductInfoDialog?.Image"
                                 (closeEvent)="displayProductInfo = false">
    </cybake-factory-product-info>

  </ng-template>

</cybake-factory-dialog>

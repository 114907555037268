import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NavbarTitle } from '../models/navbar/navbar-title.model';
import { OutputFunction } from '../models/common/output-function.model';
import { ToastMessage } from '../models/common/toast-message.model';
import { DialogOptionsModel } from '../models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // Navbar Title
  private updateNavbarTitleSubject = new Subject<NavbarTitle>();
  updateNavbarTitleState = this.updateNavbarTitleSubject.asObservable();

  updateNavbarTitle(updateNavbarTitle: NavbarTitle) {
    this.updateNavbarTitleSubject.next(updateNavbarTitle);
  }

  private clearNavbarTitleSubject = new Subject();
  clearNavbarTitleState = this.clearNavbarTitleSubject.asObservable();

  clearNavbarTitle() {
    this.clearNavbarTitleSubject.next(null);
  }

  private outputFromNavbarTitleSubject = new Subject<OutputFunction>();
  outputFromNavbarTitleState = this.outputFromNavbarTitleSubject.asObservable();

  outputFromNavbarTitle(outputFromNavbarTitle: OutputFunction) {
    this.outputFromNavbarTitleSubject.next(outputFromNavbarTitle);
  }

  // Toast Message
  private openToastSubject = new Subject<ToastMessage>();
  openToastState = this.openToastSubject.asObservable();

  openToast(toast: ToastMessage) {
    this.openToastSubject.next(toast);
  }

  private toastOutputFunctionSubject = new Subject<OutputFunction>();
  toastOutputFunctionState = this.toastOutputFunctionSubject.asObservable();

  toastOutputFunction(outputFunction: OutputFunction) {
    this.toastOutputFunctionSubject.next(outputFunction);
  }

  // Open Settings Dialog
  private openSettingsDialogSubject = new Subject<boolean>();
  openSettingsDialogState = this.openSettingsDialogSubject.asObservable();

  openSettingsDialog(show: boolean) {
    this.openSettingsDialogSubject.next(show);
  }

  // Open App Info Dialog
  private openAppInfoDialogSubject = new Subject<boolean>();
  openAppInfoDialogState = this.openAppInfoDialogSubject.asObservable();

  openAppInfoDialog(show: boolean) {
    this.openAppInfoDialogSubject.next(show);
  }

  // ------------------------- App Update Available -------------------------

  // Show Update Icon in navbar
  private showUpdateIconNavbarSubject = new Subject<boolean>();
  showUpdateIconNavbarState = this.showUpdateIconNavbarSubject.asObservable();

  showUpdateIconNavbar(showIcon: boolean) {
    this.showUpdateIconNavbarSubject.next(showIcon);
  }

  // Open Update Dialog
  private openUpdateDialogSubject = new Subject<boolean>();
  openUpdateDialogState = this.openUpdateDialogSubject.asObservable();

  openUpdateDialog(show: boolean) {
    this.openUpdateDialogSubject.next(show);
  }  

  // Open Add to Home Screen Dialog
  private showAddToHomeScreenSubject = new Subject<number>();
  showAddToHomeScreenState = this.showAddToHomeScreenSubject.asObservable();

  showAddToHomeScreen(device: number) {
    this.showAddToHomeScreenSubject.next(device);
  }

  // ------------------------- Confirmation Prompts -------------------------

  // Confirmation Prompt - Open
  private openConfirmationPromptSubject = new Subject<DialogOptionsModel>();
  openConfirmationPromptState =
    this.openConfirmationPromptSubject.asObservable();

  openConfirmationPrompt(toast: DialogOptionsModel) {
    this.openConfirmationPromptSubject.next(toast);
  }

  // Confirmation Prompt - Close
  private closeConfirmationPromptSubject = new Subject<boolean>();
  closeConfirmationPromptState =
    this.closeConfirmationPromptSubject.asObservable();

  closeConfirmationPrompt() {
    this.closeConfirmationPromptSubject.next(true);
  }

  // Confirmation Prompt - Output Function
  private confirmationPromptOutputFunctionSubject =
    new Subject<OutputFunction>();
  confirmationPromptOutputFunctionState =
    this.confirmationPromptOutputFunctionSubject.asObservable();

  confirmationPromptOutputFunction(outputFunction: OutputFunction) {
    this.confirmationPromptOutputFunctionSubject.next(outputFunction);
  }

  // ------------------------- Common Functions -------------------------

  // RM - Deepclone will never know the class thus has to be any
  // eslint-disable-next-line
  deepClone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  countDecimals(value: number): string | number {

    if (Math.floor(value) === value) return 0;

    const str = value.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
      return str.split("-")[1] || 0;
    } else if (str.indexOf(".") !== -1) {
      return str.split(".")[1].length || 0;
    }
    return str.split("-")[1] || 0;
  }
}

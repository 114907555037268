import { Injectable } from '@angular/core';
import { Howl } from 'howler';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  // Sounds
  alert = new Howl({
    src: ['assets/sounds/alert.wav'],
  });

  playAlert() {
    this.alert.play();
  }

  playError() {}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Lot } from '../../models/stock-transfers/product/lot.model';
import { Container } from '../../models/stock-transfers/product/container.model';
import { Trolley } from '../../models/stock-transfers/product/trolley.model';
import { faker } from '@faker-js/faker';
import { Product } from '../../models/stock-transfers/product/product.model';
import { StockLocation } from '../../models/stock-transfers/stocks/stock-location.model';

export interface StockTransferOptions {
  isMultipleProduct: boolean;
  selectedProduct?: Product;
  selectedLocation?: StockLocation;
  selectedDestination?: StockLocation;
}

@Injectable({
  providedIn: 'root',
})
export class LotServiceMock {
  imageUrl = faker.image.dataUri({ width: 400, height: 400, color: 'silver' });
  private basketItemsSource = new BehaviorSubject<Trolley[] | null>(null);
  basketItems$ = this.basketItemsSource.asObservable();

  private stockTransferOptionsSource = new BehaviorSubject<StockTransferOptions | null>(null);
  stockTransferOptions$ = this.stockTransferOptionsSource.asObservable();

  updateStockTransferOptions(data: StockTransferOptions) {
    this.stockTransferOptionsSource.next(data);
  }

  updateBasketItemsSource(data: Trolley[]) {
    this.basketItemsSource.next(data);
  }

  getLots(): Observable<Lot[]> {
    const lots: Lot[] = [
      {
        Id: 1,
        SystemLotNumber: '12334334343',
        ExpiryDate: '28/09/2024',
        ContainerId: undefined,
        ContainerName: 'Single'
      },
      {
        Id: 2,
        SystemLotNumber: '13300288332',
        ExpiryDate: '30/08/2024',
        ContainerId: undefined,
        ContainerName: 'Single'
      },
      {
        Id: 3,
        SystemLotNumber: '12334787992',
        ExpiryDate: '1/09/2024',
        ContainerId: undefined,
        ContainerName: 'Single'
      },
    ];
    return of(lots);
  }

  getContainers(): Observable<Container[]> {
    const containers: Container[] = [
      {
        ProductToContainerId: 1,
        ContainerDisplayName: 'Sack (32 singles)',
        IsDefault: false,
      },
      {
        ProductToContainerId: 2,
        ContainerDisplayName: 'Box (32 singles)',
        IsDefault: true,
      },
      {
        ProductToContainerId: 3,
        ContainerDisplayName: 'Palet (32 singles)',
        IsDefault: false,
      },
    ];
    return of(containers);
  }

  getTrolleys(): Observable<Trolley[]> {
    const trolleys: Trolley[] = [
      {
        Id: 1,
        Name: 'White Flour',
        Code: '1001',
        Weight: 16.5,
        ImageUrl: this.imageUrl,
        Measure: 'kgs',
        LocationName: 'Main Stores - Room A - Shelf 1',
        LotNumber: '1231',
        Expiry: '20/07/2024',
        Lots: [
          {
            Id: 1,
            SystemLotNumber: '193812345',
            ExpiryDate: '2024-09-01',
            ContainerId: 1,
            ContainerName: 'Sack (12 singles)',
            Quantity: 5,
          },
          {
            Id: 2,
            SystemLotNumber: '124423533',
            ExpiryDate: '2024-09-13',
            ContainerId: 1,
            ContainerName: 'Box (12 singles)',
            Quantity: 5,
          },
          {
            Id: 3,
            SystemLotNumber: '122316445',
            ExpiryDate: '2024-10-19',
            ContainerId: 1,
            ContainerName: 'Tub (12 singles)',
            Quantity: 5,
          },
        ],
      },
      {
        Id: 2,
        Name: 'Green Flour',
        Code: '1002',
        Weight: 16.5,
        ImageUrl: this.imageUrl,
        Measure: 'kgs',
        LocationName: 'Main Stores - Room A - Shelf 2',
        LotNumber: '2345',
        Expiry: '20/09/2024',
        Lots: [
          {
            Id: 4,
            SystemLotNumber: '2345',
            ExpiryDate: '2024-11-01',
            ContainerId: 1,
            ContainerName: 'Sack (12 singles)',
            Quantity: 5,
          },
          {
            Id: 5,
            SystemLotNumber: '2345',
            ExpiryDate: '2024-12-16',
            ContainerId: 1,
            ContainerName: 'Sack (12 singles)',
            Quantity: 5,
          },
        ],
      },
    ];
    return of(trolleys);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CyBakeButtonComponent } from '../../components/button/button.component';

@Component({
  selector: 'cybake-factory-add-app-to-home-screen',
  standalone: true,
  imports: [
    TranslateModule,
    CyBakeButtonComponent
  ],
  templateUrl: './add-app-to-home-screen.component.html',
  styleUrl: './add-app-to-home-screen.component.scss'
})
export class AddAppToHomeScreenComponent {

  // Inputs/Outputs
  @Input() device!: number; // Android = 1, IOS = 2

  @Output() confirmEvent: EventEmitter<boolean> = new EventEmitter();

  // Variables
  pageSourceName: string = 'addToHomeScreen';


  addAppToHomeScreen() {
    this.confirmEvent.emit(true);
  }
}

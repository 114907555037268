export class ProductionShift {
  Id!: number;
  Name!: string;
  StartTime!: Date;
  EndTime!: Date;

  constructor(values: ProductionShift) {
    Object.assign(this, values);
  }
}

export enum ButtonTypeEnum {
  default = 'default',
  add = 'add',
  edit = 'edit',
  delete = 'delete',
  search = 'search',
  adjustment = 'adjustment',
  external = 'external',
  list = 'list',
  address = 'address',
  output = 'output',
  save = 'save',
  undo = 'undo',
}

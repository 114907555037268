import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { StockLocationComponent } from '../stock-location/stock-location.component';
import { NgTemplateOutlet } from '@angular/common';
import { StockLocationType } from '../../models/stock-transfers/stocks/stock-location-types.model';

@Component({
  selector: 'cybake-factory-accordion',
  standalone: true,
  imports: [AccordionModule, StockLocationComponent, NgTemplateOutlet],
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss',
})
export class AccordionComponent {
  @Input() accordionTabs: StockLocationType[] = [];
  @Input() pageSourceName: string = '';
  @Input() itemsKey: string = '';

  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<unknown>;

  returnTabItems(accordionTab: StockLocationType, key:string) {
    const result = accordionTab[key];
    return Array.isArray(result) ? result : [];
  }
}

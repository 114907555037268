import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { Product } from '../../models/stock-transfers/product/product.model';
import { faker } from '@faker-js/faker';

@Injectable({
  providedIn: 'root',
})
export class ProductServiceMock {
  imageUrl = faker.image.dataUri({ width: 400, height: 400, color: 'silver' });

  getProducts(): Observable<Product[]> {
    const products: Product[] = [
      {
        Id: 3,
        Name: 'Bakers White Flour',
        ImageUrl: this.imageUrl,
        Code: 'I00101 ',
        Weight: 65,
        Measure: 'kgs',
        IsTraceable: true
      },
      {
        Id: 4,
        Name: 'Yeast (Block)',
        ImageUrl: this.imageUrl,
        Weight: 10,
        Code: 'I1000431',
        Measure: 'kgs',
        IsTraceable: true
      },
      {
        Id: 5,
        Name: 'Salt',
        ImageUrl: this.imageUrl,
        Code: 'I100230',
        Weight: 23,
        Measure: 'kgs',
        IsTraceable: true
      },
      {
        Id: 15,
        Name: 'Caster Sugar',
        ImageUrl: this.imageUrl,
        Code: 'I1000100',
        Weight: 8.5,
        Measure: 'kgs',
        IsTraceable: true
      },
      {
        Id: 16,
        Name: 'Cookie Bags (5pk)',
        ImageUrl: this.imageUrl,
        Code: 'P102989838 ',
        Weight: 7,
        Measure: 'kgs',
        IsTraceable: false
      },
      {
        Id: 17,
        Name: 'Baguette Film',
        ImageUrl: this.imageUrl,
        Code: 'P1200010',
        Weight: 3.5,
        Measure: 'kgs',
        IsTraceable: false
      },
    ];

    return of(products);
  }

  getProductImage(): Observable<Blob> {
    return of().pipe(delay(1500));
  }

}

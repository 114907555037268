import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { CounterComponent } from '../counter/counter.component';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { LotCardComponent } from '../lot-card/lot-card.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LotServiceMock } from '../../services/mock-services/lot.service.mock';
import { LotService } from '../../services/lot.service';
import { Lot } from '../../models/stock-transfers/product/lot.model';
import { Trolley } from '../../models/stock-transfers/product/trolley.model';
import { Container } from '../../models/stock-transfers/product/container.model';
import { ProductCardComponent } from '../product-card/product-card.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cybake-factory-lots-list',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    CounterComponent,
    CyBakeButtonComponent,
    LotCardComponent,
    ProductCardComponent,
  ],
  templateUrl: './lots-list.component.html',
  styleUrl: './lots-list.component.scss',
})
export class LotsListComponent {
  translateService: TranslateService = inject(TranslateService);
  lotService: LotService | LotServiceMock = environment.mock
    ? inject(LotServiceMock)
    : inject(LotService);
  @Input() lots: Lot[] = [];
  @Input() containers: Container[] = [];
  @Output() transferEvent: EventEmitter<Lot> = new EventEmitter<Lot>();
  @Input() basketItems: Trolley[] = [];
  @Input() loading: boolean = false

  // Variables
  pageSourceName: string = 'lots-list';

  handleValueChange(item: Lot) {
    this.transferEvent.emit(item);
  }

  returnItem(_lot: Lot): Trolley | undefined {
    //console.log(1234)
    if (!this.basketItems || (this.basketItems && !this.basketItems.length)) {
      return undefined;
    }

    //console.log(this.basketItems);

    const indexedProduct = this.basketItems.find((product) =>
      product.Lots?.some((lot) => lot.SystemLotNumber === _lot.SystemLotNumber),
    );
    //console.log('indexedProduct> ',indexedProduct);

    return indexedProduct;
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { UnCamelCasePipe } from '../../pipes/un-camel-case.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'cybake-factory-input-email',
  standalone: true,
  imports: [
    InputTextModule,
    SkeletonModule,
    CommonModule,
    FormsModule,
    UnCamelCasePipe,
    TranslateModule,
    TooltipModule,
  ],
  templateUrl: './input-email.component.html',
  styleUrl: './input-email.component.scss',
})
export class CybakeInputEmailComponent {
  @Input() pageSourceName: string = '';
  @Input() identifier: string = '';
  @Input() value: string | null = null;
  @Output() valueChange: EventEmitter<string | null> = new EventEmitter();

  @Input() label: string = '';

  @Input() class: string = '';
  @Input() inputClass: string = '';

  @Input() translationKey: string = '';
  @Input() translationParams: object = {};

  @Input() iconTooltipTranslation: string = '';
  @Input() iconTooltipTranslationParams: object = {};
  @Input() iconTooltipPosition: string = 'top';

  @Input() placeholder: boolean = false;
  @Input() loading: boolean = false;
  @Input() showClear: boolean = true;
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() hidden: boolean = false;
  @Input() clearable: boolean = false;
  @Input() hideCharcterLimit: boolean = false;
  @Input() required: boolean = false;
  @Input() failed: boolean = false;

  @Input() maximumCharacters?: number;

  @Input() tooltip: string = '';
  @Input() tooltipPosition: string = 'top';
  @Input() tooltipFromData: boolean = false;

  @Input() iconKey: string[] = [];
  @Input() iconClass: string = '';

  @Output() enterEvent: EventEmitter<null> = new EventEmitter();
  @Output() iconClickEvent: EventEmitter<null> = new EventEmitter();
  @Output() clearClickEvent: EventEmitter<null> = new EventEmitter();
}

<div class="set-size charts-container">

  <div class="pie-wrapper pie-wrapper--solid progress-{{ !overTotal ? completePercentage : '101 progess-pie-over-total'}}"
       [style]="{'height': height + 'em', 'width': height + 'em'}"
       [ngClass]="{'border-empty': completePercentage === 0, 'border-in-progress': completePercentage > 1 && completePercentage < 100, 'border-complete': completePercentage === 100}">

    @if (showCheckmark && !overTotal) {
    <div class="absolute vertically-center w-full top-0 text-white">
      <i class="fa-icon fa-regular fa-check" style=" font-size: 1.2em;"></i>
    </div>
    }

    @if (overTotal || showWarning) {
    <div class="absolute vertically-center w-full top-0 text-white">
      <i class="fa-solid fa-triangle-exclamation" style=" font-size: 1em;"></i>
    </div>
    }

  </div>
</div>

import { CyBakeTemplate } from '../cybake-template.model';

export class CyBakeListbox extends CyBakeTemplate {
  Options!: unknown[];
  Value: unknown;
  Height?: number = 100;
  Placeholder?: boolean = false;
  Multiple?: boolean = false;
  Checkbox?: boolean = false;
  RadioButtons?: boolean = false;
  OptionLabel: string = 'Name';
  OptionLabel2?: string;
  OptionValue?: string;
  OptionDisabled?: string;
  Filter?: boolean = false;
  FilterBy?: string = 'Name';
  Class?: string = '';
  Group?: boolean = false;
  Required?: boolean = false;
  OptionGroupLabel?: string = '';
  OptionGroupChildren?: string = '';

  constructor(values: CyBakeListbox) {
    super(values);
    Object.assign(this, values);
  }
}

<div #imageDiv
     id="{{pageSourceName}}_{{identifier}}_image_div"
     class="cybake-image-div {{verticalAlign ? verticalAlign : 'vertically-center'}}"
     [ngClass]="{'cybake-image-loading-div': loading}"
     (click)="imageClick()">

  @if (value && !loading) {
  <p-image #image
           id="{{pageSourceName}}_{{identifier}}_image"
           class="{{pImageClass}}"
           [src]="value"
           alt="{{altText ? (altText | translate) : '' }}"
           width="{{width}}"
           height="{{height}}"
           [preview]="(overrideClick ? false : preview)">
  </p-image>
  }

  @if (loading) {
  <div class="component-loading-background cybake-inage-loading"
       [style.width.px]="width"
       [style.height.px]="height">
    <div class="vertically-center">
      <i class="fa-regular fa-spinner-third fa-spin text-xl"></i>
    </div>
  </div>
  }
</div>

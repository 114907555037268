import { OutputFunction } from '../../../common/output-function.model';
import { CyBakeButton } from '../../button/button.model';
import { CyBakeTemplate } from '../../cybake-template.model';
import { CyBakeConfirmationPrompTypeEnum } from './confirmation-prompt-type.enum';

export class DialogOptionsModel extends CyBakeTemplate {
  Type!: CyBakeConfirmationPrompTypeEnum;
  Title!: string;
  Width?: number;
  TitleTranslationParams?: object;
  TitlePrefix?: string;
  TitleFromData?: boolean = false;
  Message?: string;
  MessageTranslationParams?: object;
  MessageHtml?: boolean = true;
  Validation?: boolean;
  ConfirmButton?: CyBakeButton;
  ConfirmButtonMethod?: OutputFunction;
  DeclineButton?: CyBakeButton;
  DeclineButtonMethod?: OutputFunction;
  Closable?: boolean = true;
  AutoClose?: boolean = false;
  AutoCloseDuration?: number = 2000;

  constructor(values: DialogOptionsModel) {
    super(values);
    Object.assign(this, values);
  }
}

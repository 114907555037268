<!--Temp style for demo only!!!!-->

<div class="w-full" style=" margin-top: -20px;">

  @if (product) {
  @if (product.Image) {
  <div>
    <cybake-factory-image [pageSourceName]="pageSourceName"
                          [identifier]="'productInfo'"
                          [verticalAlign]="'vertically-center'"
                          [width]="250"
                          [height]="250"
                          [value]="product.Image">
    </cybake-factory-image>
  </div>
  }

  <div class="text-center text-3xl">
    {{product.ProductName}}
  </div>

  <div class="text-center pt-1 text-xl">
    <div class="text-center">
      {{product.ProductCode}}
    </div>
  </div>

  @if (product.IsTraceable) {
  <div class="mt-2" style=" width: fit-content; margin: auto;">

    <div class="">
      <cybake-factory-tag [pageSourceName]="pageSourceName"
                          [identifier]="'productInfoTraceable'"
                          [value]="'Traceable'">
      </cybake-factory-tag>
    </div>    
  </div>
  }
  }

  <div class="col-12 p-0 pt-4">
    <cybake-factory-button [pageSourceName]="pageSourceName"
                           [identifier]="'closeProductInfo'"
                           [translationKey]="'Production.ProductionPlanProductInfo.CloseButton'"
                           [fullWidth]="true"
                           (clickEvent)="this.closeEvent.emit(true)">
    </cybake-factory-button>
  </div>

</div>

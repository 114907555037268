import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CyBakeButtonComponent } from '../../components/button/button.component';

@Component({
  selector: 'cybake-factory-notes',
  standalone: true,
  imports: [
    CommonModule,
    CyBakeButtonComponent
  ],
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.scss'
})
export class NotesComponent{

  // Inputs/Outputs

  @Input() note!: string;
  @Input() editable: boolean = false;

  @Input() contentHeader!: TemplateRef<unknown> | null;

  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

  // Services

  // Variables
  pageSourceName: string = 'noteDialog'

}

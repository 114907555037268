import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { OutputFunction } from '../../models/common/output-function.model';
import { CyBakeButtonComponent } from '../button/button.component';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import Swal from 'sweetalert2'
import { CyBakeConfirmationPrompTypeEnum } from '../../models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';
import { CyBakeSlideToConfirmComponent } from '../slide-to-confirm/slide-to-confirm.component';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'cybake-factory-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    CyBakeButtonComponent,
    TranslateModule,
    CyBakeSlideToConfirmComponent
  ],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent implements OnInit {

  // Inputs/Outputs

  // Inputs - Visibility
  @Input() visible!: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Inputs - CyBake Template Inputs
  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  // Inputs - Default Dialog Options
  @Input() type?: CyBakeConfirmationPrompTypeEnum | string;
  @Input() title!: string;
  @Input() titleTranslationParams?: object;
  @Input() titlePrefix?: string;
  @Input() titleFromData?: boolean = false;
  @Input() message?: string;
  @Input() messageTranslationParams?: object;
  @Input() validation?: boolean;

  @Input() confirmButton?: CyBakeButton;
  @Input() confirmButtonMethod?: OutputFunction;
  @Input() declineButton?: CyBakeButton;
  @Input() declineButtonMethod?: OutputFunction;

  @Input() cybakeAppIcon?: boolean = false;
  @Input() loading?: boolean = false;
  @Input() messageHtml?: boolean = true;
  @Input() fixedBottom?: boolean = false;

  @Input() width: number = 30;
  @Input() resizable: boolean = false;
  @Input() maximizable: boolean = false;
  @Input() closable?: boolean = true;
  @Input() dismissableMask: boolean = false;

  @Input() dialogContentTemplate!: TemplateRef<unknown>;

  @Output() hideEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() outputFunctionEvent: EventEmitter<OutputFunction> = new EventEmitter();

  // Services

  dataService: DataService = inject(DataService);

  ngOnInit() {
    Swal.fire({
      title: "<strong></strong>",
      heightAuto: false
    })
  }

  outputFunction(methodName: string) {
    this.outputFunctionEvent.emit({ MethodName: methodName });
  }

  confirm() {
    this.dataService.confirmationPromptOutputFunction(this.confirmButtonMethod!);
    if (this.confirmButton) {
      this.confirmButton.Loading = true;
    }
  }

  decline() {
    this.dataService.confirmationPromptOutputFunction(this.declineButtonMethod!);
    this.declineButton!.Loading = true;
  }
}

import { Component, Input } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'cybake-factory-menu-card',
  standalone: true,
  imports: [RouterOutlet, TranslateModule, RouterModule],
  templateUrl: './menu-card.component.html',
  styleUrl: './menu-card.component.scss'
})
export class MenuCardComponent
  extends BaseCardComponent {
  @Input() subtitle!: string;
  @Input() displayMeasure!: string;
  @Input() count: number = 0;
  @Input() index: number = 0;
  @Input() showArrowIcon: boolean = true;


}

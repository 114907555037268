import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DespatchComponent } from './despatch/despatch.component';
import { GoodsInComponent } from './goods-in/goods-in.component';
import { StockTransfersComponent } from './stock-transfers/stock-transfers.component';
import { StockTakeComponent } from './stock-take/stock-take.component';
import { ProductionComponent } from './production/production.component';
import { PickingComponent } from './picking/picking.component';
import { Settings } from './_shared/models/settings/settings.model';
import { LoginComponent } from './login/login.component';
import { ConditionalComponentLoaderComponent } from './_shared/components/conditional-component-loader/conditional-component-loader.component';
import { LoginPinComponent } from './login/login-pin/login-pin.component';

export const routes: Routes = [
  { path: '', component: ConditionalComponentLoaderComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login-pin', component: LoginPinComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'despatch', component: DespatchComponent },
  { path: 'goods-in', component: GoodsInComponent },
  { path: 'picking', component: PickingComponent },

  { path: 'production', component: ProductionComponent },
  { path: 'production/:productionShiftId?', component: ProductionComponent },
  { path: 'production/:productionShiftId?/:productionPlanId?', component: ProductionComponent },
  { path: 'production/:productionShiftId?/:productionPlanId?/:productId?', component: ProductionComponent },


  { path: 'stock-take', component: StockTakeComponent },
  { path: 'stock-transfer', component: StockTransfersComponent },
  { path: 'settings', component: Settings },
  { path: '**', redirectTo: 'dashboard' },
];

<div class="flex">
  <p-accordion
    class="w-full flex flex-column gap-3"
    expandIcon="pi pi-plus"
    collapseIcon="pi pi-minus"
    [activeIndex]="0"
  >
    @for (trolley of basketItems; track trolley; let i = $index) {
      <p-accordionTab
        id="{{ pageSourceName + '_' + trolley.Id }}_tab"
        class="cybake-lot-tab bottom-border-radial flex flex-row cursor-pointer bg-white items-center relative border border-slate-300 justify-content-between"
      >
        <ng-template pTemplate="header">
          <div
            id="{{ pageSourceName + '_' + trolley.Id }}_productCard"
            class="flex flex-row cursor-pointer w-full items-center relative justify-content-between"
          >
            <div class="flex flex-column">
              <div class="flex flex-row align-items-center gap-2">
                <p
                  id="{{ pageSourceName + '_' + trolley.Id }}_productCardTitle"
                  class="font-semibold"
                  cybake-text-style="body-small"
                >
                  {{ trolley.Name }}
                </p>

                <i
                  (click)="deleteItem(i)"
                  class="fa-solid fa-trash-can text-red-600"
                ></i>
              </div>
              <div class="flex flex-row align-items-center gap-2">
                <p id="{{
                    pageSourceName + '_' + trolley.Id
                  }}_productCardSubtitle"
                   class="italic ellipsis" cybake-text-style='body-xsmall'>
                  {{ trolley.LotNumber }}
                </p>
                @if (trolley.Weight) {
                <div class="flex flex-row align-items-center gap-2">
                  <p class="text-4xl">·</p>
                  <p class="flex flex-row align-items-center gap-2">
                    <span cybake-text-style="body-small">Total</span>
                    <span class="font-semibold" cybake-text-style="body-small">{{ trolley.Weight }}{{ trolley.Measure }}</span>
                  </p>
                </div>
                }
                @if (trolley?.IsTraceable) {
                <cybake-factory-tag [pageSourceName]="pageSourceName"
                                    [identifier]="'cybakeCardTag'"
                                    value="Production.ProductionPlanProductCard.TraceableLabel">
                </cybake-factory-tag>
                }
              </div>
              <!--<p
                id="{{ pageSourceName + '_' + trolley.Id }}_productCardTitle"
                class="font-semibold"
                cybake-text-style="body-small"
              >
                {{ selectedLocation.Name }}
              </p>-->
            </div>
            <div class="flex flex-row gap-3 items-center">

              <cybake-factory-image
                [pageSourceName]="productPhotoImage.PageSourceName"
                [identifier]="productPhotoImage.Identifier"
                [value]="trolley.ImageUrl"
                [width]="productPhotoImage.Width"
                [height]="productPhotoImage.Height"
                [formatDataURI]="productPhotoImage.ImageFormatDataURI"
              >
              </cybake-factory-image>
            </div>
          </div>
        </ng-template>
        <div class="flex flex-column gap-2 px-3 pb-3">
          <div class="flex flex-row align-items-center justify-content-between bg-white px-2">
            @if (trolley.Lots.length && trolley.Lots[0] && trolley.Lots[0].SystemLotNumber) {
            <p cybake-text-style="body-xsmall" class="font-bold">Lot Number</p>
            }
            <p cybake-text-style="body-xsmall" class="font-bold">Container</p>
            <div class="flex flex-row gap-4 align-items-center">
              <p cybake-text-style="body-xsmall" class="font-bold">Quantity</p>
            </div>
          </div>
          @for (lot of trolley.Lots; track lot; let j = $index) {
          <cybake-factory-trolley-card [lot]="lot"
                                       (deleteEvent)="deleteLot(i, j)"></cybake-factory-trolley-card>
          }
        </div>
      </p-accordionTab>
    }
  </p-accordion>
</div>

<div class="flex flex-column gap-3">
  @if(containers.length){
  <cybake-factory-popup-select header="Select Container"
                               [selectedOptionValue]="selectedValue?.ContainerDisplayName"
                               [optionValue]="selectedValue?.ContainerDisplayName"
                               optionLabel="ContainerDisplayName"
                               [options]="containers"
                               (valueChange)="handleValueChange($event)"></cybake-factory-popup-select>

  }
  <cybake-factory-input-number [(value)]="count"
                               (changeEvent)="handleCountValueChange($event)"
                               [min]="0"
                               [showSteppers]="true">
  </cybake-factory-input-number>
  <div class="px-3 pb-4 flex flex-column gap-2">
    <cybake-factory-button [translationKey]="
        inCart() ? 'StockTransfer.UpdateTransfer': 'Add to transfer'
      "
                           [fullWidth]="true"
                           (clickEvent)="onTransferClicked()"
                           [disabled]=" (!count)">
    </cybake-factory-button>

    @if(inCart()){
    <cybake-factory-button [translationKey]="'Remove from Transfer'"
                           [fullWidth]="true"
                           (clickEvent)="removeItem()"
                           [class]="cancelButton.Class"
                           [disabled]="false">
    </cybake-factory-button>
    }
  </div>
</div>
<!--<h1>Tag: {{lotIndex}}</h1>-->

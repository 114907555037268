import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cybake-factory-tag',
  standalone: true,
  imports: [
    TagModule,
    TranslateModule
  ],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss'
})
export class CyBakeTagComponent {

  // Inputs/Outputs
  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  @Input() value!: string;
  @Input() valueFromData?: boolean = false;
  @Input() severity?: string = undefined;
  @Input() rounded: boolean = false;

  @Output() clickEvent: EventEmitter<boolean> = new EventEmitter();
}

import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Knob, KnobModule } from 'primeng/knob';

@Component({
  selector: 'cybake-factory-dial',
  standalone: true,
  imports: [
    KnobModule,
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  templateUrl: './dial.component.html',
  styleUrl: './dial.component.scss'
})
export class CyBakeDialComponent implements OnChanges, AfterViewInit {

  // Children
  @ViewChild('cybakeDial') dialRef!: Knob;
  @ViewChild(Knob) dial!: Knob;

  // Inputs/Outputs
  @Input() pageSourceName: string = '';
  @Input() identifier: string = '';

  @Input() value!: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() dialTouchChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() dialCenterClick: EventEmitter<null> = new EventEmitter<null>();

  @Input() dialCenterTemplate!: HTMLDivElement;

  @Input() min!: number;
  @Input() max!: number;
  @Input() size: number = 100;
  @Input() step: number = 1;
  @Input() loading: boolean = true;
  @Input() readOnly: boolean = true;
  @Input() disabled: boolean = false;
  @Input() hideLabel: boolean = false;
  @Input() valueColor?: string;
  @Input() rangeColor?: string;

  @Input() label?: string;
  @Input() subLabel?: string;
  @Input() labelTranslationParams?: object;
  @Input() subLabelTranslationParams?: object;
  @Input() labelClass?: string;

  dialCenterHtml?: string;
  loadingDialCenterHtml: string = `<div class="vertically-center">
      <i class="fa-regular fa-spinner-third fa-spin text-5xl"></i>
    </div>`;

  ngAfterViewInit() {

    console.log('diaL MIN', this.min);

    if (this.value > this.max) {
      this.value = this.max;
    }

    setTimeout(() => {
      if (this.dialCenterTemplate) {
        this.dialCenterHtml = this.dialCenterTemplate.innerHTML;
      }
    }, 10);
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value'] || changes['valueColor']) {
      if (!changes['value']?.firstChange || !changes['valueColor']?.firstChange) {
        if (this.value > this.max) {
          this.value = this.max;
        }
        this.dialCenterHtml = this.dialCenterTemplate.innerHTML;
      }
    }
  }

}

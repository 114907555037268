<div class="grid m-0 w-full" (press)="openUserActions()">
  {{ foo }}

  <button class="text-3xl text-center p-4 h-5/6 user-profile-avatar m-auto"
          [ngClass]="{
      'placeholder-user-profile border-dashed border-2': placeholderProfile
    }"
          (click)="selectUser()">
    <div class="vertically-center">
      @if (!placeholderProfile) {
      <span>{{ userAcronym }}</span>
      }
      @if (placeholderProfile) {
      <i class="fa-duotone fa-user-plus"></i>
      }
    </div>
  </button>

  @if (showDetails) {
  <div class="text-center h-1/6 pt-1">
    @if (!placeholderProfile) {
    <div>{{ user?.DisplayName }}</div>
    }

    @if (placeholderProfile) {
    <div class="">
      {{ 'LoginProfile.AddNewProfile' | translate }}
    </div>
    }
  </div>
  }
</div>

<!--Login Profile Options-->
<!--<cybake-factory-dialog [title]="'Settings.Header'"
                       [(visible)]="displaySettings"
                       [identifier]="'settings'"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate">
  <ng-template #dialogContentTemplate>
    <cybake-factory-settings></cybake-factory-settings>
  </ng-template>
</cybake-factory-dialog>-->

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GroupedMenu } from '../models/menus/grouped-menu.model';
import { Menu } from '../models/menus/menu.model';
import { GetMenusResponse } from '../models/menus/get-menus-response.model';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  // Services
  http: HttpClient = inject(HttpClient);

  // Service Calls
  getMenus(): Observable<Menu[]> {

    if (localStorage.getItem('menus')) {
      return of(JSON.parse(localStorage.getItem('menus')!))
    }

    const url = 'api/dashboard/menus';

    return this.http.get<GetMenusResponse>(environment.api + url).pipe(
      map((getMenusResponse: GetMenusResponse) => {
        localStorage.setItem('menus', JSON.stringify(getMenusResponse.Menus));
        return getMenusResponse.Menus;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  mapMenusToCyBakeMenu(menuItems: Menu[]): GroupedMenu[] {
    const menus: GroupedMenu[] = [
      {
        label: '',
        items: [
          {
            Id: 1,
            Name: 'Menus.DashboardLabel',
            DisplayContent: 'fa-solid fa-objects-column',
            Title: 'dashboard',
            Enabled: true,
            Sequence: 0
          },
        ],
      },
      {
        label: 'Menus.ActivitiesLabel',
        items: menuItems.length ? menuItems : [
          {
            Id: undefined,
            Name: '',
            DisplayContent: '',
            Title: '',
            Enabled: false,
            Sequence: 0
          }
        ]
      },
      {
        label: 'Menus.AdministrationLabel',
        items: [
          {
            Id: 1,
            Name: 'Menus.SettingsLabel',
            DisplayContent: 'fa-duotone fa-gear',
            Title: '',
            Command: 'openSettings',
            Enabled: true,
            Sequence: 0
          },
          {
            Id: 9,
            Name: 'Menus.AboutLabel',
            DisplayContent: 'fa-duotone fa-solid fa-circle-info',
            Title: '',
            Command: 'openAbout',
            Enabled: true,
            Sequence: 1
          },
          {
            Id: 1,
            Name: 'Menus.LogoutLabel',
            DisplayContent: 'fa-duotone fa-left-from-bracket',
            Title: '',
            Command: 'logout',
            Enabled: true,
            Sequence: 2
          }
        ],
      },
    ];

    return menus;
  }
}

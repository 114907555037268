import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'cybake-factory-settings-dev',
  standalone: true,
  imports: [],
  templateUrl: './settings-dev.component.html',
  styleUrl: './settings-dev.component.scss',
})
export class SettingsDevComponent {

  // Inputs/Outputs
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

}

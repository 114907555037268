import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StockLocationType } from '../../models/stock-transfers/stocks/stock-location-types.model';

@Injectable({
  providedIn: 'root',
})
export class StockTransfersServiceMock {
  getStockLocations(): Observable<StockLocationType[]> {
    const warehouses: StockLocationType[] = [
      {
        Id: 1,
        Name: 'Warehouse',
        StockLocations: [
          {
            Id: 1,
            Name: 'Leeds - WHFRZ',
          },
          {
            Id: 2,
            Name: 'Leeds - WHCHLL',
          },
          {
            Id: 3,
            Name: 'Leeds - WHAMB – ROW 1',
          },
          {
            Id: 4,
            Name: 'Leeds - WHAMB – ROW 2',
          },
        ],
      },
      {
        Id: 2,
        Name: 'Production',
        StockLocations: [
          {
            Id: 5,
            Name: 'Leeds - PRDLC',
          },
          {
            Id: 6,
            Name: 'Leeds - PRDHC',
          },
        ],
      },
      {
        Id: 3,
        Name: 'Despatch',
        StockLocations: [
          {
            Id: 7,
            Name: 'Leeds - DSPAMB',
          },
          {
            Id: 8,
            Name: 'Leeds - DSPCHL',
          },
          {
            Id: 9,
            Name: 'Leeds - DSPFRZ',
          }
        ],
      },
    ];
    return of(warehouses);
  }

  createStockTransferRequest(): Observable<null> {
    return of(null);
  }
}



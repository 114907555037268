<p-accordion class="flex w-full">
  @for (tab of accordionTabs; track tab) {
    <p-accordionTab iconPos="end" id="{{ pageSourceName + '_' + tab.Id }}_tab">
      <ng-template pTemplate="header">
        <div class="custom-header">
          <span cybake-text-style="title-text" id="{{ pageSourceName + '_' + tab.Id }}_header">{{ tab.Name }}</span>
        </div>
      </ng-template>
      <div class="w-full flex flex-column gap-3">
        @for (item of returnTabItems(tab,itemsKey); track item) {
        <ng-container>
          <ng-container *ngTemplateOutlet="
                contentTemplate;
                context: {
                  $implicit: item,
                  pageSourceName: pageSourceName,
                  identifier: 'accordionItem-' + item.Id
                }
              "></ng-container>
        </ng-container>
        }
      </div>
    </p-accordionTab>
  }
</p-accordion>

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { Lot } from '../models/stock-transfers/product/lot.model';
import { environment } from '../../../environments/environment';
import { Container } from '../models/stock-transfers/product/container.model';
import { Trolley } from '../models/stock-transfers/product/trolley.model';
import { StockLocation } from '../models/stock-transfers/stocks/stock-location.model';
import { Product } from '../models/stock-transfers/product/product.model';
import { GetLotsResponse } from '../models/stock-transfers/product/lot-response.model';
import { GetContainersResponse } from '../models/stock-transfers/product/container-response.model';

export interface StockTransferOptions {
  isMultipleProduct: boolean;
  selectedProduct?: Product;
  selectedLocation?: StockLocation;
  selectedDestination?: StockLocation;
}
@Injectable({
  providedIn: 'root',
})
export class LotService {
  // Services
  http: HttpClient = inject(HttpClient);
  private basketItemsSource = new BehaviorSubject<Trolley[] | null>(null);
  basketItems$ = this.basketItemsSource.asObservable();

  private stockTransferOptionsSource = new BehaviorSubject<StockTransferOptions | null>(null);
  stockTransferOptions$ = this.stockTransferOptionsSource.asObservable();

  updateStockTransferOptions(data: StockTransferOptions) {
    this.stockTransferOptionsSource.next(data);
  }


  updateBasketItemsSource(data: Trolley[]) {
    this.basketItemsSource.next(data);
  }

  // Service Calls
  getLots(id: number): Observable<Lot[]> {
    const url = `api/stocktransfer/products/${id}/lots`;

    return this.http.get<GetLotsResponse>(environment.api + url).pipe(
      map((getLotsResponse: GetLotsResponse) => {
        return getLotsResponse.StockLots;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getContainers(id: number): Observable<Container[]> {
    const url = `api/stocktransfer/products/${id}/containers`;

    return this.http.get<GetContainersResponse>(environment.api + url).pipe(
      map((getContainerResponse: GetContainersResponse) => {
        return getContainerResponse.ProductContainerListItems;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getTrolleys(): Observable<Trolley[]> {
    const url = 'api/trolleys';

    return this.http.get<Trolley[]>(environment.api + url).pipe(
      map((getTrolleyResponse: Trolley[]) => {
        return getTrolleyResponse;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }
  constructor() {}
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Site } from '../../models/sites/site.model';
//import { faker } from '@faker-js/faker';

@Injectable({
  providedIn: 'root',
})
export class SiteServiceMock {
  getSites(): Observable<Site[]> {
    const sites: Site[] = [];

    const names: string[] = [
      'Leeds',
      'York'
    ];

    for (let i = 1; i < 3; i++) {
      const site: Site = {
        Id: i,
        Name: names[i - 1],
        Sequence: i,
        IsDefault: i === 1 ? true : false
      };
      sites.push(site);
    }

    return of(sites);
  }
}

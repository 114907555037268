import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';
import { TranslateModule } from '@ngx-translate/core';
import { DataService } from '../_shared/services/data.service';
import { Subscription } from 'rxjs';
import { NavbarTitle } from '../_shared/models/navbar/navbar-title.model';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState, Breakpoints, LayoutModule } from '@angular/cdk/layout';

@Component({
  selector: 'cybake-factory-navbar',
  standalone: true,
  imports: [
    CyBakeButtonComponent,
    TranslateModule,
    CommonModule,
    LayoutModule
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnDestroy, OnInit {

  // Inputs/Outputs
  @Output() openMenuEvent: EventEmitter<boolean> = new EventEmitter();

  // Services
  dataService: DataService = inject(DataService);
  breakpointObserver: BreakpointObserver = inject(BreakpointObserver);
  router: Router = inject(Router);

  // Variables
  pageSourceName: string = 'navbar';
  navbarTitle!: NavbarTitle;
  shopUpdateIcon: boolean = false;

  // Menu Button
  menuButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'mainMenu',
    IconKey: ['fa-duotone', ' fa-bars-sort'],
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.transparent,
    IconClass: 'mainMenu-icon',
    Loading: false,
  });

  // Subscriptions
  subscriptionUpdateNavbarTitle!: Subscription;
  subscriptionClearNavbarTitle!: Subscription;
  subscriptionShowUpdateIcon!: Subscription;

  // Screen Breakpoints
  isMobilePortrait: boolean = false;
  isMobileLandscape: boolean = false;
  isTabletLandscape: boolean = false;
  isTabletPortrait: boolean = false;

  ngOnInit() {

    // Update Navbar Title
    this.subscriptionUpdateNavbarTitle = this.dataService.updateNavbarTitleState.subscribe((navbarTitle: NavbarTitle) => {
      setTimeout(() => { this.navbarTitle = navbarTitle; }, 1);     
    });

    // Clear Navbar Title
    this.subscriptionClearNavbarTitle = this.dataService.clearNavbarTitleState.subscribe(() => {
      setTimeout(() => { this.navbarTitle = {Title: ''}; }, 1);
    });

    // Show Update Icon
    this.subscriptionShowUpdateIcon = this.dataService.showUpdateIconNavbarState.subscribe((showIcon: boolean) => {
      this.shopUpdateIcon = showIcon;
    });

    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
    ]).subscribe((state: BreakpointState) => {
      this.isMobilePortrait = state.breakpoints[Breakpoints.HandsetPortrait];
      this.isMobileLandscape = state.breakpoints[Breakpoints.HandsetLandscape];
      this.isTabletLandscape = state.breakpoints[Breakpoints.TabletLandscape];
      this.isTabletPortrait = state.breakpoints[Breakpoints.TabletPortrait];
    });
  }

  navbarTitleOutput() {

  }

  ngOnDestroy() {
    if (this.subscriptionUpdateNavbarTitle) {
      this.subscriptionUpdateNavbarTitle.unsubscribe();
    }

    if (this.subscriptionClearNavbarTitle) {
      this.subscriptionClearNavbarTitle.unsubscribe();
    }
  }

  navigateToDashboard() {
    //this.dataService.openToast({ Detail: 'Hello', Summary: 'Foo Bar', Severity: ToastSeverity.success });
    this.router.navigateByUrl('/dashboard');
  }

  openUpdateAppDialog() {
    this.dataService.openUpdateDialog(true);
  }
}

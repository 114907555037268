import { Component, OnInit, ViewContainerRef, inject } from '@angular/core';
import { UserDetails } from '../../models/user/user-details.model';
import { LoginPinComponent } from '../../../login/login-pin/login-pin.component';
import { LoginComponent } from '../../../login/login.component';

@Component({
  selector: 'cybake-factory-conditional-component-loader',
  standalone: true,
  imports: [],
  templateUrl: './conditional-component-loader.component.html',
  styleUrl: './conditional-component-loader.component.scss',
})
export class ConditionalComponentLoaderComponent implements OnInit {
  // Variables
  //componentFactoryResolver = inject(ComponentFactoryResolver);
  viewContainerRef = inject(ViewContainerRef);

  ngOnInit(): void {
    const existingUsers: UserDetails[] = JSON.parse(
      localStorage.getItem('users')!,
    );

    let componentToLoad = existingUsers?.length
      ? LoginPinComponent
      : LoginComponent;

    // Force going to login even with existing users
    componentToLoad = LoginComponent;

    // Create and render the component dynamically
    //const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentToLoad);
    this.viewContainerRef.clear();
    this.viewContainerRef.createComponent(componentToLoad as never);
  }
}

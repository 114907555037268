<div class="inline-flex cybake-switch {{transparent ? '' : 'bg-white'}} w-full h-full {{ class }}"
     [ngClass]="{ 'cybake-switch-disabled': disabled }"
     pTooltip="{{ tooltip | translate }}"
     tooltipPosition="{{ tooltipPosition }}"
     [tooltipDisabled]="!tooltip">

  @if (translationKey) {
  <div class="vertically-center-left-align cybake-switch-text cursor-pointer w-full pr-2 {{ !rounded ? 'cybake-switch-label' : ''}}"
       (click)="toggleSwitchValue()">
    {{ translationKey | translate: translationKeyParams }}
  </div>
  }

  <div class="float-end">
    @if (!loading) {
    <p-inputSwitch class="vertically-center small-input-switch {{ !rounded ? 'cybake-switch-square' : ''}} {{translationKey && !rounded ? 'cybake-switch-square-with-translation': ''}}"
                   [(ngModel)]="value"
                   (onChange)="toggleSwitch($event); this.valueChange.emit(value)"
                   [disabled]="disabled">
    </p-inputSwitch>
    }

    @if (loading) {
    <div class="spinner-border button-spinner cybake-switch-spinner text-light"></div>
    }
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GroupedMenu } from '../../models/menus/grouped-menu.model';
import { Menu } from '../../models/menus/menu.model';

@Injectable({
  providedIn: 'root',
})
export class MenuServiceMock {

  getMenus(): Observable<Menu[]> {
    const menus: Menu[] = [
      {
        Id: 2,
        Name: 'Menus.DespatchLabel',
        DisplayContent: 'fa-duotone fa-truck-ramp-box',
        Title: 'despatch',
        Enabled: false,
        Sequence: 6
      },
      {
        Id: 3,
        Name: 'Menus.GoodsInLabel',
        DisplayContent: 'fa-duotone fa-receipt',
        Title: 'goods-in',
        Enabled: false,
        Sequence: 3
      },
      {
        Id: 4,
        Name: 'Menus.PickLabel',
        DisplayContent: 'fa-duotone fa-hand-holding-box',
        Title: 'picking',
        Enabled: false,
        Sequence: 4
      },
      {
        Id: 5,
        Name: 'Menus.ProductionLabel',
        DisplayContent: 'fa-duotone fa-conveyor-belt-arm',
        Title: 'production',
        Enabled: true,
        Sequence: 1
      },
      {
        Id: 6,
        Name: 'Menus.StockTakeLabel',
        DisplayContent: 'fa-duotone fa-box-taped',
        Title: 'stock-take',
        Enabled: false,
        Sequence: 5
      },
      {
        Id: 7,
        Name: 'Menus.StockTransferLabel',
        DisplayContent: 'fa-duotone fa-dolly',
        Title: 'stock-transfer',
        Enabled: true,
        Sequence: 2
      }
    ];

    return of(menus);
  }

  mapMenusToCyBakeMenu(menuItems: Menu[]): GroupedMenu[] {
    const menus: GroupedMenu[] = [
      {
        label: '',
        items: [
          {
            Id: 1,
            Name: 'Menus.DashboardLabel',
            DisplayContent: 'fa-solid fa-objects-column',
            Title: 'dashboard',
            Enabled: true,
            Sequence: 0
          },
        ],
      },
      {
        label: 'Menus.ActivitiesLabel',
        items: menuItems.length ? menuItems : [
          {
            Id: undefined,
            Name: '',
            DisplayContent: '',
            Title: '',
            Enabled: false,
            Sequence: 0
          }
        ]
      },
      {
        label: 'Menus.AdministrationLabel',
        items: [
          {
            Id: 8,
            Name: 'Menus.SettingsLabel',
            DisplayContent: 'fa-duotone fa-gear',
            Title: '',
            Command: 'openSettings',
            Enabled: true,
            Sequence: 0
          },
          {
            Id: 9,
            Name: 'Menus.AboutLabel',
            DisplayContent: 'fa-duotone fa-solid fa-circle-info',
            Title: '',
            Command: 'openAbout',
            Enabled: true,
            Sequence: 1
          },
          {
            Id: 10,
            Name: 'Menus.LogoutLabel',
            DisplayContent: 'fa-duotone fa-left-from-bracket',
            Title: '',
            Command: 'logout',
            Enabled: true,
            Sequence: 2
          }
        ],
      },
    ];

    return menus;
  }
}

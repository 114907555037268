@if (!loading) {
  <div
    id="{{ pageSourceName }}_{{ identifier }}_toggleButton_div"
    class="h-full"
    pTooltip="{{ tooltip | translate }}"
    tooltipPosition="{{ tooltipPosition }}"
    [tooltipDisabled]="!tooltip"
    appendTo="body"
  >
    <p-toggleButton
      id="{{ pageSourceName }}_{{ identifier }}_toggleButton"
      [ngModel]="value"
      (ngModelChange)="value = $event; this.valueChange.emit($event)"
      styleClass="w-full h-full {{bigIcon && (buttonOnLabel || buttonOffLabel) ? 'cybake-toggle-big-icon' : '' }} shadow-2"
      onIcon="{{buttonOnIcon!.length > 1 ? 'fa-icon ' + buttonOnIcon[0] : '' }}{{ buttonOnIcon!.length > 1 ? ' ' + buttonOnIcon[1] : '' }}"
      offIcon="{{ buttonOffIcon!.length > 1 ? 'fa-icon ' + buttonOffIcon[0] : '' }}{{ buttonOffIcon!.length > 1 ? ' ' + buttonOffIcon[1] : '' }}"
      onLabel="{{ buttonOnLabel | translate }}"
      offLabel="{{ buttonOffLabel ? (buttonOffLabel | translate) : (buttonOnLabel | translate) }}"
      class="{{ class }} cybake-toggle-button {{ disabled ? 'cybake-toggle-button-disabled' : '' }}"
      [disabled]="disabled">
    </p-toggleButton>
  </div>}

@if (loading) {
  <div class="p-togglebutton">
    <p-skeleton
      id="{{ pageSourceName }}_{{ identifier }}_toggleButton_skeleton"
      styleClass="h-100 w-100 cybake-toggle-button-skeleton"
      width="100%"
      height="100%"
    >
    </p-skeleton>
  </div>
}

import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserDetails } from '../models/user/user-details.model';
import { Settings } from '../models/settings/settings.model';
import { GetUserResponse } from '../models/user/get-user-response.model';
import * as _ from 'underscore';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Services
  http: HttpClient = inject(HttpClient);

  // Service Calls
  getUsers(): Observable<UserDetails[]> {
    const url = 'api/users';

    return this.http.get<UserDetails[]>(environment.api + url).pipe(
      map((getMenusResponse: UserDetails[]) => {
        return getMenusResponse;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getUserDetails(): Observable<UserDetails> {
    if (localStorage.getItem('user')) {
      return of(JSON.parse(localStorage.getItem('user')!))
    }

    const url = `api/dashboard/user`;

    return this.http.get<GetUserResponse>(environment.api + url).pipe(
      map((getUserDetailsResponse: GetUserResponse) => {
        return this.logUserDetails(getUserDetailsResponse);
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  setUserDetails(loginDetails: GetUserResponse): UserDetails {
    const userDetails: UserDetails = {
      UserId: loginDetails.User.UserId,
      UserName: loginDetails.User.UserName,
      DisplayName: loginDetails.User.DisplayName,
      LastLogin: moment().toDate(),
      MasterCompanyId: loginDetails.User.MasterCompanyId,
      TimeZoneId: loginDetails.User.TimeZoneId,
      Settings: new Settings({
        ThemeId: 1,
        FontSizeId: 2,
        HighContrast: false,
        HapticFeedback: true,
        Sounds: true,
        DateFormat: loginDetails.CompanyDateFormat,
        TimeFormat: loginDetails.CompanyTimeFormat
      }),
    };

    localStorage.setItem('user', JSON.stringify(userDetails));
    return userDetails;
  }

  logUserDetails(userDetails: GetUserResponse): UserDetails {
    // get list of logged users from local storage
    let existingUsers: UserDetails[] = JSON.parse(
      localStorage.getItem('users')!,
    ) 

    let newUserDetails: UserDetails;
    let existingUser: UserDetails;

    if (existingUsers) {

      // See if the user that just logged in exists in the existing users list
      existingUser = _.find(existingUsers, (user: UserDetails) => {
        return user.UserId === userDetails.User.UserId;
      }) as UserDetails;

      // If it exists, log the user in local storage with previous settings
      // ELSE - create new user settings and then log user and add them to users list
      if (existingUser) {
        localStorage.setItem('user', JSON.stringify(existingUser));
        return existingUser;
      } else {
        newUserDetails = this.setUserDetails(userDetails);
        existingUsers.push(newUserDetails);
        localStorage.setItem('users', JSON.stringify(existingUsers));
        return newUserDetails;
      }
      
    } else {
      newUserDetails = this.setUserDetails(userDetails);
      existingUsers = [newUserDetails];
      localStorage.setItem('users', JSON.stringify(existingUsers));
      return newUserDetails
    }
  }
}

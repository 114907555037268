import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseCardComponent } from '../base-card/base-card.component';
import { ImageComponent } from '../../components/image/image.component';
import { CyBakeImage } from '../../models/image/image.model';
import { CyBakeTagComponent } from '../../components/tag/tag.component';
import { CyBakeCardComponent } from '../../components/card/card.component';
import { environment } from '../../../../environments/environment';
import { ProductServiceMock } from '../../services/mock-services/product.service.mock';
import { ProductService } from '../../services/product.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cybake-factory-product-card',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    RouterModule,
    ImageComponent,
    CyBakeCardComponent,
    CyBakeTagComponent,
  ],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
})
export class ProductCardComponent
  extends BaseCardComponent
  implements OnChanges {
  @Input() imageUrl!: string;
  @Input() subtitle!: string;
  @Input() weight!: number;
  @Input() displayMeasure!: string;
  @Input() loading: boolean = false
  @Input() showArrowIcon: boolean = true;
  @Input() tagValue?: string;
  @Input() selectedProduct!: number | undefined;
  @Output() viewProductInfoEvent: EventEmitter<string> = new EventEmitter<string>();


  photoUrl!: Blob;
  imageLoading: boolean = true;


  constructor(
    private sanitizer: DomSanitizer) {
    super();
  }
  // Product Photo
  productPhotoImage: CyBakeImage = new CyBakeImage({
    Value: undefined,
    Identifier: 'productImage',
    PageSourceName: this.pageSourceName,
    Height: 50,
    Width: 50,
    ImageFormatDataURI: true,
  });

  productService: ProductService | ProductServiceMock =
    environment.mock
      ? inject(ProductServiceMock)
      : inject(ProductService);


  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['imageUrl'] &&
      changes['imageUrl'].previousValue !== changes['imageUrl'].currentValue
    ) {
      this.productPhotoImage.Value = changes['imageUrl'].currentValue;
    }
    if (this.identifier) {
      this.productService.getProductImage(this.identifier).subscribe((url) => {
        if (url && url.size) {
          this.photoUrl = url;
          const urlCreator = window.URL;
          this.imageUrl = urlCreator.createObjectURL(url);
          this.imageLoading = false;
        }
      })

    }
  }
  viewProductInfo() {
    this.viewProductInfoEvent.emit(this.imageUrl);
  }


  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}

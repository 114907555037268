import { Component } from '@angular/core';

@Component({
  selector: 'cybake-factory-goods-in',
  standalone: true,
  imports: [],
  templateUrl: './goods-in.component.html',
  styleUrl: './goods-in.component.scss',
})
export class GoodsInComponent {}

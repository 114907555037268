import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../models/cybake/button/button-class.enum';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { DataService } from '../../services/data.service';
import { DialogOptionsModel } from '../../models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';
import { CyBakeConfirmationPrompTypeEnum } from '../../models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';
import { OutputFunction } from '../../models/common/output-function.model';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { UserDetails } from '../../models/user/user-details.model';

@Component({
  selector: 'cybake-factory-app-info',
  standalone: true,
  imports: [
    TranslateModule,
    CyBakeButtonComponent,
    CommonModule
  ],
  templateUrl: './app-info.component.html',
  styleUrl: './app-info.component.scss'
})
export class AppInfoComponent implements OnInit {

  // Inputs/Outputs
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

  // Services
  swUpdate: SwUpdate = inject(SwUpdate);
  dataService: DataService = inject(DataService);

  // Variables
  pageSourceName: string = 'appInfo';
  versionNumber!: string;
  releaseDate!: string;
  hideUpdateBtn: boolean = false;
  userDetails!: UserDetails;

  // Check for update button
  checkForUpdateButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'checkForUpdate',
    TranslationKey: 'AppInfo.CheckUpdateButton',
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.action,
    Loading: false,
  });

  // Close button
  closeButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'close',
    TranslationKey: 'AppInfo.CloseButton',
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.default,
    Loading: false,
  });

  // Subscription
  newVersionSubscription!: Subscription

  ngOnInit() {
    this.versionNumber = environment.version;
    const environmentReleaseDate = environment.releaseDate;

    this.userDetails = JSON.parse(localStorage.getItem('user')!);

    const releaseInfoArray = environmentReleaseDate.split('-')
    if (this.userDetails) {
      this.releaseDate = moment(releaseInfoArray[3] + ' ' + releaseInfoArray[4]).format(this.userDetails.Settings.DateFormat.MomentShort + ' ' + this.userDetails.Settings.TimeFormat.Short);
    }

    if (this.swUpdate.isEnabled) {
      this.hideUpdateBtn = false;
    } else {
      this.hideUpdateBtn = true;
    }
  }

  async checkForUpdate() {
    this.checkForUpdateButton.Loading = true;

    //this.newVersionSubscription?.unsubscribe();

    //if (!this.swUpdate.isEnabled) {
    //  this.checkForUpdateButton.Loading = false;
    //  return;
    //}

    this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => {
      switch (event.type) {
        case 'VERSION_DETECTED':
          console.log('VERSION_DETECTED');
          break;
        case 'VERSION_READY':
          console.log('VERSION_READY');
          this.openUpdateDialog();
          break;
        case 'NO_NEW_VERSION_DETECTED':
          console.log('NO_NEW_VERSION_DETECTED');
          this.checkForUpdateButton.Loading = false;
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log('VERSION_INSTALLATION_FAILED');
          this.checkForUpdateButton.Loading = false;
          break;
      }     
    });

  }

  openUpdateDialog() {
    const loggedIn = (location.pathname === '/' || location.pathname === '/login-pin' || location.pathname === '/login') ? false : true;

    if (loggedIn) {
      this.dataService.showUpdateIconNavbar(true);
    }

    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.info,
      Title: 'UpdateApplicationPopup.Title',
      Message: `UpdateApplicationPopup.Message`,
      MessageHtml: false,
      PageSourceName: this.pageSourceName,
      Identifier: 'updateApplicationToLatest',
      Closable: loggedIn,
      ConfirmButtonMethod: new OutputFunction({
        MethodName: 'updateApplication'
      }),
      ConfirmButton: new CyBakeButton({
        PageSourceName: this.pageSourceName,
        TranslationKey: 'UpdateApplicationPopup.UpdateBtn',
        Identifier: 'updateApplication',
        IconKey: ['fa-duotone fa-solid', 'fa-download'],
        Type: ButtonTypeEnum.default,
        Class: ButtonClassEnum.default,
      })
    });

    this.dataService.openConfirmationPrompt(options);
    this.checkForUpdateButton.Loading = false;
  }
}

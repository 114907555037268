import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProductionLocation } from '../models/production/locations/production-location.model';
import { ProductionShift } from '../models/production/shifts/production-shift.model';
import { ProductionPlan } from '../models/production/plans/production-plan.model';
import { StockLot } from '../models/production/stocklots/stock-lot.model';
import { Site } from '../models/sites/site.model';
import { GetProductionLocationsListResponse } from '../models/production/locations/get-production-location-response.model';
import { GetProductionShiftResponse } from '../models/production/shifts/get-production-shift-response.model';
import { GetProductionPlansResponse } from '../models/production/plans/get-production-plans-response.model';
import { GetProductionPlanProductsResponse } from '../models/production/plans/get-production-plan-products-response.model';
import { ProductionPlanProduct } from '../models/production/plans/production-plan-product.model';
import { GetProductImageResponse } from '../models/production/plans/production-plan-product-image.model';
import { ProductionPlanProductMix } from '../models/production/plans/production-plan-recipe-line-mix.model';
import { GetProductionPlanProductMixResponse } from '../models/production/plans/get-production-plan-product-mix-response.model';
import { GetProductionPlanProductRecipeMethodLinesResponse } from '../models/production/plans/get-production-plan-product-recipe-method-lines-response.model';
import { GetProductionPlanProductRecipeLineResponse } from '../models/production/plans/get-production-plan-product-recipe-line-response.model';
import { GetProductionUsedLinesResponse } from '../models/production/plans/get-production-used-lines-response.model';
import { ProductionUsedLine } from '../models/production/plans/production-used-line.model';
import { ProductionPlanItemRecipeLine } from '../models/production/plans/production-plan-item-recipe-line.model';
import { PersistProductionUsedLineRequest } from '../models/production/plans/persist-production-used-line-request.model';
import { ProductionPlanProductRecipeMethod } from '../models/production/plans/production-plan-recipe-methods.model';
import { GetProductionPlanProductRecipeMethodLineResponse } from '../models/production/plans/get-production-plan-product-recipe-method-line-response.model';
import { GetStockLotsResponse } from '../models/production/stocklots/get-stock-lots-response';
import { GetProductionPlanProductRecipeLinesResponse } from '../models/production/plans/get-production-plan-product-recipe-lines-response.model';
import { CompleteProductionPlanLineRecipeLineResponse } from '../models/production/plans/complete-recipe-line-response.model';
import { CompleteProductionPlanLineResponse } from '../models/production/plans/complete-production-plan-line-response.model';

@Injectable({
  providedIn: 'root',
})
export class ProductionService {
  // Services
  http: HttpClient = inject(HttpClient);

  // Service Calls
  getProductionLocations(): Observable<ProductionLocation[]> {
    let url = 'api/production/locations';
    const site: Site = JSON.parse(localStorage.getItem('deviceSite')!);

    if (site) {
      url += `?siteId=${site.Id}`;
    }

    return this.http.get<GetProductionLocationsListResponse>(environment.api + url).pipe(
      map((getProductionLocationsResponse: GetProductionLocationsListResponse) => {
        return getProductionLocationsResponse.ProductionLocationListItems;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getCurrentProductionShift(): Observable<ProductionShift> {
    let url = 'api/production/shift';
    const site: Site = JSON.parse(localStorage.getItem('deviceSite')!);

    if (site) {
      url += `?siteId=${site.Id}`;
    }

    return this.http.get<GetProductionShiftResponse>(environment.api + url).pipe(
      map((getCurrentProductionPlanResponse: GetProductionShiftResponse) => {
        return getCurrentProductionPlanResponse.ProductionShift;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlans(productionLocationId: number): Observable<ProductionPlan[]> {
    let url = `api/production/plans?productionLocationId=${productionLocationId}`;
    const site: Site = JSON.parse(localStorage.getItem('deviceSite')!);

    if (site) {
      url += `&siteId=${site.Id}`;
    }

    return this.http.get<GetProductionPlansResponse>(environment.api + url).pipe(
      map((getProductionPlansResponse: GetProductionPlansResponse) => {
        return getProductionPlansResponse.ProductionPlans;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlanProducts(productionPlanId: number): Observable<ProductionPlanProduct[]> {
    const url = `api/production/plansproducts?productionPlanId=${productionPlanId}`;

    return this.http.get<GetProductionPlanProductsResponse>(environment.api + url).pipe(
      map((getProductionPlansResponse: GetProductionPlanProductsResponse) => {
        return getProductionPlansResponse.ProductionPlanProducts;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlanProductMixes(productionPlanId: number, productId: number, productToContainerId?: number): Observable<ProductionPlanProductMix[]> {
    let url = `api/production/mixes?productionPlanId=${productionPlanId}&productId=${productId}`;

    if (productToContainerId)
      url += `&productToContainerId=${productToContainerId}`;

    return this.http.get<GetProductionPlanProductMixResponse>(environment.api + url).pipe(
      map((getProductionPlansResponse: GetProductionPlanProductMixResponse) => {
        return getProductionPlansResponse.ProductionPlanProductMixes;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlanMethodLines(productionPlanId: number, productionPlanLineId: number): Observable<ProductionPlanProductRecipeMethod[]> {
    const url = `api/production/methods?productionPlanId=${productionPlanId}&productionPlanLineId=${productionPlanLineId}`;

    return this.http.get<GetProductionPlanProductRecipeMethodLinesResponse>(environment.api + url).pipe(
      map((getProductionPlansResponse: GetProductionPlanProductRecipeMethodLinesResponse) => {
        return getProductionPlansResponse.Methods;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlanRecipeLines(productionPlanId: number, productionPlanLineId: number): Observable<ProductionPlanItemRecipeLine[]> {
    const url = `api/production/recipelines?productionPlanId=${productionPlanId}&productionPlanLineId=${productionPlanLineId}`;

    return this.http.get<GetProductionPlanProductRecipeLinesResponse>(environment.api + url).pipe(
      map((getProductionPlansResponse: GetProductionPlanProductRecipeLinesResponse) => {
        return getProductionPlansResponse.RecipeLines;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlanMethodLine(productionPlanId: number, productionPlanLineId: number, methodId: number): Observable<GetProductionPlanProductRecipeMethodLineResponse> {
    const url = `api/production/method?productionPlanId=${productionPlanId}&productionPlanLineId=${productionPlanLineId}&methodId=${methodId}`;

    return this.http.get<GetProductionPlanProductRecipeMethodLineResponse>(environment.api + url).pipe(
      map((getProductionPlanProductRecipeLineResponse: GetProductionPlanProductRecipeMethodLineResponse) => {
        return getProductionPlanProductRecipeLineResponse;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlanRecipeLine(productionPlanId: number, productionPlanLineId: number, recipeLineId: number): Observable<ProductionPlanItemRecipeLine> {
    const url = `api/production/recipeline?productionPlanId=${productionPlanId}&productionPlanLineId=${productionPlanLineId}&recipeLineId=${recipeLineId}`;

    return this.http.get<GetProductionPlanProductRecipeLineResponse>(environment.api + url).pipe(
      map((getProductionPlanProductRecipeLineResponse: GetProductionPlanProductRecipeLineResponse) => {
        return getProductionPlanProductRecipeLineResponse.RecipeLine;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlanRecipeLineUsedLines(productionPlanLineId: number, recipeLineId: number): Observable<ProductionUsedLine[]> {
    const url = `api/production/used?productionPlanLineId=${productionPlanLineId}&recipeLineId=${recipeLineId}`;

    return this.http.get<GetProductionUsedLinesResponse>(environment.api + url).pipe(
      map((getProductionPlanLineRecipeLineUsedLinesResponse: GetProductionUsedLinesResponse) => {
        return getProductionPlanLineRecipeLineUsedLinesResponse.UsedLines;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  createProductionPlanRecipeLineUsedLine(request?: PersistProductionUsedLineRequest | null): Observable<null> {
    const url = `api/production/used`;

    return this.http.post<null>(environment.api + url, request).pipe(
      map((response: null) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  updateProductionPlanRecipeLineUsedLine(request?: PersistProductionUsedLineRequest | null): Observable<null> {
    const url = `api/production/used`;

    return this.http.put<null>(environment.api + url, request).pipe(
      map((response: null) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  deleteProductionPlanRecipeLineUsedLine(productionLineId: number, productionLineUsedId: number): Observable<null> {
    const url = `api/production/used/delete?productionLineId=${productionLineId}&productionLineUsedId=${productionLineUsedId}`;

    return this.http.delete<null>(environment.api + url).pipe(
      map((response: null) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlanMethodImage(fileIdentifier: string): Observable<Blob> {
    const url = `api/production/methodimage?fileIdentifier=${fileIdentifier}`;

    return this.http.get(environment.api + url, { responseType: 'arraybuffer' }).pipe(
      map((getProductionPlansResponse: BlobPart) => {
        const blob = new Blob([getProductionPlansResponse], { type: 'application/octet-stream' });
        return <Blob>blob;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  getProductionPlanProductImage(productId: number): Observable<string> {
    const url = `api/production/productimage?productId=${productId}`;

    return this.http.get<GetProductImageResponse>(environment.api + url).pipe(
      map((getProductionPlansResponse: GetProductImageResponse) => {
        // LS - This needs changing to handle blob, not sure what needs changing though.
        return getProductionPlansResponse?.ProductImage;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  completeProductionPlanProductRecipeLine(productionPlanLineId: number, recipeLineId: number): Observable<CompleteProductionPlanLineRecipeLineResponse> {
    const url = `api/production/recipeline/complete?productionPlanLineId=${productionPlanLineId}&recipeLineId=${recipeLineId}`;

    return this.http.get<CompleteProductionPlanLineRecipeLineResponse>(environment.api + url).pipe(
      map((completeProductionPlanProductRecipeLineResponse: CompleteProductionPlanLineRecipeLineResponse) => {
        return completeProductionPlanProductRecipeLineResponse;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  completeProductionPlanLineAsync(productionPlanId: number, productionPlanLineId: number, quantity: number): Observable<CompleteProductionPlanLineResponse> {
    const url = `api/production/plansproduct/complete?productionPlanId=${productionPlanId}&productionPlanLineId=${productionPlanLineId}&quantity=${quantity}`;

    return this.http.get<CompleteProductionPlanLineResponse>(environment.api + url).pipe(
      map((completeProductionPlanProductRecipeLineResponse: CompleteProductionPlanLineResponse) => {
        return completeProductionPlanProductRecipeLineResponse;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }  

  getStockLots(productionPlanId: number, productId: number): Observable<StockLot[]> {
    const url = `api/production/stocklots?productionPlanId=${productionPlanId}&productId=${productId}`;

    return this.http.get<GetStockLotsResponse>(environment.api + url).pipe(
      map((getStockLotsResponse: GetStockLotsResponse) => {
        return getStockLotsResponse.StockLots;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }
  
}


<div class="barcode-scan-div">
  <zxing-scanner [enable]="scannerEnabled"
                 [(device)]="desiredDevice"
                 [formats]="formatsEnabled"
                 [torch]="enableTorch"
                 (torchCompatible)="onTorchCompatible($event)"
                 (permissionResponse)="onHasPermission($event)"
                 (camerasFound)="onCamerasFound($event)"
                 (scanSuccess)="scanSuccessHandler($event)">
  </zxing-scanner>
</div>

<!--onTorchCompatible-->
<cybake-factory-button [iconKey]="enableTorchButton.IconKey"
                       [class]="enableTorchButton.Class"
                       [customClass]="'barcode-scan-flashlight-btn' + (enableTorch ? ' barcode-scan-flashlight-on' : ' barcode-scan-flashlight-off')"
                       (clickEvent)="toggleTorch()">
</cybake-factory-button>

@if (availableDevices.length) {
<cybake-factory-button [iconKey]="switchCamerasButton.IconKey"
                       [class]="switchCamerasButton.Class"
                       [customClass]="'barcode-scan-camera-switch-btn'"
                       (clickEvent)="onDeviceSelectChange()">
</cybake-factory-button>
}

@if(scanResult) {
<div class="inline-flex w-full mt-2 p-2 bg-cyan-200" style="border-radius: .5em;">
  <div class="w-full">
    <div class="vertically-center-left-align">{{scanResult}}</div>
  </div>
  <div>
    <cybake-factory-button [translationKey]="scanningResultButton.TranslationKey"
                           [pageSourceName]="scanningResultButton.PageSourceName"
                           [identifier]="scanningResultButton.Identifier"
                           [loading]="true"
                           (clickEvent)="cancelBarcodeRequest()">
    </cybake-factory-button>
  </div>
</div>
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductionPlanProduct } from '../../models/production/plans/production-plan-product.model';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { ImageComponent } from '../../components/image/image.component';
import { CyBakeTagComponent } from '../../components/tag/tag.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cybake-factory-product-info',
  standalone: true,
  imports: [
    CyBakeButtonComponent,
    ImageComponent,
    CyBakeTagComponent,
    TranslateModule
  ],
  templateUrl: './product-info.component.html',
  styleUrl: './product-info.component.scss'
})
export class ProductInfoComponent {

  // Inputs/Outputs

  @Input() product!: ProductionPlanProduct;

  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

  // Services

  // Variables
  pageSourceName: string = 'productInfo'

}

export class ProductionPlan {
  Id!: number;
  Name!: string;
  Note?: string;
  StartTime!: string;
  TotalNumberOfLines!: number;
  NumberOfLinesCompleted!: number;
  Sequence!: number;

  Completed?: boolean = false;

  constructor(values: ProductionPlan) {
    Object.assign(this, values);
  }
}

import { Component, ElementRef, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '../../environments/environment';
import { StockTransfersService } from '../_shared/services/stock-transfers.service';
import { StockTransfersServiceMock } from '../_shared/services/mock-services/stock-transfers.service.mock.';
import { StockLocationComponent } from '../_shared/components-review/stock-location/stock-location.component';
import { AccordionModule } from 'primeng/accordion';
import { StockLocationType } from '../_shared/models/stock-transfers/stocks/stock-location-types.model';
import { AccordionComponent } from '../_shared/components-review/accordion/accordion.component';
import { ProductCardComponent } from '../_shared/components-review/product-card/product-card.component';
import { ProductServiceMock } from '../_shared/services/mock-services/product.service.mock';
import { ProductService } from '../_shared/services/product.service';
import { Product } from '../_shared/models/stock-transfers/product/product.model';
import { faker } from '@faker-js/faker';
import { LotsListComponent } from '../_shared/components-review/lots-list/lots-list.component';
import { TrolleyListComponent } from '../_shared/components-review/trolley-list/trolley-list.component';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { Trolley } from '../_shared/models/stock-transfers/product/trolley.model';
import { Lot } from '../_shared/models/stock-transfers/product/lot.model';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { LotService } from '../_shared/services/lot.service';
import {
  LotServiceMock,
  StockTransferOptions,
} from '../_shared/services/mock-services/lot.service.mock';
import { StockCountMenuItem } from '../_shared/models/menus/stockcount-menu-item';
import { MenuCardComponent } from '../_shared/components-review/menu-card/menu-card.component';
import { DataService } from '../_shared/services/data.service';
import { NavbarTitle } from '../_shared/models/navbar/navbar-title.model';
import { LotCardComponent } from '../_shared/components-review/lot-card/lot-card.component';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';
import { ButtonFloatEnum } from '../_shared/models/cybake/button/button-float.enum';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { CyBakeCardComponent } from '../_shared/components/card/card.component';
import { CommonModule } from '@angular/common';
import { DialogComponent } from '../_shared/components/dialog/dialog.component';
import { CybakeInputEmailComponent } from '../_shared/components/input-email/input-email.component';
import { SearchInputComponent } from '../_shared/components-review/search-input/search-input.component';
import { FilterPipe } from '../_shared/pipes/filter-by.pipe';
import { BarcodeScanComponent } from '../_shared/components/barcode-scan/barcode-scan.component';
import { DialogOptionsModel } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';
import { CyBakeConfirmationPrompTypeEnum } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';
import { StockLocation } from '../_shared/models/stock-transfers/stocks/stock-location.model';
import { Container } from '../_shared/models/stock-transfers/product/container.model';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { StockTransferLine, StockTransferType } from '../_shared/models/stock-transfers/stocks/stock-transfer.model';
import moment from 'moment';
import { Site } from '../_shared/models/sites/site.model';
import { ProductInfoComponent } from '../_shared/dialogs/product-info/product-info.component';
//import { OutputFunction } from '../_shared/models/common/output-function.model';



@Component({
  selector: 'cybake-factory-stock-transfers',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    StockLocationComponent,
    AccordionModule,
    ProductCardComponent,
    AccordionComponent,
    CybakeInputEmailComponent,
    LotsListComponent,
    CommonModule,
    TrolleyListComponent,
    CyBakeButtonComponent,
    ToastModule,
    MenuCardComponent,
    LotCardComponent,
    CyBakeCardComponent,
    DialogComponent,
    BarcodeScanComponent,
    SearchInputComponent,
    FilterPipe,
    InfiniteScrollModule,
    ProductInfoComponent
  ],
  providers: [MessageService, FilterPipe],
  templateUrl: './stock-transfers.component.html',
  styleUrl: './stock-transfers.component.scss',
})
export class StockTransfersComponent implements OnInit {
  // Services
  dataService: DataService = inject(DataService);
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  stockTransfersService: StockTransfersService | StockTransfersServiceMock =
    environment.mock
      ? inject(StockTransfersServiceMock)
      : inject(StockTransfersService);

  productService: ProductService | ProductServiceMock = environment.mock
    ? inject(ProductServiceMock)
    : inject(ProductService);

  lotService: LotService | LotServiceMock = environment.mock
    ? inject(LotServiceMock)
    : inject(LotService);

  // Variables
  pageSourceName: string = 'stock-transfers';
  stockLocationTypes: StockLocationType[] = [];
  selectedLocation!: StockLocationType;
  transferLocation!: number;
  displayPlanNote: boolean = true;
  showSearch: boolean = false;
  searchText: string = '';
  basketItems: Trolley[] | null = [];
  lots: Lot[] = [];
  lot!: Lot;
  filteredProducts: Product[] = [];
  products: Product[] = [];
  currentStage: number = 0;
  imageUrl = faker.image.dataUri({ width: 400, height: 400, color: 'silver' });
  selectedProduct!: Product;
  containers: Container[] = [];
  @ViewChild("scrollElement")
  scrollEl!: ElementRef;
  isPortraitMode: boolean = false;

  //Loading state
  loadingLocations: boolean = true;
  loading1: boolean = true;
  loading2: boolean = true;
  processing: boolean = false;

  displayedProducts: Product[] = [];  // Products that are currently displayed
  pageSize: number = 20;  // Number of products to load per scroll
  currentIndex: number = 0;  // Track the current index of displayed products
  isScrolling = false;
  displayProductInfo: boolean = false;
  stockTransferProductInfoDialog!: Product;



  //Flow config
  stockTransferOptions: StockTransferOptions = {
    isMultipleProduct: false,
  };
  displayConfirmPopup: boolean = false;

  menuItems: StockCountMenuItem[] = [
    {
      Id: 1,
      Name: 'Start a new Stock Transfer',
      Subtitle: '(This will remove any items already in your transfer)',
    },
    {
      Id: 2,
      Name: 'Continue the existing Stock Transfer',
      Subtitle: '',
      Count: 12,
    },
  ];

  navbarTitle: NavbarTitle = new NavbarTitle({
    Title: 'StockTransfer.StockTransferTitle',
    PrefixSubTitle: 'Production.SubPlanHeaderPrefix',
    SubTitleFromData: true,
  });


  //Buttons
  // Navigate back button
  navigateBackButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'productionNavigateBack',
    Class: ButtonClassEnum.transparent,
    IconKey: ['fa-regular', 'fa-chevron-left'],
    TranslationKey: 'Production.NavigateBackButton',
    IconClass: 'text-2xl',
  });

  // Complete button
  completeButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'complete',
    Class: ButtonClassEnum.default,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-check'],
    IconClass: 'text-3xl',
  });
  // cancel button
  cancelButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'complete',
    Class: ButtonClassEnum.danger,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-check'],
    IconClass: 'text-3xl',
  });

  // Barcode Button
  barcodeScannerButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'barcodeScanner',
    Class: ButtonClassEnum.default,
    Float: ButtonFloatEnum.bottomLeft,
    IconKey: ['fa-duotone', 'fa-scanner-gun'],
  });

  // Add button
  addMoreButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'add-button',
    Class: ButtonClassEnum.default,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-plus'],
    IconClass: 'text-3xl',

  });

  // Continue button
  continueButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'continue-button',
    Class: ButtonClassEnum.success,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-plus'],
    IconClass: 'text-3xl',
  });

  // Dialogs

  displayPopupOne: boolean = false;
  displayPopupTwo: boolean = true;
  displayBarcodeScanner: boolean = false;
  displayCancelTransferPopup: boolean = false;
  displayTransferConfirmPopup: boolean = false;
  displayNoLotsPopup: boolean = true;

  // Pagination

  paginatedProducts: Product[] = [];
  currentPage = 1;
  totalPages = 1;

  array: number[] = [];
  sum = 100;

  // Infinite scroll config
  scrollDistance = 2;
  scrollThrottle = 50;


  constructor() {

  }

  ngOnInit() {
    this.getStockLocationTypes();
    //this.getLot();
    //this.dataService.updateNavbarTitle(this.navbarTitle);
    this.setPageTitle();
    this.checkOrientation();
    this.lotService.basketItems$.subscribe((basketLot: Trolley[] | null) => {
      this.basketItems = JSON.parse(JSON.stringify(basketLot));
    });
    this.lotService.stockTransferOptions$.subscribe(
      (stockTransferOptions: StockTransferOptions | null) => {
        if (stockTransferOptions) {
          this.stockTransferOptions = stockTransferOptions;
        }
      },
    );
    //this.startFlow()
  }
  onScroll() {
    this.isScrolling = true;

    // Reset the isScrolling state after a delay (e.g., 1 second)
    setTimeout(() => {
      this.isScrolling = false;
    }, 1000);
  }
  //event listeners

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkOrientation();
  }

  checkOrientation() {
    this.isPortraitMode = window.innerHeight > window.innerWidth;
  }

  scrollToTop() {
    this.scrollEl.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }


  onScrollDown() {
    // add another 20 items
    const start = this.sum;
    this.sum += 20;
    for (let i = start; i < this.sum; ++i) {
      this.array.push(i);
    }
  }



  startFlow() {
    if (this.basketItems?.length) {
      this.displayPopupOne = false;
    } else {
      this.displayPopupTwo = false;
    }
    this.currentStage = 1;
    this.basketItems = [];
    this.lotService.updateBasketItemsSource(this.basketItems!);
    this.stockTransferOptions = {
      isMultipleProduct: false,
    };
    this.lotService.updateStockTransferOptions(this.stockTransferOptions);
    this.setPageTitle();
  }

  setMultipleProduct(isMultiple: boolean) {
    if (this.stockTransferOptions) {
      this.stockTransferOptions.isMultipleProduct = isMultiple;
      this.lotService.updateStockTransferOptions(this.stockTransferOptions);
      this.displayPopupTwo = false;
      this.currentStage = 4;// this.stockTransferOptions.isMultipleProduct ? 2 : 1;
      this.setPageTitle();
    }
  }

  startOver(clearAll = true, showPopup = true) {
    this.displayPopupOne = false;
    this.displayPopupTwo = showPopup ? true : false;
    this.currentStage = 1;
    if (clearAll) {
      this.basketItems = [];
      this.lotService.updateBasketItemsSource(this.basketItems!);
    }
    this.stockTransferOptions = {
      isMultipleProduct: false,
    };
    this.lotService.updateStockTransferOptions(this.stockTransferOptions);
    this.setPageTitle();
  }

  backFromStageTwo() {
    if (this.basketItems?.length) {
      this.displayPopupOne = true;
    } else {
      this.startOver(true, false);
    }
  }

  selectLocation(location: StockLocation) {

    this.stockTransferOptions.selectedLocation = location;

    this.getProducts(location.Id);
  }


  addNewProduct() {
    this.currentStage = 2;
    this.displayPopupOne = false;
    this.stockTransferOptions.isMultipleProduct = true;

    this.stockTransferOptions.selectedProduct = undefined;
    this.lotService.updateStockTransferOptions(this.stockTransferOptions);
    this.setPageTitle();
  }

  filterProducts() {
    const searchText = this.searchText.toLowerCase();

    if (!searchText.length) {
      this.filteredProducts = this.products;
      this.filteredProducts.sort((a, b) => a.Name.localeCompare(b.Name));
      this.totalPages = Math.ceil(this.filteredProducts.length / this.pageSize);
      this.paginatedProducts = this.filteredProducts;
      this.updatePaginatedProducts()

    }
    this.filteredProducts = this.products.filter(
      (product) =>
        product.Name.toLowerCase().includes(searchText) ||
        product.Code?.toLowerCase().includes(searchText),
    );
    if (this.filteredProducts.length === 0) {
      this.filteredProducts = [];
    } else {
      this.filteredProducts.sort((a, b) => a.Name.localeCompare(b.Name));
    }
    this.totalPages = Math.ceil(this.filteredProducts.length / this.pageSize);
    this.paginatedProducts = this.filteredProducts;
    this.updatePaginatedProducts()
  }



  onMenuClick(index: number) {
    if (index) {
      this.currentStage = 1;
    } else {
      this.currentStage = 1;
      this.basketItems = [];
      this.lotService.updateBasketItemsSource(this.basketItems!);
    }

    this.setPageTitle();
  }

  setPageTitle() {
    let title: string = 'StockTransfer.StockTransferTitle';
    let subtitle: string = '';

    if (this.currentStage == 1) {
      title = 'StockTransfer.SelectSource';
      subtitle = 'StockTransfer.StockTransferTitle'
    } else if (this.currentStage == 2) {
      title = 'StockTransfer.SelectProduct';
      subtitle = 'StockTransfer.StockTransferTitle'
    } else if (this.currentStage == 3) {

      title = 'Select amount to transfer';
      subtitle = 'StockTransfer.StockTransferTitle'
    } else if (this.currentStage == 4) {
      title = 'Products ready to transfer';
      subtitle = 'StockTransfer.StockTransferTitle'
    } else if (this.currentStage == 5) {
      title = 'StockTransfer.SelectDestination';
      subtitle = 'Stock transfer - ' + this.basketItems?.length + ' ' + (this.basketItems && this.basketItems?.length > 1 ? 'products' : 'product');
    } else {
      title = 'StockTransfer.StockTransferTitle';
    }

    //this.dataService.updateNavbarTitle(this.navbarTitle);
    this.navbarTitle.Title = title;
    this.navbarTitle.SubTitle = subtitle;
    this.navbarTitle.SubTitleFromData = false;
    //console.log(title)
    this.dataService.updateNavbarTitle(this.navbarTitle);
  }

  getStockLocationTypes() {
    this.stockTransfersService.getStockLocations().subscribe({
      next: (getStockLocationResponse: StockLocationType[]) => {
        this.stockLocationTypes = getStockLocationResponse;
        this.loadingLocations = false;
      },
      error: () => {
        this.loadingLocations = false;
      },
    });
  }

  addMoreProduct() {
    this.currentStage = 2;
    this.stockTransferOptions.selectedProduct = undefined;
  }

  backToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  deleteAll() {
    this.basketItems = [];
    this.lotService.updateBasketItemsSource(this.basketItems);
    this.stockTransferOptions = {
      isMultipleProduct: false,
    };

    this.lotService.updateStockTransferOptions(this.stockTransferOptions);
  }
  // ---------------------- Dialogs ----------------------

  openBarcodeScanner() {
    this.displayBarcodeScanner = true;
  }


  handleValueChange(lot: Lot) {
    // Create a new lot object
    const newLot: Lot = {
      Id: lot.Id,
      StockLotId: lot.StockLotId,
      SystemLotNumber: lot.SystemLotNumber,
      ExpiryDate: lot.ExpiryDate,
      Quantity: lot.Quantity,
      Measure: this.stockTransferOptions.selectedProduct?.Measure,
      Weight: this.stockTransferOptions.selectedProduct?.Measure === 'singles' ? ((this.stockTransferOptions?.selectedProduct?.Quantity ?? 0) * (lot?.Quantity ?? 1)) : lot.Weight,
      ContainerId: lot.ContainerId,
      ContainerName: lot.ContainerName,
      ProductId: lot.ProductId,
      UniqueIdentifier: lot.UniqueIdentifier ?? lot.ProductId + 'Single'
    };


    // Check if the product already exists in the cart
    const existingProduct =
      this.basketItems &&
      this.basketItems!.find(
        (item) => item.ProductId == this.stockTransferOptions.selectedProduct?.Id,
      );

    if (existingProduct) {
      const index = existingProduct.Lots.findIndex(
        (p) =>
          p.UniqueIdentifier === lot.UniqueIdentifier
        //p.SystemLotNumber == lot.SystemLotNumber && p.ContainerName == lot.ContainerName,
      );

      const productIndex = this.basketItems!.findIndex(
        (p) =>
          p.ProductId === this.stockTransferOptions.selectedProduct?.Id,
      );

      if (index > -1) {
        existingProduct.Lots[index] = newLot;
      } else {

        // If the product exists, add the new lot to the existing lots array
        existingProduct.Lots.push(newLot);
        //console.log('existingProduct2', existingProduct)

      }

      //if (productIndex > -1 && !lot.Quantity) {
      //  existingProduct!.Lots.splice(index, 1)
      //}


      if (!existingProduct!.Lots.length) {
        this.basketItems!.splice(productIndex, 1)
      } else {
        this.basketItems![productIndex] = existingProduct;
      }

      this.lotService.updateBasketItemsSource(this.basketItems!);


      const options: DialogOptionsModel = new DialogOptionsModel({
        Type: CyBakeConfirmationPrompTypeEnum.success,
        Title: 'Item successfully updated',
        PageSourceName: this.pageSourceName,
        Identifier: 'confrimCompletedPlanProduct',
        AutoClose: true,
      });

      this.dataService.openConfirmationPrompt(options);
    } else {
      //this.stockTransferOptions.selectedProduct
      // If the product does not exist, create a new basket item
      const basketItem: Trolley = {
        Id: 1,
        Name: this.stockTransferOptions.selectedProduct?.Name || '',
        ImageUrl: this.stockTransferOptions.selectedProduct?.ImageUrl || '',
        Measure:
          this.stockTransferOptions.selectedProduct?.Measure,
        LocationName: lot.SystemLotNumber || '',
        LotNumber: this.stockTransferOptions.selectedProduct?.Code,
        IsTraceable: this.stockTransferOptions.selectedProduct?.IsTraceable,
        Expiry: lot.ExpiryDate,
        Weight: lot.Weight || 0,
        ProductId: this.stockTransferOptions.selectedProduct?.Id,
        Lots: [newLot], // Initialize with the new lot
      };
      // Add the new basket item to the cart
      //RM - NGLINT FAILING REMOVED 'this.basketItems && ' FROM LINE BELOW, NO IDEA WHY ITS THERE?
      this.basketItems!.push(basketItem);
      this.lotService.updateBasketItemsSource(this.basketItems! || []);


      const options: DialogOptionsModel = new DialogOptionsModel({
        Type: CyBakeConfirmationPrompTypeEnum.success,
        Title: 'Item successfully added',
        PageSourceName: this.pageSourceName,
        Identifier: 'confrimCompletedPlanProduct',
        AutoClose: true,
      });

      this.dataService.openConfirmationPrompt(options);
    }

  }



  viewProductInfo(product: Product | undefined, img: string = "") {
    if (product) {
      product.ImageUrl = img;
      this.stockTransferProductInfoDialog = product;
    }
    this.displayProductInfo = true;
  }


  selectProduct(product: Product) {
    //console.log('product', product)
    this.stockTransferOptions.selectedProduct = product;

    this.lotService.updateStockTransferOptions(this.stockTransferOptions);
    this.getLots(product.Id)
    this.getContainers(product.Id)
  }


  // complete transfer and restart flow
  completeTransfer(clearAll = true): void {
    this.currentStage = 0;
    this.displayTransferConfirmPopup = false;
    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.success,
      Title: 'Product transfer completed',
      PageSourceName: this.pageSourceName,
      Identifier: 'confrimCompletedPlanProduct',
      AutoClose: true,
    });


    this.dataService.openConfirmationPrompt(options);
    //log clear all
    if (!this.basketItems?.length) {
      setTimeout(() => {
        this.startOver(clearAll);
      }, 1000);
    }
  }
  get returnBasketItemsLength(): number {
    // Filter the stock transfer lines based on the destination location's rules
    const lots: Lot[] | undefined = this.basketItems?.map(product => {
      return product.Lots.map(lot => {
        return lot;
      })
    }).flat().filter(line => line.ProductId); // Filter out empty lines

    return lots?.length ?? 0;
  }

  get returnFilteredItems(): StockTransferLine[] | undefined {
    // Get selected destination location
    const destinationLocation = this.stockTransferOptions.selectedDestination;
    // Check if destination allows all products or only specific ones
    const allowAllProducts = destinationLocation?.AllProducts;
    const allowedProductIds = destinationLocation?.StockLocationProductIds || [];

    // Filter the stock transfer lines based on the destination location's rules
    const stockTransferLines: StockTransferLine[] | undefined = this.basketItems?.map(product => {
      // Check if the product is allowed to be transferred
      const isProductAllowed = allowAllProducts || allowedProductIds.includes(product.ProductId || 0);
      //console.log('returnFilteredItems> ',product)
      if (isProductAllowed && product?.Measure !== 'singles') {
        return product.Lots.map(lot => ({
          ProductId: product.ProductId,
          Name: product.Name,
          Measure: lot.Measure,
          Weight: product.Weight,
          StockLotId: lot.StockLotId,
          TransferQuantity: lot.Quantity,
          ProductToContainerId: lot.ContainerId
        }));
      }

      return []; // Return an empty array if the product is not allowed
    }).flat().filter(line => line.ProductId); // Filter out empty lines

    return stockTransferLines;

  }

  doStockTransfer(): void | boolean {
    this.processing = true;
    // Get selected destination location
    const destinationLocation = this.stockTransferOptions.selectedDestination;

    // Filter the stock transfer lines based on the destination location's rules
    const stockTransferLines: StockTransferLine[] | undefined = this.returnFilteredItems

    const transferDateTime = moment().toISOString(); // Current date and time in the required format

    if (!stockTransferLines?.length) {
      return false;
    }

    const site: Site = JSON.parse(localStorage.getItem('deviceSite')!);

    const payload: StockTransferType = {
      SiteId: site.Id,
      "SourceLocationId": this.stockTransferOptions.selectedLocation?.Id || 0,
      "DestinationLocationId": this.stockTransferOptions.selectedDestination?.Id || 0,
      "TransferDate": transferDateTime,
      "TransferTime": transferDateTime,
      "StockTransferLines": stockTransferLines || []
    };

    // Send the stock transfer request
    this.stockTransfersService.createStockTransferRequest(payload).subscribe({
      next: () => {
        // Clear the basket items after preparing the payload
        this.basketItems = this.basketItems?.filter(product => {
          // Remove products from the basket that were successfully added to the transfer
          return !stockTransferLines?.some(line => line.ProductId === product.ProductId);
        }) || [];


        this.lotService.updateBasketItemsSource(this.basketItems!);
        this.processing = false;
        if (!this.basketItems.length) {
          this.completeTransfer(destinationLocation?.AllProducts);
        } else {
          this.displayTransferConfirmPopup = false
          this.currentStage = 4;
          this.stockTransferOptions.selectedDestination = undefined;
          this.lotService.updateStockTransferOptions(this.stockTransferOptions);
        }
      },
      error: () => {
        this.processing = false;


        const options: DialogOptionsModel = new DialogOptionsModel({
          Type: CyBakeConfirmationPrompTypeEnum.error,
          Title: 'Stock Tranfer Failed',
          PageSourceName: this.pageSourceName,
          Identifier: 'stockTranferFailed',
          AutoClose: true,
        });

        this.dataService.openConfirmationPrompt(options);
        // Handle error
      },
    });
  }

  showLotUnavailablePopup() {
    const options: DialogOptionsModel = new DialogOptionsModel({
      Type: CyBakeConfirmationPrompTypeEnum.error,
      Title: 'Product has no lots, please contact your head office to get lots added',
      PageSourceName: this.pageSourceName,
      Identifier: 'stockTranferFailed',
      AutoClose: true,
    });

    this.dataService.openConfirmationPrompt(options);
  }

  updatePaginatedProducts(): void {
    //console.log(9999)
    const nextIndex = this.currentIndex + this.pageSize;
    const newProducts = this.filteredProducts.length ? this.filteredProducts.slice(this.currentIndex, nextIndex) : this.products.slice(this.currentIndex, nextIndex);
    this.paginatedProducts = [...this.paginatedProducts, ...newProducts];
    this.currentIndex = nextIndex;

    this.isScrolling = true;

    // Reset the isScrolling state after a delay (e.g., 1 second)
    setTimeout(() => {
      this.isScrolling = false;
    }, 1000);
  }

  goToPage(page: number): void {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.updatePaginatedProducts();
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePaginatedProducts();
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedProducts();
    }
  }


  // ---------------------- Services  ----------------------

  getLots(id: number) {
    this.lotService.getLots(id).subscribe({
      next: (getLotsResponse: Lot[]) => {
        this.lots = getLotsResponse;
        //console.log('this.lots', this.lots)
      },
      error: () => { },
    });
  }

  getProducts(id: number | undefined) {
    this.productService.getProducts(id || 0).subscribe({
      next: (getProductResponse: Product[]) => {
        this.products = [];
        this.currentIndex = 0;
        this.paginatedProducts = [];
        this.products = getProductResponse;
        this.products.sort((a, b) => a.Name.localeCompare(b.Name));
        this.totalPages = Math.ceil(this.products.length / this.pageSize);
        this.updatePaginatedProducts();
        this.loading1 = false;
      },
      error: () => {
        this.loading1 = false;
      },
    });
  }

  getContainers(id: number) {
    // check if product has containers using id and return them

    const containerExists = this.containers.some(
      (container) => container.ProductId === id
    );

    if (containerExists) {
      return; // Exit early if the container already exists
    }

    this.loading2 = true;

    this.lotService.getContainers(id).subscribe({
      next: (getContainerResponse: Container[]) => {
        //foreach container set unique identifier
        getContainerResponse.forEach((container) => {
          container.UniqueIdentifier = container.ProductToContainerId ? container.ProductToContainerId : id + (container?.ContainerDisplayName ?? '');
          //container.UniqueIdentifier = this.generateUnique8DigitNumber();
          container.ProductId = id
        });
        this.containers = getContainerResponse;

        //console.log('this.containers', this.containers)
        //console.log('this.lot', this.lot)
        this.loading2 = false;
      },
      error: () => {
        this.loading2 = false;
      },
    });
  }

  getCommaSeparatedProductNames(products: StockTransferLine[] | undefined): string {
    if (!products || products.length === 0) {
      return '';
    }

    // Create a Set from the product names to remove duplicates
    const uniqueProductNames = Array.from(new Set(products.map(product => product.Name)));

    // Return the comma-separated list of unique product names
    return uniqueProductNames.join(', ');
  }

  //getCommaSeparatedProductNames(products: StockTransferLine[] | undefined): string {
  //  if (!products || products.length === 0) {
  //    return '';
  //  } else if (products.length === 1) {
  //    return products[0].Name;
  //  }
  //  return products.map(product => product.Name).join(', ');
  //}

  calculateTotalWeight(products: StockTransferLine[] | null): string {
    if (!products || products.length === 0) {
      return '';
    }
    const measure: string = products[0].Measure || '';
    return products.reduce((sum, product) => sum + (product.Weight || 0), 0) + measure;
  }

  generateUnique8DigitNumber(): number {
    return Math.floor(10000000 + Math.random() * 90000000);
  }

  calculateTotalWeight2(): number | undefined {
    //return array.Lots.reduce((total, lot) => total + (lot.Weight || 0), 0);
    return this.basketItems?.reduce((totalWeight, trolley) => {
      const trolleyWeight = trolley.Lots.reduce((lotWeight, lot) => {
        return lot?.Measure === 'singles' ? lotWeight + (lot.Weight ? lot.Weight : 0) : 0; // Use 0 if Weight is undefined
      }, 0);
      return (totalWeight + trolleyWeight);
    }, 0);
  }

  returnLots(): Trolley | undefined {
    if (!this.basketItems || (this.basketItems && !this.basketItems.length)) {
      return undefined;
    }
    const indexedProduct = this.basketItems.find((item) => item.ProductId === this.stockTransferOptions?.selectedProduct?.Id);
    //console.log('indexedProduct', indexedProduct);

    const lot: Trolley | undefined = indexedProduct ? indexedProduct : undefined;
    return lot;
  }

  returnLotContainer(): Lot {
    if (!this.basketItems || (this.basketItems && !this.basketItems.length)) {
      return {};
    }
    const indexedProduct = this.basketItems.find((item) => item.ProductId === this.stockTransferOptions?.selectedProduct?.Id);
    //console.log('indexedProduct', indexedProduct);

    const lot: Trolley | undefined = indexedProduct ? indexedProduct : undefined;
    return lot?.Lots[0] || {};
  }

}

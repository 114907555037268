import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByBoolean',
  standalone: true,
  pure: false,
})
export class FilterByBooleanPipe<T extends Record<string,boolean>>
  implements PipeTransform
{
  transform(
    items: T[],
    booleanValue: boolean,
    field: keyof T,
  ): T[] {

    if (!items) return [];
    if (booleanValue === null || booleanValue === undefined) return items;

    return items.filter((item: T) => {
      const fieldValue = item[field];
      if (fieldValue !== null || fieldValue !== undefined) {
        if (fieldValue === booleanValue) {
          return true;
        }
      }
      return false;
    });
  }
}

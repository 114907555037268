import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { SiteService } from '../_shared/services/site.service';
import { SiteServiceMock } from '../_shared/services/mock-services/site.service.mock';
import { environment } from '../../environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { CyBakeListbox } from '../_shared/models/cybake/listbox/listbox.model';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';
import { Site } from '../_shared/models/sites/site.model';
import { forkJoin } from 'rxjs';
import * as _ from 'underscore';
import { ListboxComponent } from '../_shared/components/listbox/listbox.component';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';

@Component({
  selector: 'cybake-factory-user-location',
  standalone: true,
  imports: [TranslateModule, ListboxComponent, CyBakeButtonComponent],
  templateUrl: './user-location.component.html',
  styleUrl: './user-location.component.scss',
})
export class UserLocationComponent implements OnInit {
  // Inputs/Outputs
  @Input() currentSite?: Site;

  @Output() comfirmSite = new EventEmitter<Site>();

  // Services
  translateService: TranslateService = inject(TranslateService);
  messageService: MessageService = inject(MessageService);
  siteService: SiteService | SiteServiceMock = environment.mock
    ? inject(SiteServiceMock)
    : inject(SiteService);

  // Variables
  pageSourceName: string = 'locations';

  // Sites List Box
  sitesListBox: CyBakeListbox = new CyBakeListbox({
    PageSourceName: this.pageSourceName,
    Identifier: 'sites',
    Options: [],
    Value: null,
    OptionValue: 'Id',
    OptionLabel: 'Name',
    RadioButtons: true,
    Loading: true,
  });

  // Confirm Site Button
  confirmButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'confirmSite',
    TranslationKey: 'UserLocation.ComfirmLocationButton',
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.default,
    Loading: false,
  });

  ngOnInit() {
    if (this.currentSite) {
      this.sitesListBox.Value = this.currentSite.Id;
    }

    this.getSites();
  }

  getSites() {
    this.sitesListBox.Loading = true;
    this.sitesListBox.Failed = false;

    this.siteService.getSites().subscribe({
      next: (getSitesResponse: Site[]) => {
        getSitesResponse = _.sortBy(getSitesResponse, 'Sequence');
        this.sitesListBox.Options = getSitesResponse;
        this.sitesListBox.Loading = false;
      },
      error: () => {
        forkJoin([
          this.translateService.get('Login.FailedLoginMsg'),
          this.translateService.get('Login.FailedLoginMsg'),
        ]).subscribe((translations: string[]) => {
          this.messageService.add({
            severity: 'error',
            detail: translations[0],
            summary: translations[1],
          });
        });

        this.sitesListBox.Loading = false;
        this.sitesListBox.Failed = true;
      },
    });
  }

  comfirmLocation() {
    const site: Site = _.find(this.sitesListBox.Options, (site: Site) => {
      return site.Id === this.sitesListBox.Value;
    }) as Site;

    localStorage.setItem('deviceSite', JSON.stringify(site));

    this.comfirmSite.emit(site);
  }
}

<div id="loginPin_div" class="login-background-image h-full">
  <div
    class=""
    style="
      border-bottom-right-radius: 3rem;
      background-color: #e84e0f;
      height: 12%;
    "
  >
    <div class="vertically-center">
      <div class="inline-flex">
        <img
          src="assets/images/cybake_bean_white.png"
          alt="{{ 'General.CyBakeBeanAltText' | translate }}"
          style="height: 42px; padding-right: 15px"
        />
        <img
          src="assets/images/cybake_white.png"
          alt="{{ 'General.CyBakeLogoAltText' | translate }}"
          style="height: 50px"
        />
      </div>
    </div>
  </div>

  <div
    class="bg-white p-4 pt-3 login-background"
    style="
      border-top-left-radius: 3rem;
      height: 88%;
      border-top-right-radius: 3rem;
      overflow-y: auto;
    "
    [ngClass]="{ 'padding-top-selected-user': user }"
  >
    @if (!user) {
      <div class="grid grid-cols-2 gap-4 m-0">
        @for (user of users; track user.UserId) {
          <cybake-factory-login-profile
            [user]="user"
            [showDetails]="true"
            (selectUserEvent)="selectUser($event)"
          ></cybake-factory-login-profile>
        }
        <cybake-factory-login-profile
          [placeholderProfile]="true"
          (selectUserEvent)="navigateToLogin()"
        ></cybake-factory-login-profile>
      </div>
    }

    @if (user) {
      <div class="w-full inline-flex">
        <div class="col-2 pt-0 pb-0 pt-2 pl-0">
          <cybake-factory-button
            [pageSourceName]="pageSourceName"
            [identifier]="backToExistingUsersButton.Identifier"
            [class]="backToExistingUsersButton.Class"
            [iconKey]="backToExistingUsersButton.IconKey"
            [iconClass]="backToExistingUsersButton.IconClass"
            (clickEvent)="user = undefined"
          ></cybake-factory-button>
        </div>

        <div class="col-8 p-0 pb-1">
          <cybake-factory-login-profile
            [user]="user"
            [showDetails]="false"
          ></cybake-factory-login-profile>
        </div>

        <div class="col-2 pt-0 pb-0"></div>
      </div>

      <div class="w-full text-center">
        <div class="">{{ user.DisplayName }}</div>
        <div class="text-xs">{{ user.LastLogin }}</div>
      </div>

      <div class="w-full text-center pt-4 pb-4">
        <div class="inline-flex">
          <div
            class="pin-indicator mr-2"
            [ngClass]="{ 'entered-pin': pin.length > 0 }"
          ></div>
          <div
            class="pin-indicator ml-2 mr-2"
            [ngClass]="{ 'entered-pin': pin.length > 1 }"
          ></div>
          <div
            class="pin-indicator ml-2 mr-2"
            [ngClass]="{ 'entered-pin': pin.length > 2 }"
          ></div>
          <div
            class="pin-indicator ml-2"
            [ngClass]="{ 'entered-pin': pin.length > 3 }"
          ></div>
        </div>
      </div>

      <div class="grid m-0 grid-cols-3 gap-4">
        <!--Pin Buttons 1, 2 and 3-->

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'1'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(1)"
          >
          </cybake-factory-button>
        </div>

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'2'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(2)"
          >
          </cybake-factory-button>
        </div>

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'3'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(3)"
          >
          </cybake-factory-button>
        </div>

        <!--Pin Buttons 4, 5 and 6-->

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'4'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(4)"
          >
          </cybake-factory-button>
        </div>

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'5'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(5)"
          >
          </cybake-factory-button>
        </div>

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'6'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(6)"
          >
          </cybake-factory-button>
        </div>

        <!--Pin Buttons 7, 8 and 9-->

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'7'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(7)"
          >
          </cybake-factory-button>
        </div>

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'8'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(8)"
          >
          </cybake-factory-button>
        </div>

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'9'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(9)"
          >
          </cybake-factory-button>
        </div>

        <div>
          <div class="vertically-center p-2 text-center default-colour p-2">
            Forgot PIN
          </div>
        </div>

        <div class="m-auto">
          <cybake-factory-button
            [translationKey]="'0'"
            [customClass]="'pin-number-button'"
            [disabled]="loggingIn"
            (clickEvent)="pinButton(0)"
          >
          </cybake-factory-button>
        </div>

        <div>
          <div class="vertically-center">
            <i
              class="fa-icon fa-solid fa-delete-left text-3xl default-colour"
            ></i>
          </div>
        </div>
      </div>
    }
  </div>
</div>

export const environment = {
  production: true,
  envName: 'test',
  mock: false,
  authApi: 'https://verification.cybakeinstore-test.co.uk',
  api: 'https://api-factory.cybakeonline-test.co.uk/',
  version: '0.0.__Version__',
  releaseDate: '__ReleaseDate__',
  clientId: 'CyBakeFactoryApp',
};

import { Component } from '@angular/core';

@Component({
  selector: 'cybake-factory-despatch',
  standalone: true,
  imports: [],
  templateUrl: './despatch.component.html',
  styleUrl: './despatch.component.scss',
})
export class DespatchComponent {}

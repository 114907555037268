import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { OutputFunction } from '../../models/common/output-function.model';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { Subscription, forkJoin } from 'rxjs';
import { DataService } from '../../services/data.service';
import { ToastMessage } from '../../models/common/toast-message.model';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cybake-factory-toast',
  standalone: true,
  imports: [
    ToastModule,
    CommonModule
  ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class CyBakeToastComponent implements OnInit {

  // Inputs/Outputs
  @Input() title!: string;
  @Input() message!: string;
  @Input() severity: number = 0;
  @Input() closable: boolean = false;
  @Input() iconKey?: string[];

  @Input() buttons: CyBakeButton[] = [];

  @Input() closeMethod!: string;
  @Input() retryMethod!: string;

  @Output() closeEvent = new EventEmitter<OutputFunction>();
  @Output() retryEvent = new EventEmitter<OutputFunction>();

  // Services
  dataService: DataService = inject(DataService);
  messageService: MessageService = inject(MessageService);
  translateService: TranslateService = inject(TranslateService);

  // Subscriptions
  subscriptionOpenToast!: Subscription;

  // Variables
  popupMode: boolean = false;

  ngOnInit() {
    this.subscriptionOpenToast = this.dataService.openToastState.subscribe((toast: ToastMessage) => {
      forkJoin([
        this.translateService.get(toast.Summary!),
        this.translateService.get(toast.Detail!),
      ]).subscribe((translations: string[]) => {
        this.messageService.add({
          key: 'cybakeToast',
          severity: toast.Severity.toString(),
          detail: translations[0],
          summary: translations[1],
          sticky: true
        });

      });
    });
  }

}

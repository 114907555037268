import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { ListboxModule } from 'primeng/listbox';
import { ListboxComponent } from '../../components/listbox/listbox.component';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { ButtonTypeEnum } from '../../models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../models/cybake/button/button-class.enum';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { Container } from '../../models/stock-transfers/product/container.model';

@Component({
  selector: 'cybake-factory-popup-select',
  standalone: true,
  imports: [
    FormsModule,
    DialogComponent,
    DropdownModule,
    DialogModule,
    CyBakeButtonComponent,
    ListboxModule,
    ListboxComponent

  ],
  templateUrl: './popup-select.component.html',
  styleUrl: './popup-select.component.scss',
})
export class PopupSelectComponent implements OnInit {
  @Input() options!: Container[];
  @Input() header: string = '';
  @Input() selectedOptionValue: string | number | undefined = '';
  @Input() optionValue: string = '';
  @Input() optionLabel: string = '';
  @Output() valueChange: EventEmitter<Container> = new EventEmitter<Container>();
  @Input() pageSourceName: string = '';


  // Confirm Site Button
  confirmButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'confirmSite',
    TranslationKey: 'UserLocation.ComfirmLocationButton',
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.default,
    Loading: false,
  });

  selectedOption!: Container | undefined;
  selectedValue: string | number | boolean | undefined = '';
  visible: boolean = false;

  ngOnInit(): void {

    this.selectedOption = this.options.find(x => x[this.optionValue] == this.selectedOptionValue);
  }

  getDisplay(): string | undefined | number | boolean {
    return this.selectedOption ? this.selectedOption[this.optionLabel] : 'Select container';
  }

  setDefaultOption(): void {
    const defaultOption: Container = this.options[0];

    if (defaultOption) {
      this.selectedValue = defaultOption[this.optionLabel];
    }
  }


  selectValueChange() {
    console.log(this.selectedOption);
    this.valueChange.emit(this.selectedOption);

    this.visible = false;
  }
}

import { OutputFunction } from "../common/output-function.model";

export class NavbarTitle {
  Title!: string;
  TitleFromData?: boolean = false;
  PrefixSubTitle?: string;
  SubTitle?: string;
  SubTitleFromData?: boolean =  false;
  OutputFunction?: OutputFunction;

  constructor(values: NavbarTitle) {
    Object.assign(this, values);
  }
}

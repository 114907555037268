import {
    ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { CounterComponent } from '../counter/counter.component';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { Lot } from '../../models/stock-transfers/product/lot.model';
import { PopupSelectComponent } from '../popup-select/popup-select.component';
import { TranslateService } from '@ngx-translate/core';
import { LotService } from '../../services/lot.service';
import {
  LotServiceMock,
  StockTransferOptions,
} from '../../services/mock-services/lot.service.mock';
import { environment } from '../../../../environments/environment';
import { Container } from '../../models/stock-transfers/product/container.model';
import { NgTemplateOutlet } from '@angular/common';
import { Trolley } from '../../models/stock-transfers/product/trolley.model';
import { CyBakeInputNumberComponent } from '../../components/input-number/input-number.component';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../models/cybake/button/button-class.enum';

@Component({
  selector: 'cybake-factory-lot-card',
  standalone: true,
  imports: [
    AccordionModule,
    CounterComponent,
    CyBakeButtonComponent,
    PopupSelectComponent,
    NgTemplateOutlet,
    CyBakeInputNumberComponent,
  ],
  templateUrl: './lot-card.component.html',
  styleUrl: './lot-card.component.scss',
})

export class LotCardComponent implements OnChanges, OnInit {
  translateService: TranslateService = inject(TranslateService);
  lotService: LotService | LotServiceMock = environment.mock
    ? inject(LotServiceMock)
    : inject(LotService);

  @Input() containers: Container[] = [];
  @Input() basketLot!: Trolley | undefined;
  @Input() lot!: Lot;
  @Input() pageSourceName: string = '';
  @Output() transferEvent: EventEmitter<Lot> = new EventEmitter<Lot>();

  @ViewChild(CounterComponent) counterComponent!: CounterComponent;
  selectedValue: Container | undefined = undefined;
  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<unknown>;
  filtered!: Trolley[];
  basketItems: Trolley[] | null = null;
  count: number = 0;
  stockTransferOptions!: StockTransferOptions;
  // cancel button
  cancelButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'complete',
    Class: ButtonClassEnum.danger,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-check'],
    IconClass: 'text-3xl',
  });

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }
  ngOnInit() {
    this.lotService.basketItems$.subscribe((basketLot) => {
      this.basketItems = basketLot;
    });

    const filtered = this.basketLot?.Lots?.find(
      (item: Lot) => this.lot?.Id ? (item.Id == this.lot.Id && item.ContainerId == this.selectedValue?.ProductToContainerId) : (item?.ContainerName == this.lot?.ContainerName),
    );

    if (this.containers.length && !this.selectedValue) {
      this.selectedValue = this.containers[0];
    }

    //console.log('this.lot?.Id', this.selectedValue)
    //console.log('filtered', filtered)

    if (filtered) {
      if (
        this.lot?.Id?(this.lot?.Id &&
        this.basketLot?.ProductId == this.stockTransferOptions?.selectedProduct?.Id &&
        this.lot?.Id == filtered?.Id &&
          this.selectedValue?.ProductToContainerId == filtered?.ContainerId) : (this.lot?.ContainerName && filtered?.ContainerName)
      ) {
        this.count = filtered.Quantity || 0;
      } else {
        this.count = 0;
      }

    }
   
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changeDetectorRef.detectChanges();


    this.lotService.stockTransferOptions$.subscribe(
      (stockTransferOptions: StockTransferOptions | null) => {
        if (stockTransferOptions) {
          this.stockTransferOptions = stockTransferOptions;
        }
      },
    );
    console.log('changes', changes)
    if (changes['basketLot'] && changes['basketLot'].currentValue) {
      //console.log('basketLot', this.lot);
      const filtered = this.basketLot?.Lots?.find(
        (item: Lot) => {
          (this.lot?.Id  && item.SystemLotNumber == this.lot?.SystemLotNumber) ||
            (item.ContainerName === "Singles" && this.stockTransferOptions?.selectedProduct?.Id == item?.ProductId)


          console.log('this.lot?.Id??>>', this.lot?.Id);
          console.log('item??>>', this.stockTransferOptions?.selectedProduct?.Id);
          console.log('item??>>', item?.ProductId);
        }
      );
      console.log('this.lot?.Id', this.lot?.Id);
      console.log('filtered', filtered);
      console.log('this.stockTransferOptions?.selectedProduct?', this.stockTransferOptions);
      if ((this.lot?.SystemLotNumber &&
        filtered &&
        filtered.ProductId == this.stockTransferOptions?.selectedProduct?.Id &&
        this.lot?.SystemLotNumber == filtered.SystemLotNumber &&
        this.lot?.ContainerName == filtered.ContainerName &&
        filtered?.ProductId == this.stockTransferOptions?.selectedProduct?.Id)
      ) {
        this.count = filtered?.Quantity || 0;
      } else if (filtered?.ContainerName === "Singles" && filtered.ProductId == this.stockTransferOptions?.selectedProduct?.Id) {
        this.count = filtered?.Quantity || 0;
      }
    }
  }

  checkCount() {
    const filtered = this.basketItems?.find(
      (item: Trolley) =>
        item.ProductId == this.stockTransferOptions?.selectedProduct?.Id,
    );

    const foundLot =
      filtered &&
      filtered.Lots
        .find(
          (lot: Lot) =>
            lot?.Id === this.lot?.Id &&
            lot?.ContainerId === this.lot?.ContainerId,
        );

    this.count = (foundLot ? foundLot.Quantity : 0) || 0;
  }

  inCart() {
    let status = false;

    status = this.doesObjectExist(this.basketItems!, this.lot);

    return status;
  }

  doesObjectExist(array: Trolley[], obj: Lot) {
    //console.log('array', this.selectedValue);
    return array?.some((item) => {
      return item.Lots.some((lot) => {
        return (
          lot?.Id ? lot?.Id === obj?.Id &&
          lot?.ContainerId === this.selectedValue?.ProductToContainerId : lot?.ContainerName === obj?.ContainerName && this.selectedValue?.Measure === 'singles'
        );
      });
    });
  }

  removeLotIfExists(array: Trolley[], obj: Lot) {
    return array.map((item) => {
      const newLots = item.Lots.filter(
        (lot) =>
          !(
            lot.Id === obj.Id &&
            lot.ContainerId === this.selectedValue?.ProductToContainerId
          ),
      );
      return { ...item, Lots: newLots };
    });
  }

  getLotQuantity(array: Trolley[], obj: Lot) {
    if (!array || array.length) {
      return 0;
    }
    for (const item of array) {
      for (const lot of item.Lots) {
        if (
          obj.ContainerId? (lot.Id === obj.Id &&
          lot.ExpiryDate === obj.ExpiryDate &&
            lot.ContainerId === obj.ContainerId) : (lot.ContainerName === obj.ContainerName)
        ) {
          console.log(lot);

          return lot.Quantity;
        }
      }
    }
    return 0; // Return null if the lot does not exist
  }  
  handleValueChange(value: Container) {

    console.log('value', this.lot);
    this.selectedValue = value;
    this.lot.ContainerId = this.selectedValue.ProductToContainerId;
    this.lot.ContainerName = this.selectedValue.ContainerDisplayName;
  }

  handleCountValueChange(value: number) {
    this.count = value;
  }

  removeItem() {
    const updatedItems = this.removeLotIfExists(this.basketItems!, this.lot);
    this.lotService.updateBasketItemsSource(updatedItems);
    //if (this.inCart()) {
      this.transferEvent.emit(this.lot);
    //}
  }

  onTransferClicked() {
    const lot: Lot = {
      Id: this.lot?.Id ?? undefined,
      StockLotId: this.lot?.Id ?? undefined,
      SystemLotNumber: this.lot?.SystemLotNumber ?? '',
      ExpiryDate: this.lot?.ExpiryDate ?? '',
      ContainerId: this.selectedValue?.ProductToContainerId ?? undefined,
      Weight: this.selectedValue?.Weight ?(this.selectedValue?.Weight * this.count) : 0,
      ContainerName: this.selectedValue?.ContainerDisplayName ?? 'Single',
      Quantity: this.count,
      ProductId: this.stockTransferOptions?.selectedProduct?.Id
    }

    this.lot = lot;

    if (this.count == 0 && this.inCart()) {
      this.removeItem();
    } else {
      this.transferEvent.emit(this.lot);
    }
  }
}

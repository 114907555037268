
<div class="flex flex-row align-items-center justify-content-between bg-white border-1 rounded-lg border-200 px-2">
  @if(lot && lot?.SystemLotNumber) {
  <p cybake-text-style="body-small" class="w-3/12 truncate">{{ lot?.SystemLotNumber }}</p>
  }
  <!--@else {
    <div class="w-2/12"></div>
  }-->
  <p cybake-text-style="body-small" class="w-3/12 truncate {{lot && lot?.SystemLotNumber  && 'text-center' }}">{{ lot?.ContainerName }}</p>
  <div class="flex flex-row gap-3 justify-content-end align-items-center w-2/12">
    <p cybake-text-style="body-small">{{ lot?.Quantity }}</p>
    <i class="fa-solid fa-trash-can text-red-600" (click)="deleteItem()"></i>
  </div>
</div>

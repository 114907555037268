import { DateFormat } from "./date-format.model";
import { TimeFormat } from "./time-format.model";

export class Settings {
  ThemeId: number = 1;
  HighContrast: boolean = false;
  FontSizeId: number = 2;
  Sounds: boolean = true;
  HapticFeedback: boolean = true;
  SiteId?: number;
  DateFormat!: DateFormat;
  TimeFormat!: TimeFormat;

  constructor(values: Settings) {
    Object.assign(this, values);
  }
}

<div>
  <div>
    <p-menu [model]="menus">
      <ng-template pTemplate="items" let-menu>

        @if (!menu.id) {
        <p-skeleton height="40px" styleClass=""></p-skeleton>
        }

        @if (menu?.routerLink) {
        <a [routerLink]="menu.routerLink"
           [attr.tabindex]="-1"
           routerLinkActive="active-menu-item"
           style="color: black"
           (click)="closeMenu()">

          <div class="inline-flex w-full"
               style="min-height: 100%; border-radius: 8px">
            <div class="vertically-center" style="height: 40px !important">
              <i class="fa-icon pr-2 pl-2 text-center"
                 [class]="menu.icon"
                 style="font-size: 1.1em; min-width: 40px; padding-top: 1px"></i>
            </div>
            <div class="w-full">
              <div class="vertically-center-left-align" cybake-text-style='body-xsmall'>
                {{ menu.label | translate }}
              </div>
            </div>
          </div>
        </a>
        }

        @if (menu?.command) {
        <a [attr.tabindex]="-1"
           [attr.href]="menu.command"
           style="color: black">

          <div class="inline-flex w-full"
               style="min-height: 100%; border-radius: 8px">
            <div class="vertically-center" style="height: 40px !important">
              <i class="fa-icon pr-2 pl-2 text-center"
                 [class]="menu.icon"
                 style="font-size: 1.1em; min-width: 40px; padding-top: 1px"></i>
            </div>
            <div class="w-full">
              <div class="vertically-center-left-align" cybake-text-style='body-xsmall'>
                {{ menu.label | translate }}
              </div>
            </div>
          </div>
        </a>
        }
      </ng-template>
    </p-menu>
  </div>
</div>

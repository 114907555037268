import { Component, OnInit, inject } from '@angular/core';
import { UserDetailsComponent } from '../user-details/user-details.component';
import { CybakeTileComponent } from '../_shared/components/tile/tile.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { Router, RouterOutlet } from '@angular/router';
import { environment } from '../../environments/environment';
import { DataService } from '../_shared/services/data.service';
import { SiteService } from '../_shared/services/site.service';
import { SiteServiceMock } from '../_shared/services/mock-services/site.service.mock';
import { Site } from '../_shared/models/sites/site.model';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { MenuService } from '../_shared/services/menu.service';
import { MenuServiceMock } from '../_shared/services/mock-services/menu.service.mock';
import { Menu } from '../_shared/models/menus/menu.model';

@Component({
  selector: 'cybake-factory-dashboard',
  standalone: true,
  imports: [
    UserDetailsComponent,
    CybakeTileComponent,
    RouterOutlet,
    TranslateModule,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  // Services
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  dataService: DataService = inject(DataService);
  messageService: MessageService = inject(MessageService);
  menuService: MenuService | MenuServiceMock = environment.mock
    ? inject(MenuServiceMock)
    : inject(MenuService);
  siteService: SiteService | SiteServiceMock = environment.mock
    ? inject(SiteServiceMock)
    : inject(SiteService);

  // Variables
  pageSourceName: string = 'dashboard';
  tiles: Menu[] = [];
  hasSites: boolean = false;
  refreshSites: boolean = false;
  loadingSites: boolean = false;

  ngOnInit() {
    this.dataService.clearNavbarTitle();

    this.getSites();
    this.getTiles();
  }

  getTiles() {
    this.menuService.getMenus().subscribe({
      next: (getMenusResponse: Menu[]) => {
        getMenusResponse = _.sortBy(getMenusResponse, 'Sequence');
        this.tiles = getMenusResponse;
      },
      error: () => {},
    });
  }

  getSites() {
    this.loadingSites = true;
    this.siteService.getSites().subscribe({
      next: (getSitesResponse: Site[]) => {


        this.hasSites = getSitesResponse.length ? true : false;

        if (this.hasSites) {
          const deviceSite: Site = JSON.parse(localStorage.getItem('deviceSite')!);
          const site: Site = getSitesResponse.find((x: Site) => x.Id == deviceSite.Id) as Site;

          if (!site) {
            localStorage.removeItem('deviceSite');
            localStorage.removeItem('deviceProductionLocation');
          }          
        }

        this.loadingSites = false;
      },
      error: () => {
        forkJoin([
          this.translateService.get('Login.FailedLoginMsg'),
          this.translateService.get('Login.FailedLoginMsg'),
        ]).subscribe((translations: string[]) => {
          this.messageService.add({
            severity: 'error',
            detail: translations[0],
            summary: translations[1],
          });
          this.loadingSites = false;
        });
      },
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unCamelCase',
  standalone: true,
  pure: false,
})
export class UnCamelCasePipe implements PipeTransform {
  transform(passedInString: string): string {
    if (!passedInString) {
      return passedInString;
    }
    return (
      passedInString
        // insert a space between lower & upper
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // space before last upper in a sequence followed by lower
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        // uppercase the first character
        .replace(/^./, () => passedInString.charAt(0).toUpperCase())
    );
  }
}

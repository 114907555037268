<div class="flex flex-col">
  @if(loading){
  <div class="flex flex-col gap-2">
    <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
    <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
    <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
    <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
    <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
    <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
  </div>
  }
  <p-accordion class="w-full flex flex-column gap-3"
               expandIcon="pi pi-plus"
               [activeIndex]="lots.length === 1 ? 0:-1"
               collapseIcon="pi pi-minus">
    @for (lot of lots; track lot) {
    <p-accordionTab id="{{ pageSourceName + '_' + lot.Id }}_tab"
                    class="cybake-lot-tab bottom-border-radial flex flex-row cursor-pointer bg-white items-center relative border border-slate-300 justify-content-between">
      <ng-template pTemplate="header">
        <span class="flex justify-content-between gap-1 w-full">
          <p id="{{ pageSourceName + '_' + lot.Id }}_lotnumber"
             class="white-space-nowrap text-black"
             cybake-text-style="body-small">
            Lot {{ lot.SystemLotNumber }}
          </p>
          <p id="{{ pageSourceName + '_' + lot.Id }}_lotexpiry"
             class=" text-black"
             cybake-text-style="body-small">
            Expiry {{ lot.ExpiryDate | date: 'dd/MM/yyyy' }}
          </p>
        </span>
      </ng-template>
      <cybake-factory-lot-card (transferEvent)="handleValueChange($event)"
                               [basketLot]="returnItem(lot)"
                               [pageSourceName]="'333'"
                               [containers]="containers"
                               [lot]="lot"></cybake-factory-lot-card>
    </p-accordionTab>
    }
  </p-accordion>
</div>

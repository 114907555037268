import { Component } from '@angular/core';

@Component({
  selector: 'cybake-factory-picking',
  standalone: true,
  imports: [],
  templateUrl: './picking.component.html',
  styleUrl: './picking.component.scss',
})
export class PickingComponent {}

<div class="cybake-page-background page-scroller relative {{isPortraitMode?'page-portrait':'page-landscape'}}">
  <!--<p>{{currentStage}}</p>-->
  <p-toast position="top-left" key="tl" />
  @switch (currentStage) {
  <!-- SELECT SOURCE DESTINATION -->
  @case (1) {
  <div class="flex w-full flex-column gap-3 px-2 py-4">
    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="pageSourceName"
                           [cardStyle]="'light'"
                           [hideCardHeader]="true"
                           [customClass]="'stock-count-path'"
                           [cardContentTemplate]="breadcrumbCardContentTemplate"
                           [identifier]="'productionPlanBreadcrumb'">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate>
        <div class="w-full flex align-items-center gap-4"
             (click)="currentStage = 0; startOver() ;setPageTitle()">
          <div class="flex align-items-center">
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="pageSourceName"
                                   [identifier]="navigateBackButton.Identifier"
                                   [raisedButton]="false"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>

            <div class=""
                 [ngClass]="{ 'vertically-center-left-align': true }"
                 cybake-text-style="body-small">
              Start new transfer
            </div>
          </div>


          <!--<div class="font-bold" cybake-text-style="body-small">
            Where are you transferring from?
          </div>-->
        </div>
      </ng-template>
    </div>
    @if(loadingLocations){
    <div class="flex flex-col gap-2">
      <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
      <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
      <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
      <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
    </div>
    }
    <cybake-factory-accordion class="w-full"
                              [accordionTabs]="stockLocationTypes"
                              itemsKey="StockLocations"
                              [pageSourceName]="pageSourceName">
      <ng-template let-item
                   let-pageSourceName="pageSourceName"
                   let-identifier="identifier">
        <cybake-factory-stock-location (click)="selectLocation(item)"
                                       *ngIf="item.StockLocationProductIds?.length"
                                       [name]="item.Name"
                                       [pageSourceName]="pageSourceName"
                                       [showIcon]="false"
                                       [selectedtransferLocation]="stockTransferOptions.selectedLocation?.Id"
                                       [identifier]="item.Id"></cybake-factory-stock-location>
      </ng-template>
    </cybake-factory-accordion>
    <div class="fixed bottom-1 left-0 right-0 p-4 px-32">
      <cybake-factory-button id="factoryBlockBtn" [translationKey]="'StockTransfer.confirm'"
                             [raisedButton]="false"
                             (clickEvent)="currentStage = 2;scrollToTop();setPageTitle()"
                             [fullWidth]="true"
                             [disabled]="!stockTransferOptions.selectedLocation">
      </cybake-factory-button>
    </div>
  </div>
  }

  <!-- SELECT PRODUCT SECTION -->
  @case (2) {
  <div class="px-2 py-4 flex flex-column gap-3">
    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="pageSourceName"
                           [cardStyle]="'light'"
                           [hideCardHeader]="true"
                           [customClass]="'stock-count-path'"
                           [cardContentTemplate]="breadcrumbCardContentTemplate"
                           [identifier]="'productionPlanBreadcrumb'">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate>
        <div class="w-full flex flex-row justify-content-between align-items-center">
          <div class="flex flex-row  align-items-center gap-1" (click)="backFromStageTwo()">
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="pageSourceName"
                                   [identifier]="navigateBackButton.Identifier"
                                   [raisedButton]="false"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>
            <div class="w-full"
                 [ngClass]="{ 'vertically-center-left-align': true }"
                 cybake-text-style="body-small">
              {{ stockTransferOptions.selectedLocation?.Name }}
            </div>
          </div>
          <div class="pr-2">
            <span (click)="showSearch = !showSearch">
              <i class="fa-duotone fa-{{showSearch?'xmark':'magnifying-glass'
              }} text-2xl"></i>
            </span>
          </div>
        </div>
      </ng-template>
    </div>
    @if(showSearch){

    <div class="w-full  animate__animated animate__fadeIn">
      <cybake-factory-card [pageSourceName]="pageSourceName"
                           [hideCardHeader]="true"
                           [cardStyle]="'light'"
                           [customClass]="'stock-count-path'"
                           [cardContentTemplate]="breadcrumbCardContentTemplate2"
                           [identifier]="'productionPlanBreadcrumb2'">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate2>
        <div class="w-full flex items-center">
          <cybake-factory-search-input class="w-full"
                                       [(value)]="searchText"
                                       [pageSourceName]="pageSourceName"
                                       [identifier]="'searchText'"
                                       [label]="'StockTransfer.searchProduct'"
                                       (valueChange)="searchText = $event || ''; filterProducts()"
                                       [placeholder]="true">
          </cybake-factory-search-input>
        </div>

      </ng-template>
    </div>
    }
    @if(loading1){
    <div class="flex flex-col gap-2">
      @for ( item of [1,2,3,4,5,6,2,3,3,4,2,3]; track item ) {
      <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
      }
    </div>
    }
    @if (paginatedProducts.length){
    <div class="product-list flex flex-col gap-2"
         infinite-scroll
         [infiniteScrollDistance]="scrollDistance"
         [infiniteScrollThrottle]="scrollThrottle"
         [scrollWindow]="false"
         (scrolled)="updatePaginatedProducts()">

      @for ( item of (searchText && paginatedProducts) || paginatedProducts; track item ) {
      <cybake-factory-product-card (click)="selectProduct(item)
              "
                                   [name]="item.Name"
                                   [subtitle]="item.Code || ''"
                                   [weight]="item.Quantity || 0"
                                   [displayMeasure]="item.Measure || ''"
                                   [imageUrl]="item.ImageUrl"
                                   [selectedProduct]="stockTransferOptions.selectedProduct?.Id"
                                   [tagValue]="
                                    item.IsTraceable
                                      ? 'Production.ProductionPlanProductCard.TraceableLabel'
                                      : undefined
                                   "
                                   [pageSourceName]="pageSourceName"
                                   (viewProductInfoEvent)="viewProductInfo(item,$event)"
                                   [identifier]="item.Id"></cybake-factory-product-card>
      }

      @if(currentIndex < paginatedProducts.length){
      <div class="loading-spinner">
        Loading more products...
      </div>
      }
    </div>
    }
    @if (searchText && !paginatedProducts.length){
    <div class="p-4 flex flex-col gap-2 justify-center align-items-center">
      <i class="fa-solid fa-magnifying-glass text-4xl"></i>
      <p cybake-text-style='body-bold'>
        {{ 'StockTransfer.NoSearchResults' | translate }}
      </p>
      <p cybake-text-style='body-xsmall'>
        {{ 'StockTransfer.CheckSpelling' | translate }}
      </p>
      <p></p>
    </div>
    }
    <div class="fixed bottom-1 left-0 right-0 p-4 px-32">
      <cybake-factory-button [translationKey]="'StockTransfer.confirm'"
                             id="factoryBlockBtn"
                             (clickEvent)="currentStage = 3;scrollToTop();setPageTitle()"
                             customClass="shadow-none"
                             [fullWidth]="true"
                             [disabled]="!stockTransferOptions.selectedProduct">
      </cybake-factory-button>
    </div>
  </div>
  }
  <!-- SELECT LOT SECTION -->
  @case (3) {
  <div class="px-2 py-4 flex flex-column gap-3">
    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="pageSourceName"
                           [hideCardHeader]="true"
                           [cardStyle]="'light'"
                           [customClass]="'stock-count-path'"
                           [cardContentTemplate]="breadcrumbCardContentTemplate2"
                           [identifier]="'productionPlanBreadcrumb2'"
                           (click)="currentStage = 2; setPageTitle()">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate2>
        <div class="w-full flex items-center h-4rem">
          <div>
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="pageSourceName"
                                   [raisedButton]="false"
                                   [identifier]="navigateBackButton.Identifier"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>
          </div>
          @if(!stockTransferOptions.isMultipleProduct){
          <div class="w-full">
            <div class="w-full" cybake-text-style="body-small">
              {{ stockTransferOptions.selectedLocation?.Name }}
            </div>

            <div class="w-full inline-flex" style="padding-left: 10px">
              <i class="fa-solid fa-arrow-turn-down-right mr-2"
                 style="padding-top: 4px"></i>
              <div class="w-full" cybake-text-style="body-xs">
                {{ stockTransferOptions.selectedProduct?.Name }}
              </div>
            </div>
          </div>
          } @if(stockTransferOptions.isMultipleProduct){

          <div class="w-full">
            <div class="w-full" cybake-text-style="body-small">
              Add More Products
            </div>
          </div>
          }
        </div>
      </ng-template>
    </div>
    <div class="pb-2">
      @if (stockTransferOptions?.selectedProduct) {
      <cybake-factory-product-card [name]="stockTransferOptions?.selectedProduct?.Name || ''"
                                   [subtitle]="
                                    stockTransferOptions?.selectedProduct?.Code || ''
                                   "
                                   [weight]="stockTransferOptions.selectedProduct?.Quantity || 0"
                                   [displayMeasure]="
                                    stockTransferOptions.selectedProduct?.Measure || ''
                                   "
                                   [tagValue]="
                                    stockTransferOptions.selectedProduct?.IsTraceable
                                      ? 'Production.ProductionPlanProductCard.TraceableLabel'
                                      : undefined
                                   "
                                   [showArrowIcon]="false"
                                   [imageUrl]="stockTransferOptions?.selectedProduct?.ImageUrl || ''"
                                   
                                   (viewProductInfoEvent)="viewProductInfo(stockTransferOptions?.selectedProduct,$event)"
                                   [pageSourceName]="pageSourceName"
                                   [identifier]="stockTransferOptions.selectedProduct?.Id || 0"></cybake-factory-product-card>
      }
    </div>
    @if ( stockTransferOptions?.selectedProduct &&
    stockTransferOptions.selectedProduct?.IsTraceable && currentStage === 3 ) {
    <cybake-factory-lots-list [lots]="lots"
                              [loading]="loading2"
                              [containers]="containers" (transferEvent)="handleValueChange($event)"
                              [basketItems]="basketItems!"></cybake-factory-lots-list>
    }
    @if (stockTransferOptions.selectedProduct &&
    !stockTransferOptions.selectedProduct.IsTraceable && !loading2) {


    <div id="{{ pageSourceName + '_' + lots[0]?.Id }}_tab"
         class="cybake-lot-tab bottom-border-radial flex flex-column cursor-pointer bg-white items-center relative border border-slate-300 justify-content-between">
      <cybake-factory-lot-card (transferEvent)="handleValueChange($event)"
                               [basketLot]="returnLots()"
                               [containers]="containers"
                               class="w-full"></cybake-factory-lot-card>
    </div>
    }
    @if(!stockTransferOptions?.selectedProduct?.IsTraceable && loading2){
    <div class="flex flex-col gap-2">
      <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
      <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
      <cybake-factory-product-card [loading]="true"></cybake-factory-product-card>
    </div>
    }
  </div>
  }
  <!--[lot]="returnLotContainer()"-->
  <!--trolley section-->
  @case (4) {
  <div class="px-2 py-4 flex flex-column gap-3">
    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="pageSourceName"
                           [cardStyle]="'light'"
                           [hideCardHeader]="true"
                           [customClass]="'stock-count-path'"
                           [cardContentTemplate]="breadcrumbCardContentTemplate"
                           [identifier]="'productionPlanBreadcrumb'">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate>
        <div class="flex flex-row justify-content-between items-center">
          <div class="flex flex-row items-center gap-2"
               (click)="stockTransferOptions.selectedProduct = undefined;getProducts(stockTransferOptions?.selectedLocation?.Id); currentStage = 2; setPageTitle()">
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="pageSourceName"
                                   [identifier]="navigateBackButton.Identifier"
                                   [raisedButton]="false"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>
            <div class=""
                 [ngClass]="{ 'vertically-center-left-align': true }"
                 cybake-text-style="body-small">
              Add more to transfer
            </div>
          </div>
          <div class="md:pr-2">
            @if (basketItems?.length) {
            <cybake-factory-button [pageSourceName]="pageSourceName"
                                   (clickEvent)="displayCancelTransferPopup = true"
                                   [translationKey]="'StockTransfer.Cancel'"
                                   [identifier]="cancelButton.Identifier"
                                   [class]="cancelButton.Class">
            </cybake-factory-button>
            }
          </div>
        </div>
      </ng-template>
    </div>
    @if (selectedLocation && basketItems && !basketItems.length) {
    <div class="px-1">
      <p cybake-text-style="body-small">
        Currently selected location: {{ selectedLocation.Name }}
      </p>
    </div>
    } @if (!basketItems || (basketItems && !basketItems.length)) {
    <div class="flex flex-column gap-3 text-center justify-content-center p-4 pt-8">
      <i class="fa-duotone fa-person-dolly-empty icon"></i>
      <p class="font-bold text-center" cybake-text-style="body">
        {{ 'StockTransfer.NoItems' | translate }}
      </p>
    </div>
    }
    <cybake-factory-trolley-list [selectedLocation]="selectedLocation"></cybake-factory-trolley-list>

    <div class="fixed bottom-1 left-0 right-0 p-4 px-32">
      <cybake-factory-button [translationKey]="'StockTransfer.TransferNow'"
                             id="factoryBlockBtn"
                             (clickEvent)="currentStage = 5;scrollToTop();setPageTitle()"
                             [fullWidth]="true"
                             [disabled]="!basketItems?.length">
      </cybake-factory-button>
    </div>
  </div>
  }
  <!-- Select destination section -->
  @case (5) {
  <div class="flex flex-col w-full px-2 py-4">

    <div class="w-full" #scrollElement>
      <cybake-factory-card [pageSourceName]="pageSourceName"
                           [cardStyle]="'light'"
                           [hideCardHeader]="true"
                           [customClass]="'stock-count-path'"
                           [cardContentTemplate]="breadcrumbCardContentTemplate"
                           [identifier]="'productionPlanBreadcrumb'">
      </cybake-factory-card>
      <ng-template #breadcrumbCardContentTemplate>
        <div class="w-full flex align-items-center gap-4"
             (click)="currentStage = 4;setPageTitle()">
          <div class="flex align-items-center">
            <cybake-factory-button [iconKey]="navigateBackButton.IconKey"
                                   [pageSourceName]="pageSourceName"
                                   [identifier]="navigateBackButton.Identifier"
                                   [raisedButton]="false"
                                   [class]="navigateBackButton.Class"
                                   [iconClass]="navigateBackButton.IconClass">
            </cybake-factory-button>

            <div class=""
                 [ngClass]="{ 'vertically-center-left-align': true }"
                 cybake-text-style="body-small">
              {{ 'From ' + stockTransferOptions.selectedLocation?.Name}}
            </div>
          </div>
          <!--<div class="font-bold" cybake-text-style="body-small">
            Where are you transferring to?
          </div>-->
        </div>
      </ng-template>
    </div>
    <cybake-factory-accordion class="w-full"
                              [accordionTabs]="stockLocationTypes"
                              itemsKey="StockLocations"
                              [pageSourceName]="pageSourceName">
      <ng-template let-item
                   let-pageSourceName="pageSourceName"
                   let-identifier="identifier">
        <cybake-factory-stock-location *ngIf="item?.AllProducts || (!item?.AllProducts && item?.StockLocationProductIds.length)" (click)="stockTransferOptions.selectedDestination = item"
                                       [name]="item.Name"
                                       [selectedtransferLocation]="stockTransferOptions.selectedDestination?.Id"
                                       [showIcon]="false"
                                       [pageSourceName]="pageSourceName"
                                       [identifier]="item.Id"></cybake-factory-stock-location>
      </ng-template>
    </cybake-factory-accordion>
    <div class="fixed bottom-1 left-0 right-0 p-4 px-32">
      <cybake-factory-button [translationKey]="'StockTransfer.confirm'"
                             (clickEvent)="displayTransferConfirmPopup = true"
                             id="factoryBlockBtn"
                             [fullWidth]="true"
                             [disabled]="!stockTransferOptions.selectedDestination">
      </cybake-factory-button>
    </div>
  </div>
  }
  }
</div>
<!-- Scan barcode button -->
@if (currentStage && currentStage < 5 && !isScrolling) {
<div class="fixed bottom-0 left-0 z-3 p-4">
  <cybake-factory-button [translationKey]="barcodeScannerButton.TranslationKey"
                         [iconKey]="barcodeScannerButton.IconKey"
                         [pageSourceName]="barcodeScannerButton.PageSourceName"
                         [identifier]="barcodeScannerButton.Identifier"
                         [class]="barcodeScannerButton.Class"
                         [float]="barcodeScannerButton.Float"
                         [rounded]="true"
                         (clickEvent)="openBarcodeScanner()">
  </cybake-factory-button>
</div>
<!-- Basket button -->
@if (basketItems?.length && currentStage !== 4) {
<div class="fixed bottom-0 right-0 p-4 z-3">
  <button class="cart-button bg-black text-white rounded-full w-4rem h-4rem flex items-center justify-center"
          (click)="currentStage = 4;setPageTitle()">
    <i class="fa-icon fa-solid fa-list text-4xl text-white"></i>
    @if (basketItems?.length) {
    <span class="cart-count-indicator">
      {{
 basketItems?.length
      }}
    </span>
    }
  </button>
</div>
}
}

<!-- DIALOG SECTION -->
<!-- Init Popup One -->
@if(displayPopupOne){
<cybake-factory-dialog [(visible)]="displayPopupOne"
                       (hideEvent)="displayPopupOne = false; backToDashboard()"
                       [identifier]="'productionPlanNote'"
                       [width]="45"
                       [dialogContentTemplate]="dialogContentTemplate5">
  <ng-template #dialogContentTemplate5>
    <div class="flex flex-column gap-3 items-center">
      <img class="illus-icon bg-gray-200 rounded-full p-4" height="120" width="120" src="assets/icons/trolley-icon.png" />
      <p class="text-center" cybake-text-style="body">
        There is a transfer in progress do you want to continue that transfer?
      </p>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (click)="displayPopupOne = false;currentStage=2; setPageTitle()"
                               [translationKey]="'StockTransfer.Continue'"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class">
        </cybake-factory-button>
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (click)="startOver(true,false)"
                               [translationKey]="'StockTransfer.StartOver'"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class">
        </cybake-factory-button>
        <!--<cybake-factory-button [pageSourceName]="pageSourceName"
                               (click)="displayPopupOne = false; backToDashboard()"
                               [translationKey]="'StockTransfer.Cancel'"
                               [identifier]="cancelButton.Identifier"
                               [class]="cancelButton.Class">
        </cybake-factory-button>-->
      </div>
      <div class="pt-2 border-t-2 border-slate-200 w-full">
        @if(basketItems && basketItems.length){
        <p class="text-center text-sky-500 text-base"
           cybake-text-style="body-semibold">
          There
          {{basketItems && basketItems.length > 1?'are ' + basketItems.length + ' items':'is 1 item'}}
          in the existing transfer
        </p>
        }
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>
}

<!-- Init Popup Two -->
@if(displayPopupTwo){

<cybake-factory-dialog [(visible)]="displayPopupTwo"
                       [identifier]="'productionPlanNote'"
                       (hideEvent)="displayPopupTwo = false; backToDashboard()"
                       [width]="45"
                       [dialogContentTemplate]="dialogContentTemplate5">
  <ng-template #dialogContentTemplate5>
    <div class="flex flex-column gap-3 items-center pb-4">
      <img class="illus-icon" src="assets/icons/product-icon.png" />
      <div class="flex flex-column items-center">
        <p class="text-center" cybake-text-style="body">
          Welcome to stock transfer
        </p>
        <p class="text-center" cybake-text-style="body-small">
          Start a new transfer or continue your transfer
        </p>
      </div>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (clickEvent)="displayPopupTwo= false; startFlow()"
                               [translationKey]="'StockTransfer.NewTransfer'"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class">
        </cybake-factory-button>
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (clickEvent)="setMultipleProduct(true)"
                               [disabled]="!basketItems?.length"
                               [translationKey]="'StockTransfer.ContinueTransfer'"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class">
        </cybake-factory-button>
      </div>
      @if(basketItems?.length){
      <div class="pt-2 border-t-2 border-slate-200 w-full">
        <p class="text-center text-sky-500 text-base"
           cybake-text-style="body-semibold">
          You have {{basketItems?.length}} {{basketItems && basketItems.length > 1?'items':'item'}} in your trolley.
        </p>
      </div>
      }
    </div>
  </ng-template>
</cybake-factory-dialog>
}
<!-- If selecting lots and no lots -->
@if(currentStage === 3 && stockTransferOptions?.selectedProduct?.IsTraceable && !lots?.length && !loading2){

<cybake-factory-dialog [visible]="true"
                       [identifier]="'productionPlanNoteWarnibg'"
                       type="warning"
                       (hideEvent)="displayPopupTwo = false; backToDashboard()"
                       [width]="45"
                       [dialogContentTemplate]="dialogContentTemplate5">
  <ng-template #dialogContentTemplate5>
    <div class="flex flex-column gap-3 items-center pb-4">
      <!--<img class="illus-icon" src="assets/icons/empty-icon.png" />-->
      <div class="flex flex-column items-center">
        <p class="text-center" cybake-text-style="body">
          There are no active lots for this product, please contact head office if you would like to transfer this product.
        </p>
      </div>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (clickEvent)="currentStage = 2"
                               [translationKey]="'Continue'"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class">
        </cybake-factory-button>
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>
}
<!-- Cancel Transfer Confirmation -->

<cybake-factory-dialog [title]="'StockTransfer.CancelConfirmation'"
                       [type]="'question'"
                       [(visible)]="displayCancelTransferPopup"
                       [identifier]="'productionLocationsSelection'"
                       [closable]="true"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate2">
  <ng-template #dialogContentTemplate2>
    <div class="grid m-0">
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (click)="deleteAll(); backToDashboard()"
                               [translationKey]="'StockTransfer.Yes'"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class + ' px-4'">
        </cybake-factory-button>
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (click)="displayCancelTransferPopup = false;"
                               [translationKey]="'StockTransfer.No'"
                               [identifier]="cancelButton.Identifier"
                               [class]="cancelButton.Class + ' px-4'">
        </cybake-factory-button>
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>


<!--Transfer Now Confirmation   -->

<cybake-factory-dialog [title]="!stockTransferOptions.selectedDestination?.AllProducts && !returnFilteredItems?.length?'Your transfer destination does not accept any product in your trolley':!stockTransferOptions.selectedDestination?.AllProducts && returnFilteredItems?.length !== returnBasketItemsLength? returnFilteredItems?.length + ' out of ' + returnBasketItemsLength + ' will be transfered out, are you sure you want to transfer?':'StockTransfer.TransferConfirmation'"
                       [type]="!returnFilteredItems?.length?'error':'question'"
                       [(visible)]="displayTransferConfirmPopup"
                       [identifier]="'productionLocationsSelection'"
                       [closable]="true"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate3">
  <ng-template #dialogContentTemplate3>
    <div class="grid m-0">
      <div class="flex flex-col pb-6">
        @if(returnFilteredItems?.length){
        <p cybake-text-style="body-xsmall"><b>Products</b>: {{getCommaSeparatedProductNames(returnFilteredItems)}}</p>
        <p cybake-text-style="body-xsmall"><b>Total Weight</b>: {{ calculateTotalWeight(returnFilteredItems || []) }}</p>
        @if(calculateTotalWeight2()){
        <p cybake-text-style="body-xsmall"><b>Total Singles</b>: {{ calculateTotalWeight2() }}</p>
        }
        }
        <!--<p cybake-text-style="body-xsmall"><b>Total Weight</b>: {{calculateTotalWeight2() ? calculateTotalWeight2() + ', ':'' }}{{ calculateTotalWeight(returnFilteredItems || []) }}{{ stockTransferOptions.selectedProduct?.Measure}}</p>-->

        <p cybake-text-style="body-xsmall"><b>Source</b>: {{ stockTransferOptions.selectedLocation?.Name }}</p>
        <p cybake-text-style="body-xsmall"><b>Destination</b>: {{ stockTransferOptions.selectedDestination?.Name }}</p>
        @if(!returnFilteredItems?.length){
        <p cybake-text-style="body-xsmall" class="text-red-300">Transfer destination does not accept any product in your trolley, please select another destination</p>
        }
      </div>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (clickEvent)="doStockTransfer()"
                               [disabled]="!returnFilteredItems?.length"
                               [loading]="processing"
                               [translationKey]="'OK'"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class + ' px-4'">
        </cybake-factory-button>
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (click)="displayTransferConfirmPopup = false;"
                               [translationKey]="'StockTransfer.Cancel'"
                               [identifier]="cancelButton.Identifier"
                               [class]="cancelButton.Class + ' px-4'">
        </cybake-factory-button>
      </div>
    </div>
  </ng-template>
</cybake-factory-dialog>

<!--Scan Barcode Dialog-->
<cybake-factory-dialog [title]="'BarcodeScanner.Header'"
                       [(visible)]="displayBarcodeScanner"
                       [identifier]="'barcodeScanProduction'"
                       [closable]="true"
                       [width]="25"
                       [dialogContentTemplate]="dialogContentTemplate1">
  <ng-template #dialogContentTemplate1>
    <cybake-factory-barcode-scan></cybake-factory-barcode-scan>
  </ng-template>
</cybake-factory-dialog>



<!-- Transfer continuation popup -->
<cybake-factory-dialog [(visible)]="displayConfirmPopup"
                       (hideEvent)="displayConfirmPopup = false;"
                       [identifier]="'confirmationPopup'"
                       [width]="45"
                       [dialogContentTemplate]="dialogContentTemplate5">
  <ng-template #dialogContentTemplate5>
    <div class="flex flex-column gap-3 items-center">
      <img class="illus-icon bg-gray-200 rounded-full p-4" height="120" width="120" src="assets/icons/trolley-icon.png" />
      <p class="text-center" cybake-text-style="body">
        There is a transfer in progress do you want to continue that transfer?
      </p>
      <div class="flex flex-row gap-3 justify-center">
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (click)="startOver()"
                               [translationKey]="'StockTransfer.StartOver'"
                               [identifier]="completeButton.Identifier"
                               [class]="completeButton.Class">
        </cybake-factory-button>
        <cybake-factory-button [pageSourceName]="pageSourceName"
                               (click)="displayConfirmPopup = false;"
                               [translationKey]="'StockTransfer.Cancel'"
                               [identifier]="cancelButton.Identifier"
                               [class]="cancelButton.Class">
        </cybake-factory-button>
      </div>
      @if(basketItems && basketItems.length){

      <div class="pt-2 border-t-2 border-slate-200 w-full">
        <p class="text-center text-sky-500 text-base"
           cybake-text-style="body-semibold">
          There
          {{basketItems && basketItems.length > 1?'are ' + basketItems.length + ' items':'is 1 item'}}
          in the existing transfer
        </p>
      </div>
      }
    </div>
  </ng-template>
</cybake-factory-dialog>

<!--Production Plan Product Info-->
<cybake-factory-dialog [(visible)]="displayProductInfo"
                       [identifier]="'stockTransferProductInfo'"
                       [width]="35"
                       [dialogContentTemplate]="stockTransferProductInfo">
  <ng-template #stockTransferProductInfo>

    <cybake-factory-product-info [name]="stockTransferProductInfoDialog.Name!"
                                 [code]="stockTransferProductInfoDialog.Code!"
                                 [isTraceable]="stockTransferProductInfoDialog.IsTraceable"
                                 [image]="stockTransferProductInfoDialog.ImageUrl"
                                 (closeEvent)="displayProductInfo = false">
    </cybake-factory-product-info>

  </ng-template>

</cybake-factory-dialog>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'cybake-factory-counter',
  standalone: true,
  imports: [FormsModule, InputNumberModule],
  templateUrl: './counter.component.html',
  styleUrl: './counter.component.scss',
})
export class CounterComponent {
  @Input() pageSourceName: string = '';
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() value: number = 0;

  increment(): void {
    this.value++;
    this.onValueChange();
  }

  resetCount() {
    //this.value = 0;
  }

  decrement(): void | false {
    if (this.value == 0) {
      return false;
    }
    this.value--;
    this.onValueChange();
  }
  onValueChange() {
    this.valueChange.emit(this.value);
  }
}

import { CyBakeTemplate } from '../cybake-template.model';
import { MessageSeverityEnum } from './message-severity.enum';

export class Message extends CyBakeTemplate {
  Severity!: MessageSeverityEnum;
  Closable?: boolean = false;

  constructor(values: Message) {
    super(values);
    Object.assign(this, values);
  }
}

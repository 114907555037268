import { Component } from '@angular/core';

@Component({
  selector: 'cybake-factory-login-profile-options',
  standalone: true,
  imports: [],
  templateUrl: './login-profile-options.component.html',
  styleUrl: './login-profile-options.component.scss',
})
export class LoginProfileOptionsComponent {}

import {
    AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { CounterComponent } from '../counter/counter.component';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { Lot } from '../../models/stock-transfers/product/lot.model';
import { PopupSelectComponent } from '../popup-select/popup-select.component';
import { TranslateService } from '@ngx-translate/core';
import { LotService } from '../../services/lot.service';
import {
  LotServiceMock,
  StockTransferOptions,
} from '../../services/mock-services/lot.service.mock';
import { environment } from '../../../../environments/environment';
import { Container } from '../../models/stock-transfers/product/container.model';
import { NgTemplateOutlet } from '@angular/common';
import { Trolley } from '../../models/stock-transfers/product/trolley.model';
import { CyBakeInputNumberComponent } from '../../components/input-number/input-number.component';
import { CyBakeButton } from '../../models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../models/cybake/button/button-class.enum';

@Component({
  selector: 'cybake-factory-lot-card',
  standalone: true,
  imports: [
    AccordionModule,
    CounterComponent,
    CyBakeButtonComponent,
    PopupSelectComponent,
    NgTemplateOutlet,
    CyBakeInputNumberComponent,
  ],
  templateUrl: './lot-card.component.html',
  styleUrl: './lot-card.component.scss',
})

export class LotCardComponent implements  OnInit , AfterViewInit{
  translateService: TranslateService = inject(TranslateService);
  lotService: LotService | LotServiceMock = environment.mock
    ? inject(LotServiceMock)
    : inject(LotService);

  @Input() containers: Container[] = [];
  @Input() basketLot!: Trolley | undefined;
  @Input() lot!: Lot;
  @Input() pageSourceName: string = '';
  @Input() lotIndex: number = 0;
  @Output() transferEvent: EventEmitter<Lot> = new EventEmitter<Lot>();

  @ViewChild(CounterComponent) counterComponent!: CounterComponent;
  selectedValue: Container | undefined = undefined;
  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<unknown>;
  filtered!: Trolley[];
  basketItems: Trolley[] | null = null;
  count: number = 0;
  stockTransferOptions!: StockTransferOptions;
  // cancel button
  cancelButton: CyBakeButton = new CyBakeButton({
    Type: ButtonTypeEnum.default,
    PageSourceName: this.pageSourceName,
    Identifier: 'complete',
    Class: ButtonClassEnum.danger,
    Rounded: true,
    IconKey: ['fa-regular', 'fa-check'],
    IconClass: 'text-3xl',
  });

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    console.log('ngOnInit')


  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit')
    this.lotService.basketItems$.subscribe((basketLot) => {
      this.basketItems = basketLot;
      this.loadLot()
    });

  }

  loadLot() {

    this.lotService.stockTransferOptions$.subscribe(
      (stockTransferOptions: StockTransferOptions | null) => {
        if (stockTransferOptions) {
          this.stockTransferOptions = stockTransferOptions;
        }
      },
    );

    const filtered = this.basketLot?.Lots?.find(
      (item: Lot) =>
        this.lot?.Id && this.selectedValue ? ((item.Id == this.lot.Id) && (item.ProductId == this.stockTransferOptions?.selectedProduct?.Id) && this.selectedValue?.ProductToContainerId == item.ContainerId) :
          this.lot?.Id ? ((item.Id == this.lot.Id) && (item.ProductId == this.stockTransferOptions?.selectedProduct?.Id)) :
        !this.lot?.Id ? (item.ProductId == this.stockTransferOptions?.selectedProduct?.Id) :
          ((item?.UniqueIdentifier == this.lot?.UniqueIdentifier) || (item.ProductId == this.stockTransferOptions?.selectedProduct?.Id && item.ContainerName === 'Single'))
    );


    if (filtered && this.containers.length  && !this.selectedValue) {
      this.selectedValue = this.containers.find((container: Container) => container.UniqueIdentifier === filtered?.UniqueIdentifier +''+ this.lotIndex)
    }
    else if (this.containers.length && !this.selectedValue) {
      this.selectedValue = this.containers[0];
    }


    if (filtered) {
      if (
        this.lot?.Id ? (this.lot?.Id &&
          this.basketLot?.ProductId == this.stockTransferOptions?.selectedProduct?.Id &&
          this.lot?.Id == filtered?.Id &&
          this.selectedValue?.ProductToContainerId == filtered?.ContainerId) : (this.lot?.ContainerName && filtered?.ContainerName)
      ) {

        this.count = filtered.Quantity || 0;
      } else if (!this.lot?.Id && filtered.ContainerName && this.basketLot?.ProductId == this.stockTransferOptions?.selectedProduct?.Id) {
        this.count = filtered.Quantity || 0;
      } else if (this.lot?.Id && filtered.ContainerName && this.basketLot?.ProductId == this.stockTransferOptions?.selectedProduct?.Id) {

        this.count = filtered.Quantity || 0;
      }

      else if (!this.lot?.Id) {
        this.count = 0;
      }

    }


    //just added now

    if (this.containers.length && !this.selectedValue) {
      this.selectedValue = this.containers[0];
    }

    this.changeDetectorRef.detectChanges();
  }

  checkCount() {

    const filtered = this.basketItems?.find(
      (item: Trolley) =>
        item.ProductId == this.stockTransferOptions?.selectedProduct?.Id,
    );

    console.log(this.selectedValue);
    const traceabilityId: string = this.lot?.Id ? this.selectedValue?.UniqueIdentifier + '' + this.lot?.SystemLotNumber + '' + this.lotIndex : '' ;
    console.log(traceabilityId)

    const foundLot =
      filtered &&
      filtered.Lots
        .find(
          (lot: Lot) =>
            this.lot?.Id && this.stockTransferOptions?.selectedProduct?.IsTraceable ?
              traceabilityId === lot.UniqueIdentifier && lot.ContainerId == this.selectedValue?.ProductToContainerId :
              this.lot?.Id && !this.stockTransferOptions?.selectedProduct?.IsTraceable ?
                (lot?.Id === this.lot?.Id && lot?.ContainerId === this.lot?.ContainerId) :
              lot.UniqueIdentifier == this.selectedValue?.UniqueIdentifier + '' + this.lotIndex
          //}
        );
    this.count = (foundLot ? foundLot.Quantity : 0) || 0;

  }

  inCart() {
    let status = false;
    status = this.stockTransferOptions?.selectedProduct?.IsTraceable ? this.doesObjectExist2(this.basketItems!, this.lot) : this.doesObjectExist(this.basketItems!);

    return status;
  }

  doesObjectExist(array: Trolley[]) {
    return array?.some((item) => {
      return item.Lots.some((lot) => {
        return (
          lot?.Id && this.lot ?
            lot?.SystemLotNumber === this.lot?.SystemLotNumber &&
            lot?.ContainerId === this.selectedValue?.ProductToContainerId :
            (lot.UniqueIdentifier == (this.selectedValue?.UniqueIdentifier + '' + this.lotIndex) || lot?.ContainerName === 'Single' && lot?.ProductId == this.stockTransferOptions?.selectedProduct?.Id)
        );
      });
    });
  }


  doesObjectExist2(array: Trolley[], obj: Lot) {
    return array?.some((item) => {
      return item.Lots.some((lot) => {
        return (
          lot?.Id ? lot?.Id === obj?.Id &&
            lot?.ContainerId === this.selectedValue?.ProductToContainerId : lot?.ContainerName === obj?.ContainerName && obj.ProductId == this.stockTransferOptions?.selectedProduct?.Id
        );
      });
    });
  }

  removeItem() {
    const updatedItems = this.stockTransferOptions?.selectedProduct?.IsTraceable ? this.removeLotIfExists2(this.basketItems!, this.lot) : this.removeLotIfExists(this.basketItems!);
    this.lotService.updateBasketItemsSource(updatedItems);
    this.count = 0;
  }


  removeLotIfExists(array: Trolley[]): Trolley[] {
    const lotIndexInfo = this.getLotIndex();
    console.log(lotIndexInfo)
    // If no matching lot is found, return the original array
    if (lotIndexInfo === -1) {
      return array;
    }

    const { trolleyIndex, lotIndex } = lotIndexInfo;

    return array
      .map((item, tIndex) => {
        if (tIndex === trolleyIndex) {
          // Remove the lot at the found index
          const newLots = item.Lots.filter((_, lIndex) => lIndex !== lotIndex);
          return { ...item, Lots: newLots };
        }
        return item; // Return the other trolleys unchanged
      })
      .filter((item) => item.Lots.length > 0); // Remove trolleys with no lots
  }


  removeLotIfExists2(array: Trolley[], obj: Lot) {
    return array.map((item) => {
      const newLots = item.Lots.filter(
        (lot) =>
          !(
            lot.Id === obj.Id &&
            lot.ContainerId === this.selectedValue?.ProductToContainerId
          ),
      );
      return { ...item, Lots: newLots }
    })
      .filter((item) => item.Lots.length > 0); // Remove trolleys with no lots
  }


  getLotIndex(): { trolleyIndex: number; lotIndex: number } | -1 {
    let trolleyIndex = -1;
    let lotIndex = -1;

    const found = this.basketItems?.some((trolley, tIndex) => {
      return trolley.Lots.some((lot, lIndex) => {
        const isMatch =
          lot?.Id  ? (lot.UniqueIdentifier) === this.selectedValue?.UniqueIdentifier && lot.ContainerId === this.selectedValue?.ProductToContainerId :
            !lot?.Id ? lot.UniqueIdentifier === (this.selectedValue?.UniqueIdentifier + '' + this.lotIndex) :
              (lot.ContainerName === 'Single' && lot.ProductId === this.stockTransferOptions?.selectedProduct?.Id);
        if (isMatch) {
          trolleyIndex = tIndex; // Store the trolley index
          lotIndex = lIndex; // Store the lot index
          return true; // Stop iteration once a match is found
        }

        return false;
      });
    });

    return found ? { trolleyIndex, lotIndex } : -1;
  }


  getLotQuantity(array: Trolley[], obj: Lot) {
    if (!array || array.length) {
      return 0;
    }
    for (const item of array) {
      for (const lot of item.Lots) {
        if (
          obj.ContainerId ? (lot.Id === obj.Id &&
            lot.ExpiryDate === obj.ExpiryDate &&
            lot.ContainerId === obj.ContainerId) : (lot.ContainerName === obj.ContainerName)
        ) {
          return lot.Quantity;
        }
      }
    }
    return 0; // Return null if the lot does not exist
  }

  handleValueChange(value: Container) {
    this.selectedValue = value;
    if (this.lot) {

      this.lot.ContainerId = this.selectedValue.ProductToContainerId;
      this.lot.ContainerName = this.selectedValue.ContainerDisplayName;
    }
    this.checkCount()
  }

  handleCountValueChange(value: number) {
    this.count = value;
  }

 

  onTransferClicked() {
    const lot: Lot = {
      Id: this.lot?.Id ?? undefined,
      StockLotId: this.lot?.Id ?? undefined,
      SystemLotNumber: this.lot?.SystemLotNumber ?? '',
      ExpiryDate: this.lot?.ExpiryDate ?? '',
      ContainerId: this.selectedValue?.ProductToContainerId ?? undefined,
      Weight: this.selectedValue?.Weight ? (this.selectedValue?.Weight * this.count) : 0,
      ContainerName: this.selectedValue?.ContainerDisplayName ?? 'Single',
      Quantity: this.count,
      ProductId: this.stockTransferOptions?.selectedProduct?.Id,
      UniqueIdentifier: this.selectedValue?.UniqueIdentifier + (this.lot?.Id ? '' + this.lot?.SystemLotNumber : '') + '' + this.lotIndex
    }

    if (this.count == 0 && this.inCart()) {
      this.removeItem();
    } else {
      this.transferEvent.emit(lot);
    }
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LotServiceMock } from '../../services/mock-services/lot.service.mock';
import { LotService, StockTransferOptions } from '../../services/lot.service';
import { Trolley } from '../../models/stock-transfers/product/trolley.model';
import { CyBakeImage } from '../../models/image/image.model';
import { TrolleyCardComponent } from '../trolley-card/trolley-card.component';
import { StockLocationType } from '../../models/stock-transfers/stocks/stock-location-types.model';
import { ImageComponent } from '../../components/image/image.component';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { CyBakeTagComponent } from '../../components/tag/tag.component';
import { Product } from '../../models/stock-transfers/product/product.model';

@Component({
  selector: 'cybake-factory-trolley-list',
  standalone: true,
  imports: [
    AccordionModule,
    ImageComponent,
    CyBakeButtonComponent,
    CyBakeTagComponent,
    TrolleyCardComponent,
    ImageComponent
  ],
  templateUrl: './trolley-list.component.html',
  styleUrl: './trolley-list.component.scss',
})
export class TrolleyListComponent implements OnInit {
  translateService: TranslateService = inject(TranslateService);
  lotService: LotService | LotServiceMock = environment.mock
    ? inject(LotServiceMock)
    : inject(LotService);
  basketItems: Trolley[] | null = [];
  @Input() selectedLocation!: StockLocationType;

  @Output() transferEvent: EventEmitter<null> = new EventEmitter();

  // Variables
  pageSourceName: string = 'trolleys-list';
  // Product Photo
  productPhotoImage: CyBakeImage = new CyBakeImage({
    Value: undefined,
    Identifier: 'productImage',
    PageSourceName: this.pageSourceName,
    Height: 50,
    Width: 50,
    ImageFormatDataURI: true,
  });
  product!: Product | undefined;

  ngOnInit() {
    this.lotService.basketItems$.subscribe((basketLot) => {
      this.basketItems = basketLot;
    });


    this.lotService.stockTransferOptions$.subscribe(
      (stockTransferOptions: StockTransferOptions | null) => {
        if (stockTransferOptions) {
          this.product = stockTransferOptions?.selectedProduct;
        }
      },
    );
  }

  calculateTotalWeight(array: Trolley): number {
    return array.Lots.reduce((total, lot) => total + (lot.Weight || lot.Quantity || 0), 0);
  }

  deleteItem(i: number) {
    this.basketItems!.splice(i, 1);
    this.lotService.updateBasketItemsSource(this.basketItems!);
  }

  deleteLot(i: number, j: number) {
    this.basketItems![i].Lots.splice(j, 1);

    if (!this.basketItems![i].Lots.length) {
      this.basketItems!.splice(i, 1);
    }
    this.lotService.updateBasketItemsSource(this.basketItems!);
  }
}

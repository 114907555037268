import { ToastSeverity } from "../cybake/toast/toast-severity.enum";

export class ToastMessage {
  Severity: ToastSeverity = ToastSeverity.success;
  Summary?: string;
  Detail?: string;
  Closable?: boolean = true;
  StyleClass?: string = '';
  Life?: number = 3000;
  Sticky?: boolean = false;
  Id?: string;
  Key?: string;
  PopupMode?: boolean = false;

  constructor(values: ToastMessage) {
    Object.assign(this, values);
  }
}

<!--<div class="toast-alert-icon toast-alert-success toast-alert-animate-success-icon" style="display: flex;">
  <div class="toast-alert-success-circular-line-left" style="background-color: transparent;"></div>
  <span class="toast-alert-success-line-tip"></span>
  <span class="toast-alert-success-line-long"></span>
  <div class="toast-alert-success-ring"></div>
  <div class="toast-alert-success-fix" style="background-color: transparent;"></div>
  <div class="toast-alert-success-circular-line-right" style="background-color: transparent;"></div>
</div>-->
<!--<div class="toast-alert-icon toast-alert-error toast-alert-animate-error-icon" style="display: flex;">
  <span class="toast-alert-x-mark">
    <span class="toast-alert-x-mark-line-left"></span>
    <span class="toast-alert-x-mark-line-right"></span>
  </span>
</div>-->

<!--<p-toast position="bottom-center"></p-toast>-->

<p-toast position="bottom-center"
         key="cybakeToast"
         (onClose)="this.closeEvent.emit()"
         [baseZIndex]="5000">

  <ng-template let-message pTemplate="message">

    <div class="inline-flex w-full">

      <!--Success-->
      @if(!iconKey && severity === 0) {
      <div class="">
        <div class="vertically-center">
          <div class="toast-alert-icon toast-alert-success toast-alert-animate-success-icon" style="display: flex; font-size: 50%;">
            <div class="toast-alert-success-circular-line-left" style="background-color: transparent; font-size: 100%;"></div>
            <span class="toast-alert-success-line-tip" style="font-size: 100%;"></span>
            <span class="toast-alert-success-line-long" style="font-size: 100%;"></span>
            <div class="toast-alert-success-ring" style="font-size: 100%;"></div>
            <div class="toast-alert-success-fix" style="font-size: 100%;"></div>
            <div class="toast-alert-success-circular-line-right" style="font-size: 100%;"></div>
          </div>
        </div>        
      </div>
      }

      <!--Error-->
      @if(!iconKey && severity === 1) {
      <div>
        <div class="toast-alert-icon toast-alert-error toast-alert-animate-error-icon" style="display: flex;">
          <span class="toast-alert-x-mark">
            <span class="toast-alert-x-mark-line-left"></span>
            <span class="toast-alert-x-mark-line-right"></span>
          </span>
        </div>
      </div>
      }

      <!--Warning-->
      @if(!iconKey && severity === 2) {
      <div>
        <div class="toast-alert-icon toast-alert-error toast-alert-animate-error-icon" style="display: flex;">
          <span class="toast-alert-x-mark">
            <span class="toast-alert-x-mark-line-left"></span>
            <span class="toast-alert-x-mark-line-right"></span>
          </span>
        </div>
      </div>
      }

      <!--Info-->
      @if(!iconKey && severity === 3) {
      <div>
        <div class="toast-alert-icon toast-alert-error toast-alert-animate-error-icon" style="display: flex;">
          <span class="toast-alert-x-mark">
            <span class="toast-alert-x-mark-line-left"></span>
            <span class="toast-alert-x-mark-line-right"></span>
          </span>
        </div>
      </div>
      }

      <!--Custom-->
      @if(severity === 4) {

      @if(iconKey) {
      <div>
        Custom Icon
      </div>
      }

      }

      <!--Toast Text-->
      <div class="w-full c-success"
           [ngClass]="{'pl-2': severity < 3 || iconKey}">

        <div class="w-full text-xl"
             [ngClass]="{'vertically-center-left-align': !message.summary}">
          {{message.detail}}
        </div>

        <div class="w-full">
          {{message.summary}}
        </div>

      </div>

    </div>

    @if(buttons.length) {
    <div class="w-full">
      @for ((button of buttons);track button;let index = $index) {
      Button {{index}}
      }
    </div>
    }

  </ng-template>

</p-toast>

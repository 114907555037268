import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserDetails } from '../../models/user/user-details.model';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HammerModule } from '@angular/platform-browser';
import { LoginProfileOptionsComponent } from '../../../login/login-profile-options/login-profile-options.component';
import { SettingsComponent } from '../../../settings/settings.component';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'cybake-factory-login-profile',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule,
    HammerModule,
    DialogModule,
    LoginProfileOptionsComponent,
    SettingsComponent,
  ],
  templateUrl: './login-profile.component.html',
  styleUrl: './login-profile.component.scss',
})
export class LoginProfileComponent implements OnInit {
  // Inputs/Outputs
  @Input() user?: UserDetails;
  @Input() placeholderProfile: boolean = false;
  @Input() showDetails: boolean = true;

  @Output() selectUserEvent: EventEmitter<UserDetails> = new EventEmitter();

  // Services

  // Variables
  userAcronym!: string;
  foo: string = '';

  ngOnInit() {
    if (this.user && !this.placeholderProfile) {
      const titleLetters: RegExpMatchArray | null =
        this.user.DisplayName.match(/\b(\w)/g);
      if (titleLetters) {
        this.userAcronym = titleLetters.join('');
      }
    }
  }

  selectUser() {
    if (this.placeholderProfile) {
      this.selectUserEvent.emit();
    } else {
      this.selectUserEvent.emit(this.user);
    }
  }

  openUserActions() {
    this.foo = 'long press detected';
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as _ from 'underscore';
import { UserDetails } from '../../models/user/user-details.model';
import { Settings } from '../../models/settings/settings.model';
import { faker } from '@faker-js/faker';
import { GetUserResponse } from '../../models/user/get-user-response.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UserServiceMock {
  getUsers(): Observable<UserDetails[]> {
    return of();
  }

  getUserDetails(): Observable<UserDetails> {

    if (localStorage.getItem('user')) {
      return of(JSON.parse(localStorage.getItem('user')!))
    }

      const userResponse: GetUserResponse = {
        User: {
          UserId: faker.number.int({ min: 10000, max: 99999 }),
          UserName: faker.internet.email(),
          DisplayName: 'Alasdair Goodfellow',
          MasterCompanyId: 1,
          TimeZoneId: 'GMT Standard Time',
        },
        CompanyDateFormat: {
          Id: 1,
          Long: "dd MMMM yyy",
          Medium: "dd MMM yyyy",
          MomentLong: "DD MMMM YYYY",
          MomentMedium: "DD MMM YYYY",
          MomentShort: "DD/MM/YYYY",
          MomentTiny: "DD/MM",
          Name: "dd/mm/yyyy",
          PrimeLong: "dd MM yy",
          PrimeMedium: "dd M yy",
          PrimeShort: "dd/mm/yy",
          PrimeTiny: "dd/mm",
          Short: "dd/MM/yyyy",
          Tiny: "dd/MM"
        },
        CompanyTimeFormat: {
          Id: 1,
          Long: "HH:mm:ss",
          Name: "24 Hour",
          Short: "HH:mm"
        }
      };

      const userDetails = this.logUserDetails(userResponse);

      return of(userDetails);    
  }

  setUserDetails(loginDetails: GetUserResponse): UserDetails {
    const userDetails: UserDetails = {
      UserId: loginDetails.User.UserId,
      UserName: loginDetails.User.UserName,
      DisplayName: 'Alasdair Goodfellow',
      LastLogin: moment().toDate(),
      MasterCompanyId: 1,
      TimeZoneId: loginDetails.User.TimeZoneId,
      Settings: new Settings({
        ThemeId: 1,
        FontSizeId: 2,
        HighContrast: false,
        HapticFeedback: true,
        Sounds: true,
        DateFormat: {
          Id: 1,
          Long: "dd MMMM yyy",
          Medium: "dd MMM yyyy",
          MomentLong: "DD MMMM YYYY",
          MomentMedium: "DD MMM YYYY",
          MomentShort: "DD/MM/YYYY",
          MomentTiny: "DD/MM",
          Name: "dd/mm/yyyy",
          PrimeLong: "dd MM yy",
          PrimeMedium: "dd M yy",
          PrimeShort: "dd/mm/yy",
          PrimeTiny: "dd/mm",
          Short: "dd/MM/yyyy",
          Tiny: "dd/MM"
        },
        TimeFormat: {
          Id: 1,
          Long: "HH:mm:ss",
          Name: "24 Hour",
          Short: "HH:mm"
        }
      }),
    };

    localStorage.setItem('user', JSON.stringify(userDetails));
    return userDetails;
  }

  logUserDetails(userDetails: GetUserResponse): UserDetails {
    // get list of logged users from local storage
    let existingUsers: UserDetails[] = JSON.parse(
      localStorage.getItem('users')!,
    );

    let newUserDetails: UserDetails;
    let existingUser: UserDetails;

    if (existingUsers) {

      // See if the user that just logged in exists in the existing users list
      existingUser = _.find(existingUsers, (user: UserDetails) => {
        return user.UserId === userDetails.User.UserId;
      }) as UserDetails;

      // If it exists, log the user in local storage with previous settings
      // ELSE - create new user settings and then log user and add them to users list
      if (existingUser) {
        localStorage.setItem('user', JSON.stringify(existingUser));
        return existingUser;
      } else {
        newUserDetails = this.setUserDetails(userDetails);
        existingUsers.push(newUserDetails);
        localStorage.setItem('users', JSON.stringify(existingUsers));
        return newUserDetails;
      }

    } else {
      newUserDetails = this.setUserDetails(userDetails);
      existingUsers = [newUserDetails];
      localStorage.setItem('users', JSON.stringify(existingUsers));
      return newUserDetails
    }
  }
}

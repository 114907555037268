//import { SafeResourceUrl } from '@angular/platform-browser';
import { CyBakeTemplate } from '../cybake/cybake-template.model';

export class CyBakeImage extends CyBakeTemplate {
  Value: string | undefined;
  Width: number = 50;
  Height: number = 50;
  ShowViewIndicator?: boolean = true;
  ShowUploadIndicator?: boolean = true;
  Title?: string = '';
  Label?: string;
  Alt?: string = '';
  StyleClass?: string;
  ImageStyle?: string;
  ImageClass?: string;
  NoImage?: boolean = false;
  ImageFormatDataURI: boolean = false;
  HideBorder?: boolean = false;

  constructor(values: CyBakeImage) {
    super(values);
    Object.assign(this, values);
  }
}

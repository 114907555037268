import { Component, Input, inject } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cybake-factory-base-card',
  standalone: true,
  imports: [RouterOutlet, TranslateModule, RouterModule],
  templateUrl: './base-card.component.html',
  styleUrl: './base-card.component.scss',
})
export class BaseCardComponent {
  // Services
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);

  // Inputs/Outputs
  @Input() name!: string;
  @Input() identifier: number = 0;
  @Input() pageSourceName: string = '';
}

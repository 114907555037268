<div class="w-full">

  <div class="col-12 p-0 pt-3">
    <div class="w-full text-lg"><span class="font-semibold text-lg">{{'Production.CompleteItem.NameLabel' | translate}}: </span> {{product?.ProductName}}</div>
    <div class="w-full pt-1 text-lg"><span class="font-semibold text-lg">{{'Production.CompleteItem.CodeLabel' | translate}}: </span>{{product?.ProductCode}}</div>
  </div>

  @if (traceableItem) {
  <div class="col-12 p-0 pt-4">
    <cybake-factory-calendar [pageSourceName]="pageSourceName"
                             [identifier]="'expiryDate'"
                             [(value)]="calendarDate"
                             [minDate]="todaysDate"
                             [disabled]="confirmButton.Loading ? true : false"
                             [label]="'Production.CompleteItem.ExpiryDateLabel'">
    </cybake-factory-calendar>
  </div>
  }

  @if (!mixItem) {
  <div class="col-12 p-0 pt-4">
    <cybake-factory-input-number [(value)]="value"
                                 [pageSourceName]="pageSourceName"
                                 [identifier]="'quantityProduced'"
                                 [disabled]="confirmButton.Loading"
                                 [label]="'Production.CompleteItem.QuantityProducedLabel'"
                                 [showSteppers]="true">
    </cybake-factory-input-number>
  </div>
  }

  <div class="col-12 p-0 pt-4">
    <cybake-factory-button [pageSourceName]="confirmButton.PageSourceName"
                           [identifier]="confirmButton.Identifier"
                           [translationKey]="confirmButton.TranslationKey"
                           [class]="confirmButton.Class"
                           [iconKey]="confirmButton.IconKey"
                           [iconClass]="confirmButton.IconClass"
                           [loading]="confirmButton.Loading"
                           [fullWidth]="true"
                           (clickEvent)="confirmCompletion()">
    </cybake-factory-button>
  </div>

</div>

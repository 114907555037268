import { Platform } from '@angular/cdk/platform';
import { ApplicationRef, Injectable, inject } from '@angular/core';
import { concat, first, interval } from 'rxjs';
import { DataService } from './data.service';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  // Services
  platform: Platform = inject(Platform);
  dataService: DataService = inject(DataService);
  updates: SwUpdate = inject(SwUpdate);
  appRef: ApplicationRef = inject(ApplicationRef);

  // Variables
  // eslint-disable-next-line
  promptEvent: any;
  pageSourceName: string = 'pwaService';

  activate() {
    console.log('updates', this.updates);

    if (this.updates.isEnabled) {

      const appIsStable$ = this.appRef.isStable.pipe(
        first((isStable) => isStable === true)
      );

      const everySixSeconds$ = interval(6 * 1000); // 6 seconds
      const everySixSecondsOnceAppIsStable$ = concat(
        appIsStable$,
        everySixSeconds$
      );

      everySixSecondsOnceAppIsStable$.subscribe(async () => {
        try {
          const updateFound = await this.updates.checkForUpdate();
          if (updateFound) {
            //if (confirm('New version available. Load new version?')) {
            //  // Reload the page to update to the latest version.
            //  document.location.reload();
            //}
            console.log('New version available.');
          } else {
            console.log('Already on the latest version.');
          }
        } catch (err) {
          console.error('Failed to check for updates:', err);
        }
      });
    }
  }

  initPwaPrompt() {
    console.log('initPwaPrompt');

    if (this.platform.ANDROID) {
      // eslint-disable-next-line
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        this.dataService.showAddToHomeScreen(1);
        //this.openPromptComponent('android');
      });
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this.dataService.showAddToHomeScreen(2);
        //this.openPromptComponent('ios');
      }
    }
  }

  //openPromptComponent(mobileType: 'ios' | 'android') {
  //  timer(3000)
  //    .pipe(take(1))
  //    .subscribe(() => {

  //      console.log('this.promptEvent', this.promptEvent);

  //      let options: DialogOptionsModel = new DialogOptionsModel({
  //        Type: CyBakeConfirmationPrompTypeEnum.info,
  //        Title: `${mobileType} - Add to home screen`,
  //        PageSourceName: this.pageSourceName,
  //        Identifier: 'addToHomeScreen',
  //        Closable: true          
  //      });

  //      if (mobileType === 'android') {

  //        options.ConfirmButtonMethod = new OutputFunction({
  //          MethodName: 'confirmAddToHomeScreen'
  //        }),

  //        options.ConfirmButton = new CyBakeButton({
  //          PageSourceName: this.pageSourceName,
  //          TranslationKey: 'Add to home screen',
  //          Identifier: 'addToHomeScreen',
  //          Type: ButtonTypeEnum.default,
  //          Class: ButtonClassEnum.default,
  //        })
  //      };

  //      this.dataService.openConfirmationPrompt(options);

  //      //this.bottomSheet.open(PromptComponent, {
  //      //  data: { mobileType, promptEvent: this.promptEvent }
  //      //})
  //    });
  //}
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Messages, MessagesModule } from 'primeng/messages';
import { MessageSeverityEnum } from '../../models/cybake/message/message-severity.enum';
import { Message } from 'primeng/api';

@Component({
  selector: 'cybake-factory-message',
  standalone: true,
  imports: [TranslateModule, MessagesModule],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class CyBakeMessageComponent implements OnInit, OnChanges {

  // Children
  @ViewChild(Messages) messages!: Messages;

  // Inputs/Outputs
  @Input() translationKey!: string | undefined;
  @Input() translationParams?: object;
  @Input() severity: MessageSeverityEnum = MessageSeverityEnum.info;
  @Input() closable: boolean | undefined = false;

  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  @Output() closeEvent: EventEmitter<Message> = new EventEmitter();

  // Services
  translate: TranslateService = inject(TranslateService);

  // Variables
  message: Message[] = [];

  ngOnInit() {
    //this.translate.get(this.translationKey ? this.translationKey : '', this.translationParams).subscribe((translation: string) => {
    //  this.message.push({
    //    severity: this.severity,
    //    summary: translation
    //  });
    //});

    if (this.messages) {
    if (this.messages.value) {
      this.messages.value = this.messages.value.slice();
    }
    }
    
  }

  ngOnChanges() {
    this.message = [];
    this.translate.get(this.translationKey ? this.translationKey : '', this.translationParams).subscribe((translation: string) => {
      this.message.push({
        severity: this.severity,
        summary: translation
      });
    });

    if (this.messages) {
    if (this.messages.value) {
      this.messages.value = this.messages.value.slice();
    }
    }

  }

}

import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ProductCardComponent } from '../product-card/product-card.component';
import { Lot } from '../../models/stock-transfers/product/lot.model';
import { CyBakeButtonComponent } from '../../components/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LotService } from '../../services/lot.service';
import { LotServiceMock, StockTransferOptions } from '../../services/mock-services/lot.service.mock';
import { Product } from '../../models/stock-transfers/product/product.model';

@Component({
  selector: 'cybake-factory-trolley-card',
  standalone: true,
  imports: [CyBakeButtonComponent, TranslateModule],
  templateUrl: './trolley-card.component.html',
  styleUrl: './trolley-card.component.scss',
})
export class TrolleyCardComponent extends ProductCardComponent implements OnInit {
  lotService: LotService | LotServiceMock = environment.mock
    ? inject(LotServiceMock)
    : inject(LotService);
  product!: Product | undefined;

  ngOnInit() {

    this.lotService.stockTransferOptions$.subscribe(
      (stockTransferOptions: StockTransferOptions | null) => {
        if (stockTransferOptions) {
          this.product = stockTransferOptions?.selectedProduct;
        }
      },
    );
  }
  @Input() lot!: Lot;
  @Output() deleteEvent: EventEmitter<unknown> = new EventEmitter<unknown>();

  deleteItem() {
    this.deleteEvent.emit();
  }
}

export class CyBakeTemplate {
  PageSourceName!: string;
  Identifier!: string;
  Loading?: boolean = false;
  Disabled?: boolean;
  TranslationKey?: string;
  TranslationParams?: object;
  Tooltip?: string;
  TooltipPosition?: string = 'top';
  TooltipFromData?: boolean;
  FullWidth?: boolean;
  TranslationFromData?: boolean = false;
  Hidden?: boolean;
  Failed?: boolean;

  constructor(values: CyBakeTemplate) {
    Object.assign(this, values);
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { UserDetails } from '../../models/user/user-details.model';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { CyBakeButtonComponent } from '../button/button.component';
import { UserService } from '../../services/user.service';
import { environment } from '../../../../environments/environment';
import { UserServiceMock } from '../../services/mock-services/user.service.mock';

@Component({
  selector: 'cybake-factory-calendar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CalendarModule,
    TranslateModule,
    CyBakeButtonComponent
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CyBakeCalendarComponent implements OnInit {

  // Children
  @ViewChild(Calendar) calendarRef!: Calendar;

  // Services
  userService: UserService | UserServiceMock = environment.mock ? inject(UserServiceMock) : inject(UserService);

  // Inputs/Outputs
  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  @Input() value!: Date;
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() disabled: boolean = false;
  @Input() showClear: boolean = false;
  @Input() readOnly: boolean = true;
  @Input() timeOnly: boolean = false;
  @Input() showTime: boolean = false;
  @Input() required: boolean = false;

  @Input() minDate?: Date;
  @Input() maxDate?: Date;

  @Output() selectEvent: EventEmitter<Date> = new EventEmitter<Date>();

  // Variables
  userDetails!: UserDetails;

  optionSelected(event: Date) {
    this.selectEvent.emit(event);
  }

  setTodaysDate() {
    this.value = moment(moment().format('YYYY-MM-DD')).toDate();
    this.calendarRef.toggle();
  }

  ngOnInit() {
    this.userService.getUserDetails().subscribe({
      next: (response: UserDetails) => {
        this.userDetails = response;
      },
      error: () => {

      },
    });

    
  }

}

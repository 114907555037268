import { Component, Input, OnChanges, OnInit, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';
import { ButtonTypeEnum } from '../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../_shared/models/cybake/button/button-class.enum';
import { CyBakeButtonComponent } from '../_shared/components/button/button.component';
import { CyBakeButton } from '../_shared/models/cybake/button/button.model';
import { environment } from '../../environments/environment';
import { UserService } from '../_shared/services/user.service';
import { UserServiceMock } from '../_shared/services/mock-services/user.service.mock';
import { UserDetails } from '../_shared/models/user/user-details.model';
import { MessageService } from 'primeng/api';
import { DialogComponent } from '../_shared/components/dialog/dialog.component';
import { UserLocationComponent } from '../user-location/user-location.component';
import { Site } from '../_shared/models/sites/site.model';
import { SettingsComponent } from '../settings/settings.component';
import { forkJoin } from 'rxjs';
import { ToastSeverity } from '../_shared/models/cybake/toast/toast-severity.enum';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { DialogOptionsModel } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt.model';
import { CyBakeConfirmationPrompTypeEnum } from '../_shared/models/cybake/dialogs/confirmation-prompt/confirmation-prompt-type.enum';
import { DataService } from '../_shared/services/data.service';

@Component({
  selector: 'cybake-factory-user-details',
  standalone: true,
  imports: [
    AvatarModule,
    CardModule,
    TranslateModule,
    CyBakeButtonComponent,
    DialogComponent,
    UserLocationComponent,
    SettingsComponent,
    SkeletonModule,
    CommonModule
  ],
  templateUrl: './user-details.component.html',
  styleUrl: './user-details.component.scss',
})
export class UserDetailsComponent implements OnInit, OnChanges {

  // Inputs/Outputs
  @Input() hasSites: boolean = false;
  @Input() refreshSites: boolean = false;
  @Input() loadingSites: boolean = false;

  // Services
  userService: UserService | UserServiceMock = environment.mock
    ? inject(UserServiceMock)
    : inject(UserService);
  translateService: TranslateService = inject(TranslateService);
  messageService: MessageService = inject(MessageService);
  dataService: DataService = inject(DataService);

  // Variables
  pageSourceName: string = 'userDetails';
  currentLocation: string = '';
  userDetails!: UserDetails;
  bottomSheetVisible: boolean = false;
  deviceSite?: Site;

  // Location Button
  locationButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'location',
    Prefix: 'UserDetails.LoacationPrefixLabel',
    IconKey: ['fa-duotone', 'fa-location-dot'],
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.transparent,
    IconClass: 'text-xl',
    Loading: false,
  });

  // Locations Dialog
  userLocationsPrompOptions: DialogOptionsModel = new DialogOptionsModel({
    PageSourceName: this.pageSourceName,
    Identifier: 'selectSite',
    Type: CyBakeConfirmationPrompTypeEnum.question,
    Title: 'UserLocation.Header',
  });

  // Settings Button
  settingsButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'mainMenu',
    IconKey: ['fa-duotone', ' fa-gear'],
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.transparent,
    IconClass: 'text-xl',
    Loading: false,
  });

  // Locations/Sites Dialog
  displayLocations: boolean = false;
  missingSetLocation: boolean = false;

  ngOnInit() {
    this.getUserDetails();
  }

  ngOnChanges() {
    if (this.hasSites) {
      this.deviceSite = JSON.parse(localStorage.getItem('deviceSite')!);

      if (this.hasSites && !this.deviceSite) {
        this.missingSetLocation = true;
        this.openLocations();
      }
    }
  }

  getUserDetails() {
    this.userService.getUserDetails().subscribe({
      next: (getUserDetailsResponse: UserDetails) => {
        this.userDetails = getUserDetailsResponse;
      },
      error: () => {
        forkJoin([
          this.translateService.get('Login.FailedLoginMsg'),
          this.translateService.get('Login.FailedLoginMsg'),
        ]).subscribe((translations: string[]) => {
          this.messageService.add({
            severity: ToastSeverity.error,
            detail: translations[0],
            summary: translations[1],
          });
        });
      },
    });
  }

  openSettings() {
    this.dataService.openSettingsDialog(true);
  }

  openLocations() {
    this.displayLocations = true;
  }

  toggleBottomSheet() {
    this.bottomSheetVisible = !this.bottomSheetVisible;
  }

  locationSet(site: Site) {
    this.deviceSite = site;
    this.displayLocations = false;
  }

  getInitials(name: string): string {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }
}

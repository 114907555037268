import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { CyBakeListbox } from '../../_shared/models/cybake/listbox/listbox.model';
import { CyBakeButton } from '../../_shared/models/cybake/button/button.model';
import { ButtonTypeEnum } from '../../_shared/models/cybake/button/button-type.enum';
import { ButtonClassEnum } from '../../_shared/models/cybake/button/button-class.enum';
import { TranslateModule } from '@ngx-translate/core';
import { ProductionLocation } from '../../_shared/models/production/locations/production-location.model';
import { ProductionService } from '../../_shared/services/production.service';
import { ProductionServiceMock } from '../../_shared/services/mock-services/production.service.mock';
import { environment } from '../../../environments/environment';
import { CyBakeButtonComponent } from '../../_shared/components/button/button.component';
import { ListboxComponent } from '../../_shared/components/listbox/listbox.component';
import _ from 'underscore';

@Component({
  selector: 'cybake-factory-production-shift-select',
  standalone: true,
  imports: [
    TranslateModule,
    CyBakeButtonComponent,
    ListboxComponent
  ],
  templateUrl: './production-shift-select.component.html',
  styleUrl: './production-shift-select.component.scss'
})
export class ProductionShiftSelectComponent implements OnInit {
  // Inputs/Outputs
  @Input() productionLocation?: ProductionLocation;
  @Input() productionLocations?: ProductionLocation[];

  @Output() comfirmLocation = new EventEmitter<ProductionLocation>();

  // Services
  productionService: ProductionService | ProductionServiceMock =
    environment.mock
      ? inject(ProductionServiceMock)
      : inject(ProductionService);

  // Variables
  pageSourceName: string = 'locations';

  // Locations List Box
  locationsListBox: CyBakeListbox = new CyBakeListbox({
    PageSourceName: this.pageSourceName,
    Identifier: 'productionLocations',
    Options: [],
    Value: null,
    OptionValue: 'Id',
    OptionLabel: 'Name',
    RadioButtons: true,
    Loading: true,
  });

  // Confirm Site Button
  confirmButton: CyBakeButton = new CyBakeButton({
    PageSourceName: this.pageSourceName,
    Identifier: 'confirmSite',
    TranslationKey: 'UserLocation.ComfirmLocationButton',
    Type: ButtonTypeEnum.default,
    Class: ButtonClassEnum.default,
    Loading: false,
  });

  ngOnInit() {
    if (this.productionLocation) {
      this.locationsListBox.Value = this.productionLocation.Id;
    }

    if (!this.productionLocations?.length) {
      this.locationsListBox.Loading = true;
      this.getProductionLocations();
    }
  }

  getProductionLocations() {
    this.locationsListBox.Loading = true;
    this.locationsListBox.Failed = false;

    this.productionService.getProductionLocations().subscribe({
      next: (getProductionLocationsResponse: ProductionLocation[]) => {
        this.locationsListBox.Options = getProductionLocationsResponse;
        this.locationsListBox.Loading = false;
      },
      error: () => {
        this.locationsListBox.Loading = false;
        this.locationsListBox.Failed = true;
      },
    });
  }

  confirmLocation() {
    const location: ProductionLocation = _.find(this.locationsListBox.Options, (location: ProductionLocation) => {
      return location.Id === this.locationsListBox.Value;
    }) as ProductionLocation;

    localStorage.setItem('deviceProductionLocation', JSON.stringify(location));

    this.comfirmLocation.emit(location);
  }

}

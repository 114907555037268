import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { FormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HammerModule } from '@angular/platform-browser';
import 'hammerjs';
import { environment } from '../environments/environment';
import { HTTPInterceptor } from './_shared/services/_http-interceptor.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PrimeNGConfig } from 'primeng/api';

export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  if (!environment.production) {
    return new MultiTranslateHttpLoader(_httpBackend, [
      { prefix: './app/login/translations/', suffix: '.json' },
      { prefix: './app/dashboard/translations/', suffix: '.json' },
      { prefix: './app/settings/translations/', suffix: '.json' },
      { prefix: './app/production/translations/', suffix: '.json' },
      { prefix: './app/user-details/translations/', suffix: '.json' },
      { prefix: './app/user-details/translations/', suffix: '.json' },
      { prefix: './app/stock-transfers/translations/', suffix: '.json' },
      {
        prefix: './app/_shared/components/login-profile/translations/',
        suffix: '.json',
      },
      {
        prefix: './app/_shared/components/barcode-scan/translations/',
        suffix: '.json',
      },
      {
        prefix: './app/_shared/dialogs/add-app-to-home-screen/translations/',
        suffix: '.json',
      },
      { prefix: './assets/translations/', suffix: '.json' },
    ]);
  } else {
    return new MultiTranslateHttpLoader(_httpBackend, [
      { prefix: 'assets/merged-translations/', suffix: '.json' },
    ]);
  }
}

const initializeAppFactory = (primeConfig: PrimeNGConfig) => () => {
  primeConfig.ripple = true;
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(routes),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [PrimeNGConfig],
      multi: true,
    },
    importProvidersFrom(
      FormsModule,
      HammerModule,
      SweetAlert2Module.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend],
        },
      }),
    ),
  ],
};

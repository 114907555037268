import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cybake-factory-tile',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    RouterModule,
    CommonModule
  ],
  templateUrl: './tile.component.html',
  styleUrl: './tile.component.scss',
})
export class CybakeTileComponent {

  // Services
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);

  // Inputs/Outputs
  @Input() title!: string;
  @Input() iconKey!: string;
  @Input() link!: string;
  @Input() identifier!: string;
  @Input() pageSourceName!: string;
  @Input() disabled?: boolean = false;
}

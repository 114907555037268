<div
  class="inline-flex w-full navbar-background p-2 max-app-width"
  style="
    position: fixed;
    top: 0;
    margin: 0 -7px;
    padding: 0rem 0.75rem 0.5rem 0.75rem !important;
    height: 60px !important;
  "
>
  <div
    class="text-start"
    style="padding-top: 0.5rem; width: 35px"
    (click)="navigateToDashboard()"
  >
    <div class="vertically-center-left-align">
      <img
        src="assets/images/cybake_bean_white.png"
        alt="{{ 'General.CyBakeBeanAltText' | translate }}"
        class="cursor-pointer"
        style="height: 28px !important"
      />
    </div>
  </div>

  <div
    class="w-full flex flex-column justify-content-center align-items-center"
    [ngClass]="{
      'mobile-navbar-title-with-update':
        shopUpdateIcon && (isMobilePortrait || isMobileLandscape),
      'tablet-navbar-title-with-update':
        shopUpdateIcon && !isMobilePortrait && !isMobileLandscape
    }"
    style="padding: 0 5px"
    (click)="navbarTitleOutput()"
  >
    @if (navbarTitle) {
      @if (navbarTitle.Title) {
        <div
          class="text-center w-full navbar-title text-white"
          [ngClass]="{
            'pt-2 h-full vertically-center fs-1-5': !navbarTitle.SubTitle
          }"
        >
          {{
            navbarTitle.TitleFromData
              ? navbarTitle.Title
              : (navbarTitle.Title | translate)
          }}
        </div>
      }

      @if (navbarTitle.SubTitle) {
        <div class="text-center w-full navbar-subtitle text-white">
          @if (navbarTitle.SubTitleFromData) {
            <div
              [ngClass]="{
                'inline-flex': navbarTitle.PrefixSubTitle && !isMobilePortrait
              }"
            >
              @if (navbarTitle.PrefixSubTitle && !isMobilePortrait) {
                <div class="pr-1">
                  {{ navbarTitle.PrefixSubTitle | translate }}
                </div>
              }

              <div>
                {{ navbarTitle.SubTitle }}
              </div>
            </div>
          }

          @if (!navbarTitle.SubTitleFromData) {
            {{ navbarTitle.SubTitle | translate }}
          }
        </div>
      }
    }
  </div>

  @if (shopUpdateIcon) {
    <div class="text-start pr-3" style="margin-top: 12.5px; height: 35px">
      <cybake-factory-button
        [pageSourceName]="pageSourceName"
        [identifier]="'updateApplication'"
        [translationKey]="
          !isMobilePortrait && !isMobileLandscape
            ? 'Navbar.UpdateApplicationBtn'
            : undefined
        "
        [iconKey]="['fa-duotone fa-solid', 'fa-download']"
        [raisedButton]="false"
        (clickEvent)="openUpdateAppDialog()"
      >
      </cybake-factory-button>
    </div>
  }

  <div class="text-end" style="padding-top: 0.5rem; width: 35px">
    <cybake-factory-button
      [pageSourceName]="pageSourceName"
      [identifier]="menuButton.Identifier"
      [class]="menuButton.Class"
      [iconKey]="menuButton.IconKey"
      [iconClass]="menuButton.IconClass"
      [raisedButton]="false"
      (clickEvent)="this.openMenuEvent.emit(true)"
    >
    </cybake-factory-button>
  </div>
</div>

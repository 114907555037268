<div id="login_div" class="login-background-image h-full">
  <div class="h-1/6"
       style="background-color: #e84e0f">
    <div class="vertically-center">
      <div class="inline-flex">
        <img src="assets/images/cybake_bean_white.png"
             alt="{{ 'General.CyBakeBeanAltText' | translate }}"
             style="height: 42px; padding-right: 15px" />
        <img src="assets/images/cybake_white.png"
             alt="{{ 'General.CyBakeLogoAltText' | translate }}"
             style="height: 50px" />
      </div>
    </div>
  </div>

  <div class="bg-white p-4"
       style="border-radius: 3em; max-width: 370px; margin: auto; max-height: 88.333333%;">
    <div class="block m-0 h-full relative">

      @if (failedLoginCount || failedGetUser) {
      <div class="col-12 pt-0 pb-2">
        <cybake-factory-message [severity]="failedLoginMessage.Severity"
                                [translationKey]="failedLoginMessage.TranslationKey"></cybake-factory-message>
      </div>
      }

      <div class="col-12" [ngClass]="{'mt-4 pt-4': !failedLoginCount && !failedGetUser}">
        <cybake-factory-input-email [(value)]="username"
                                    [pageSourceName]="pageSourceName"
                                    [identifier]="'username'"
                                    [label]="'Login.UsernamePlaceholder'"
                                    [placeholder]="true"
                                    [disabled]="loggingIn">
        </cybake-factory-input-email>
      </div>

      <div class="col-12">
        <cybake-factory-input-password [(value)]="password"
                                       [pageSourceName]="pageSourceName"
                                       [identifier]="'password'"
                                       [label]="'Login.PasswordPlaceholder'"
                                       [placeholder]="true"
                                       [disabled]="loggingIn"
                                       (enterEvent)="login()">
        </cybake-factory-input-password>
      </div>

      @if (captcha) {
      <div class="col-12">
        {{captcha}}
      </div>
      }

      <div class="col-12" [ngClass]="{'inline-flex w-full': existingUsers.length}">

        <div class="w-full">
          <cybake-factory-button [translationKey]="'Login.LoginBtn'"
                                 [fullWidth]="true"
                                 [disabled]="!password || !username"
                                 [loading]="loggingIn"
                                 (clickEvent)="login()">
          </cybake-factory-button>
        </div>

        @if (existingUsers.length) {
        <div class="w-90 ms-2">
          <cybake-factory-button [pageSourceName]="existingUserButton.PageSourceName"
                                 [identifier]="existingUserButton.Identifier"
                                 [type]="existingUserButton.Type"
                                 [class]="existingUserButton.Class"
                                 [iconKey]="existingUserButton.IconKey"
                                 [disabled]="loggingIn"
                                 (clickEvent)="goToPinLogin()">
          </cybake-factory-button>
        </div>
        }
      </div>

      <div class="col-12 text-end mt-2">
        <a>Forgot password?</a>
      </div>

      <div id="login_general_footer_privacyPolicy_div"
           class="text-center text-xs mt-4">
        <span id="login_general_footer_privacyPolicy_prefix_msg"
              class="text-xs"
              [innerHtml]="'Login.PrivacyPolicy' | translate">
        </span>
      </div>

      <div class="col-12 fixed bottom-0 left-0 w-full">
        <div class="text-center text-white opacity-60 ">
          <span>Version {{versionNumber}}</span>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="h-1/6" style="border-top-right-radius: 3rem; background-color: #E84E0F; ">

  </div>-->
</div>

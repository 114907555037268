import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Product } from '../models/stock-transfers/product/product.model';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GetProductResponse } from '../models/stock-transfers/product/product-response';
//import { GetProductImageResponse } from '../models/production/plans/production-plan-product-image.model';

@Injectable({
  providedIn: 'root',
})

export class ProductService {
  // Services
  http: HttpClient = inject(HttpClient);
  _options: object = { responseType: 'blob', observe: 'response' };

  // Service Calls
  getProducts(id: number): Observable<Product[]> {
    const url = `api/stocktransfer/locations/${id}/products`;

    return this.http.get<GetProductResponse>(environment.api + url).pipe(
      map((getProductsResponse: GetProductResponse) => {
        return getProductsResponse.ProductListItems;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  //getProductImagse(productId: number): Observable<Blob> {
  //  const url = `api/stocktransfer/products/${productId}/image`;

  //  return this.http.get(environment.api + url, this._options).pipe(
  //    map((data: any) => {
  //      const blob = new Blob([data.body], { type: 'application/octet-stream' });
  //      return blob;
  //    }),
  //    catchError((error: HttpErrorResponse) => {
  //      return throwError(() => error);
  //    })
  //  );
  //}

  getProductImage(productId: number): Observable<Blob> {
    const url = `api/stocktransfer/products/${productId}/image`;

    return this.http.get(environment.api + url, { ...this._options, observe: 'response', responseType: 'blob' }).pipe(
      map((data: HttpResponse<Blob>) => {
        const blob = new Blob([data.body!], { type: 'application/octet-stream' });
        return blob;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }


}

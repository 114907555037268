<div class="grid m-0">
  <div class="col-12 p-0 pb-2">
    <cybake-factory-listbox
      [options]="sitesListBox.Options"
      [(value)]="sitesListBox.Value"
      [loading]="sitesListBox.Loading"
      [optionValue]="sitesListBox.OptionValue"
      [optionLabel]="sitesListBox.OptionLabel"
      [radioButtons]="true"
      [height]="200"
    >
    </cybake-factory-listbox>
  </div>

  <div class="col-12 p-0 pt-2">
    <cybake-factory-button
      [pageSourceName]="confirmButton.PageSourceName"
      [identifier]="confirmButton.Identifier"
      [translationKey]="confirmButton.TranslationKey"
      [disabled]="!sitesListBox.Value"
      [class]="confirmButton.Class"
      [iconKey]="confirmButton.IconKey"
      [iconClass]="confirmButton.IconClass"
      [fullWidth]="true"
      (clickEvent)="comfirmLocation()"
    >
    </cybake-factory-button>
  </div>
</div>

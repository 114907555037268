import { CommonModule, DecimalPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'cybake-factory-progress-pie',
  standalone: true,
  imports: [DecimalPipe, CommonModule],
  templateUrl: './progress-pie.component.html',
  styleUrl: './progress-pie.component.scss',
})
export class CyBakeProgressPieComponent implements OnInit, OnChanges {
  // Inputs/Outputs
  @Input() pageSourceName!: string;
  @Input() identifier!: string;

  @Input() total!: number;
  @Input() completed!: number;

  @Input() height: number = 2;

  @Input() showCheckmark: boolean = false;
  @Input() showWarning: boolean = false;

  @Output() clickEvent = new EventEmitter<unknown>();

  // Variables
  completePercentage: number = 0;
  overTotal: boolean = false;

  ngOnInit() {
    if (this.completed > this.total) {
      this.overTotal = true;
    } else {
      this.overTotal = false;
    }
  }

  ngOnChanges() {
    if (this.completed > this.total) {
      this.overTotal = true;
    } else {
      this.overTotal = false;
    }

    this.calculatePercentage();
  }

  calculatePercentage() {
    if (this.total !== null && this.completed !== null) {
      this.completePercentage = Number(
        ((this.completed / this.total) * 100).toFixed(0),
      );
    }
  }
}

@if (!hideLabel) {
<div id=""
     class="w-full m-auto text-center {{labelClass}}">

  @if (label) {
  <div class="w-full">
    {{label | translate:labelTranslationParams}}
  </div>
  }

  @if (subLabel) {
  <div class="w-full text-base">
    {{subLabel | translate:labelTranslationParams}}
  </div>
  }

  @if (!label) {
  {{value}}
  }

</div>
}

<div class="relative"
     [style.height]="size + 'px'">
  <div id=""
       class="w-full">
    <p-knob #cybakeDial
            [(ngModel)]="value"
            [min]="min"
            [max]="max"
            [size]="size"
            [disabled]="disabled || loading"
            [readonly]="readOnly"
            (onChange)="this.valueChange.emit(value);this.dialTouchChange.emit(value)"
            valueColor="{{valueColor}}"
            rangeColor="{{rangeColor}}"
            valueTemplate="">
    </p-knob>
  </div>

  <div class="absolute" style="top: 33%; left: 0; right: 0; margin: auto; text-align: center;"
       [style.height]="(loading ? '33%' : '')"
       [style.width]="(size / 2) + 20 + 'px'"
       (click)="this.dialCenterClick.emit()"
       [innerHtml]="!loading ? dialCenterHtml : loadingDialCenterHtml">
  </div>
</div>


<div>

  <div>
    <img src="../../../../assets/images/cybake-logo-25-years.jpeg" />
  </div>

  <div class="text-center w-full text-xl pt-3">
    <strong class="pr-1">{{'AppInfo.VersionLabel' | translate}}:</strong>
    <div>{{versionNumber}}</div>
  </div>

  <div class="text-center w-full text-xl pt-3">
    <strong class="pr-1">{{'AppInfo.ReleaseDateLabel' | translate}}:</strong>
    <div>{{releaseDate}}</div>
  </div>

  @if (!hideUpdateBtn) {
  <div class="col-12 p-0 pt-4">
    <cybake-factory-button [pageSourceName]="checkForUpdateButton.PageSourceName"
                           [identifier]="checkForUpdateButton.Identifier"
                           [translationKey]="checkForUpdateButton.TranslationKey"
                           [class]="checkForUpdateButton.Class"
                           [loading]="checkForUpdateButton.Loading"
                           [fullWidth]="true"
                           (clickEvent)="checkForUpdate()">
    </cybake-factory-button>
  </div>
  }

  <div class="col-12 p-0"
       [ngClass]="{'pt-4': hideUpdateBtn, 'pt-2': !hideUpdateBtn}">
    <cybake-factory-button [pageSourceName]="closeButton.PageSourceName"
                           [identifier]="closeButton.Identifier"
                           [translationKey]="closeButton.TranslationKey"
                           [class]="closeButton.Class"
                           [fullWidth]="true"
                           (clickEvent)="this.closeEvent.emit()">
    </cybake-factory-button>
  </div>

</div>

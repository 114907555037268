<div class="flex flex-col items-center p-2 rounded-lg">
  <p class="text-gray-600 mb-2" cybake-text-style="body-small">Amount</p>
  <div
    class="flex flex-row justify-content-between items-center w-full md:w-6 lg:w-8 p-2"
  >
    <button
      id="{{ pageSourceName }}_decrementBtn"
      class="rounded-full text-4xl md:text-6xl text-white bg-orange-500 text-white w-3rem h-3rem flex items-center justify-center"
      (click)="decrement()"
    >
      -
    </button>
    <p-inputNumber
      id="{{ pageSourceName }}_input"
      class="number-input bg-white text-center rounded-xl font-bold"
      cybake-text-style="body"
      inputId="integeronly"
      [(ngModel)]="value"
    />
    <button
      id="{{ pageSourceName }}_incrementBtn"
      class="rounded-full text-4xl md:text-6xl text-white bg-orange-500 text-white w-3rem h-3rem flex items-center justify-center"
      (click)="increment()"
    >
      +
    </button>
  </div>
</div>

<div class="swal2-center swal2-backdrop-show">
  <p-dialog
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: width + 'em' }"
    [maximizable]="maximizable"
    [baseZIndex]="99"
    [draggable]="true"
    [closable]="closable"
    [resizable]="resizable"
    [focusOnShow]="false"
    [transitionOptions]="'0ms'"
    [dismissableMask]="dismissableMask"
    (onHide)="this.visibleChange.emit(false); this.hideEvent.emit(true)"
    id="{{ identifier }}_dialog"
    styleClass="{{ identifier }}-dialog swal2-popup swal2-modal swal2-show {{
      fixedBottom ? 'dialog-fixed-bottom' : ''
    }}"
  >
    <div>
      @if (cybakeAppIcon) {
        <div style="margin-top: 15px; margin-bottom: 15px">
          <div class="ios-app-icon m-auto">
            <div class="vertically-center">
              <img
                src="assets/images/cybake_bean_white.png"
                alt="{{ 'General.CyBakeBeanAltText' | translate }}"
                style="height: 50px"
              />
            </div>
          </div>
        </div>
      }

      @if (type) {
        <div class="pt-2" style="width: 80%; margin: auto">
          <!--Info Icon-->
          @if (type === 'info') {
            <div
              class="swal2-icon swal2-info swal2-icon-show"
              style="display: flex"
            >
              <div class="swal2-icon-content">i</div>
            </div>
          }

          <!--Success Icon-->
          @if (type === 'success') {
            <div
              class="swal2-icon swal2-success swal2-icon-show"
              style="display: flex"
            >
              <div
                class="swal2-success-circular-line-left"
                style="background-color: rgb(255, 255, 255)"
              ></div>
              <span class="swal2-success-line-tip"></span>
              <span class="swal2-success-line-long"></span>
              <div class="swal2-success-ring"></div>
              <div
                class="swal2-success-fix"
                style="background-color: rgb(255, 255, 255)"
              ></div>
              <div
                class="swal2-success-circular-line-right"
                style="background-color: rgb(255, 255, 255)"
              ></div>
            </div>
          }

          <!--Error Icon-->
          @if (type === 'error') {
            <div
              class="swal2-icon swal2-error swal2-icon-show"
              style="display: flex"
            >
              <span class="swal2-x-mark">
                <span class="swal2-x-mark-line-left"></span>
                <span class="swal2-x-mark-line-right"></span>
              </span>
            </div>
          }

          <!--Warning Icon-->
          @if (type === 'warning') {
            <div
              class="swal2-icon swal2-warning swal2-icon-show"
              style="display: flex"
            >
              <div class="swal2-icon-content">!</div>
            </div>
          }

          <!--Question-->
          @if (type === 'question') {
            <div
              class="swal2-icon swal2-question swal2-icon-show"
              style="display: flex"
            >
              <div class="swal2-icon-content">?</div>
            </div>
          }
        </div>
      }

      <div
        class="pt-2"
        style="width: 80%; margin: auto"
        [ngClass]="{
          'pb-4': !message && !validation && !confirmButton && !declineButton
        }"
      >
        <div
          class="vertically-center text-center text-3xl"
          style="position: relative"
        >
          @if (titlePrefix) {
            <span>{{ titlePrefix | translate: titleTranslationParams }}</span>
          }

          {{
            !titleFromData ? (title | translate: titleTranslationParams) : title
          }}
        </div>
      </div>

      @if (message && messageHtml) {
        <div class="w-full pt-4">
          <div class="vertically-center-left-align pl-2 pr-2">
            <div class="text-xl" [innerHTML]="message"></div>
          </div>
        </div>
      }

      @if (message && !messageHtml) {
        <div class="w-full pt-4">
          <div class="vertically-center pl-2 pr-2">
            <div class="text-xl text-center">
              {{ message | translate: messageTranslationParams }}
            </div>
          </div>
        </div>
      }

      @if (validation) {
        <div
          class="w-full pt-4 mt-3"
          [ngClass]="{ 'pb-4': !confirmButton && !declineButton }"
        >
          <cybake-factory-slide-to-confirm
            (comfirmed)="confirm()"
            [loading]="loading"
          ></cybake-factory-slide-to-confirm>
        </div>
      }

      @if (confirmButton || declineButton) {
        <div
          class="w-full pt-4"
          [ngClass]="{ 'inline-flex': declineButton, 'mt-4': !message }"
        >
          @if (declineButton) {
            <div class="w-full mr-2">
              <cybake-factory-button
                [pageSourceName]="declineButton.PageSourceName"
                [identifier]="declineButton.Identifier"
                [translationKey]="declineButton.TranslationKey"
                [type]="declineButton.Type"
                [class]="declineButton.Class"
                [iconKey]="declineButton.IconKey"
                [iconClass]="declineButton.IconClass"
                [fullWidth]="true"
                [loading]="declineButton.Loading"
                (clickEvent)="decline()"
              >
              </cybake-factory-button>
            </div>
          }

          @if (confirmButton) {
            <div class="w-full" [ngClass]="{ 'ml-2': declineButton }">
              <cybake-factory-button
                [pageSourceName]="confirmButton.PageSourceName"
                [identifier]="confirmButton.Identifier"
                [translationKey]="confirmButton.TranslationKey"
                [type]="confirmButton.Type"
                [class]="confirmButton.Class"
                [iconKey]="confirmButton.IconKey"
                [iconClass]="confirmButton.IconClass"
                [fullWidth]="true"
                [loading]="confirmButton.Loading"
                (clickEvent)="confirm()"
              >
              </cybake-factory-button>
            </div>
          }
        </div>
      }
    </div>

    <ng-container
      *ngTemplateOutlet="visible ? dialogContentTemplate : null"
    ></ng-container>
  </p-dialog>
</div>

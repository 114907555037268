@if (device === 1) {
<div>
  <cybake-factory-button [pageSourceName]="pageSourceName"
                         [identifier]="'addTpHomeScreen'"
                         [translationKey]="'AddToHomeScreen.Title'"
                         [iconKey]="['fa-brands', 'fa-plus']"
                         [fullWidth]="true"
                         (clickEvent)="addAppToHomeScreen()">
  </cybake-factory-button>
</div>
}

@if (device === 2) {
<div class="pl-2 pr-2">

  <!--Step 1-->
  <div class="inline-flex w-full pb-3">
    <div class="pr-2">
      <i class="fa-sharp fa-solid fa-circle-1 text-xl" style="padding-top: 0.05rem !important;"></i>
    </div>

    <div class="w-full inline-flex">
      <span class="pr-1 text-lg">Tap on the </span>
      <img style="width: 1.5rem;height: 1.5rem; margin-top: -2px; " src="assets/images/iOS-share-icon.png" />
      <span class="pl-1 text-lg"> icon</span>
    </div>
  </div>

  <!--Step 2-->
  <div class="inline-flex w-full pb-3">
    <div class="pr-2">
      <i class="fa-sharp fa-solid fa-circle-2 text-xl" style="padding-top: 0.05rem !important;"></i>
    </div>

    <div class="text-lg w-full inline-flex">
      Select "Add to Home Screen" <i class="fa-regular fa-square-plus pl-2 text-lg block" style="font-size: 1.4rem !important;"></i>
    </div>
  </div>

  <!--Step 3-->
  <div class="inline-flex w-full pb-2">
    <div class="pr-2">
      <i class="fa-sharp fa-solid fa-circle-3 text-xl" style="padding-top: 0.05rem !important;"></i>
    </div>

    <div class="text-lg w-full">
      Select "Add"
    </div>
  </div>

  <div>

  </div>

</div>
}

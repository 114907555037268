import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Site } from '../models/sites/site.model';
import { GetSitesResponse } from '../models/sites/get-sites-response.model';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  // Services
  http: HttpClient = inject(HttpClient);

  // Service Calls
  getSites(): Observable<Site[]> {
    if (localStorage.getItem('sites')) {
      return of(JSON.parse(localStorage.getItem('sites')!))
    }

    const url = 'api/dashboard/sites';

    return this.http.get<GetSitesResponse>(environment.api + url).pipe(
      map((getSitesResponse: GetSitesResponse) => {
        localStorage.setItem('sites', JSON.stringify(getSitesResponse.Sites));
        return getSitesResponse.Sites;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }
}

<!--Navbar-->
@if (
  router.url !== '/' && router.url !== '/login' && router.url !== '/login-pin'
) {
<cybake-factory-navbar (openMenuEvent)="menuVisible = true"></cybake-factory-navbar>
}

<!--Main Pages-->
<router-outlet [ngClass]="{
    'router-outlet-logged-in':
      router.url !== '/' &&
      router.url !== '/login' &&
      router.url !== '/login-pin'
  }"></router-outlet>

<!--Menu-->
<p-sidebar #menuSidebar [(visible)]="menuVisible" position="left">
  <ng-template pTemplate="header">
    <div class="inline-flex w-full">
      <!--<img src="assets/images/cybake_bean.png" style="height: 30px; padding-right: 15px;" />-->
      <img src="assets/images/cybake.png"
           alt="{{ 'General.CyBakeLogoAltText' | translate }}"
           style="height: 34px" />
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <cybake-factory-menu (closeMenuEvent)="menuVisible = $event"></cybake-factory-menu>
  </ng-template>

</p-sidebar>

<cybake-factory-toast></cybake-factory-toast>

<!--Add to Home Screen Dialog-->
<cybake-factory-dialog [title]="'Add Cybake Factory to home screen'"
                       [(visible)]="displayAddToHomeScreen"
                       [identifier]="'addToHomeScreen'"
                       [closable]="true"
                       [fixedBottom]="true"
                       [cybakeAppIcon]="true"
                       [width]="25"
                       [dialogContentTemplate]="addToHomeScreenContent"
                       (hideEvent)="declinedAddToHomeScreen()">
  <ng-template #addToHomeScreenContent>
    <cybake-factory-add-app-to-home-screen [device]="device!" (confirmEvent)="confirmAndroidAddToHomeScreen()"></cybake-factory-add-app-to-home-screen>
  </ng-template>
</cybake-factory-dialog>

<!--Dynamic Dialog-->
@if (displayConformationPrompt) {
<cybake-factory-dialog [(visible)]="displayConformationPrompt"
                       [identifier]="'confirmationPrompt'"
                       [width]="confirmationPrompOptions.Width ? confirmationPrompOptions.Width : 25"
                       [closable]="!confirmationPrompOptions.AutoClose ? confirmationPrompOptions.Closable : false"
                       [type]="confirmationPrompOptions.Type"
                       [title]="confirmationPrompOptions.Title"
                       [titleTranslationParams]="confirmationPrompOptions.TitleTranslationParams"
                       [titlePrefix]="confirmationPrompOptions.TitlePrefix"
                       [titleFromData]="confirmationPrompOptions.TitleFromData"
                       [message]="confirmationPrompOptions.Message"
                       [messageTranslationParams]="confirmationPrompOptions.MessageTranslationParams"
                       [validation]="confirmationPrompOptions.Validation"
                       [confirmButton]="confirmationPrompOptions.ConfirmButton"
                       [confirmButtonMethod]="confirmationPrompOptions.ConfirmButtonMethod"
                       [declineButton]="confirmationPrompOptions.DeclineButton"
                       [declineButtonMethod]="confirmationPrompOptions.DeclineButtonMethod"
                       [loading]="confirmationPrompOptions.Loading"
                       [messageHtml]="confirmationPrompOptions.MessageHtml">
</cybake-factory-dialog>
}

<cybake-factory-dialog [title]="'Settings.Header'"
                       [(visible)]="displaySettingsDialog"
                       [identifier]="'settings'"
                       [width]="25"
                       [dialogContentTemplate]="settingsDialogContentTemplate">
  <ng-template #settingsDialogContentTemplate>
    <cybake-factory-settings></cybake-factory-settings>
  </ng-template>
</cybake-factory-dialog>

<cybake-factory-dialog [(visible)]="displayAppInfoDialog"
                       [identifier]="'appInfo'"
                       [closable]="false"
                       [width]="25"
                       [dialogContentTemplate]="appInfoDialogContentTemplate">
  <ng-template #appInfoDialogContentTemplate>
    <cybake-factory-app-info (closeEvent)="displayAppInfoDialog = false"></cybake-factory-app-info>
  </ng-template>
</cybake-factory-dialog>

<div class="w-full">
  <cybake-factory-card
    [title]="name"
    [subTitle]="subtitle"
    [subSubTitle]="weight?weight + ' '+ displayMeasure:''"
    [loading]="loading"
    [pageSourceName]="pageSourceName"
    [image]="imageUrl || ''"
    [loadingImage]="imageLoading"
    [selected]="!!(selectedProduct && selectedProduct === identifier)"
    [pImageClass]="'vertically-center-left-align'"
    identifier="{{ pageSourceName + '_' + identifier }}_productCard"
    [tagValue]="
      tagValue
        ? ['Production.ProductionPlanProductCard.TraceableLabel']
        : undefined
    "
  >
  </cybake-factory-card>
</div>
